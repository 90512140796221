import React, { ReactNode } from 'react';
import { AuthenticationContext } from './AuthenticationContext';
import { SystemStatus } from '../interfaces/SystemStatus';
import SystemStatusService from '../services/SystemStatusServices';
import { useLocalStorage } from '../helpers/hooks/useLocalStorage';

export interface SystemStatusProviderStore {
	isFetching: boolean;
	mySystemStatus: SystemStatus[];
}

export interface SystemStatusUpdateProviderStore {
	fetchSystemStatus: () => void;
	setMySystemStatus: (mySystemStatus: SystemStatus[]) => void;
}

export const SystemInfoPanelStateKey = 'SYSTEM_INFO_PANEL_STATE';
export type SystemInfoPanelState = 'OPENED' | 'CLOSED';

export const SystemStatusContext = React.createContext({} as SystemStatusProviderStore);
export const SystemStatusUpdateContext = React.createContext({} as SystemStatusUpdateProviderStore);

const SystemStatusProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const { isAuthentication } = React.useContext(AuthenticationContext);
	const [isFetching, setIsFetching] = React.useState(false);
	const [mySystemStatus, setMySystemStatus] = React.useState<SystemStatus[]>([]);
	const { isKeyStored, getItem } = useLocalStorage(SystemInfoPanelStateKey);

	const fetchSystemStatus = async (): Promise<void> => {
		if (isAuthentication) {
			setIsFetching(true);

			try {
				const systemStatus = await SystemStatusService.getSystemStatusInfo();

				setMySystemStatus(systemStatus);
			} catch (err) {
				Promise.reject((err as unknown as { name: string; message: string }).message);
			}

			setIsFetching(false);
		}

		Promise.resolve();
	};

	React.useEffect(() => {
		fetchSystemStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthentication]);

	React.useEffect(() => {
		const interval = setInterval(() => {
			if (isKeyStored() && getItem() === 'OPENED') {
				fetchSystemStatus();
			}
		}, 5000);

		return (): void => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthentication]);

	React.useEffect(() => {
		const interval = setInterval(() => {
			if (isKeyStored() && getItem() === 'CLOSED') {
				fetchSystemStatus();
			}
		}, 10000);

		return (): void => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthentication]);

	const store: SystemStatusProviderStore = {
		isFetching,
		mySystemStatus,
	};

	const updateStore: SystemStatusUpdateProviderStore = {
		setMySystemStatus,
		fetchSystemStatus,
	};

	return (
		<SystemStatusContext.Provider value={store}>
			<SystemStatusUpdateContext.Provider value={updateStore}>
				{children}
			</SystemStatusUpdateContext.Provider>
		</SystemStatusContext.Provider>
	);
};

export { SystemStatusProvider };
export const SystemStatusConsumer = SystemStatusContext.Consumer;

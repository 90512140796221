import { Box, Switch, Typography } from '@material-ui/core';
import React from 'react';
import style from './OperationRotateBox.module.scss';
import { TextField } from '../../../components/TextField';

interface OperationRotateBoxProps {
	data: unknown;
	updateOperation: (data: unknown) => void;
}

export interface OperationRotateData {
	degree: string;
	resize: boolean;
}

const OperationRotateBox: React.FC<OperationRotateBoxProps> = ({ data, updateOperation }) => {
	const textData = data as OperationRotateData;

	const onChangeDegree = (value: string): void => {
		updateOperation({ ...textData, degree: value } as OperationRotateData);
	};

	const onChangeResize = (value: boolean): void => {
		updateOperation({ ...textData, resize: value } as OperationRotateData);
	};

	return (
		<Box className={style.operationRotateBox}>
			<Box className={style.degree}>
				<TextField placeholder="Degrees..." value={textData.degree} onChange={onChangeDegree} type={'number-float'} />
			</Box>
			<Box className={style.resize}>
				<Typography className={style.text}>
					Resize
				</Typography>
				<Switch
					checked={textData.resize}
					onChange={(event: React.ChangeEvent<HTMLInputElement>): void => onChangeResize(event.target.checked)}
					color="primary"
				/>
			</Box>
		</Box>
	);
};

export default OperationRotateBox;

import './SplitPanes.scss';
import React from 'react';
import SplitPlane from 'react-split-pane';

interface SplitPanesProps {
	children: React.ReactNode;
	division?: 'VERTICAL' | 'HORIZONTAL';
	onChange?: (size: number) => void;
	primaryComponent?: 'FIRST' | 'SECOND';
	primaryMaxSize?: number;
	primaryMinWidth?: number;
	allowResize?: boolean;
}

const SplitPanes: React.FC<SplitPanesProps> = ({ children, division, onChange, primaryComponent, primaryMaxSize, primaryMinWidth, allowResize }) => {
	const divisionDirection: 'vertical' | 'horizontal' = division === 'HORIZONTAL' ? 'horizontal' : 'vertical';
	const mainComponent: 'first' | 'second' = primaryComponent === 'SECOND' ? 'second' : 'first';

	return (
		<SplitPlane
			split={divisionDirection}
			allowResize={allowResize !== undefined ? allowResize : true}
			defaultSize={primaryMaxSize || '50%'}
			onChange={onChange}
			primary={mainComponent}
			maxSize={primaryMaxSize}
			minSize={primaryMinWidth}
		>
			{children}
		</SplitPlane>
	);
};

export default SplitPanes;

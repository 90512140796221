import { IconButton, TextField } from '@material-ui/core';
import { BoundingBoxEntities } from '../../interfaces/Image';
import { Button } from '../Button';
import { DeleteOutline } from '@material-ui/icons';
import React from 'react';
import style from './ImageBoundingBoxPropertiesEdition.module.scss';
import { UseImageBoundingBoxPropertiesEdition } from './useImageBoundingBoxPropertiesEdition';

interface ImageBoundingBoxEditionProps {
	isClosed: boolean;
	imageUniqueid: number;
	selectedBoundingBox: BoundingBoxEntities | null;
	imageNaturalSize: HTMLImageElement | null;
	resetSelectedBoundingBox: () => void;
}

const ImageBoundingBoxPropertiesEdition: React.FC<ImageBoundingBoxEditionProps> = ({ isClosed, imageUniqueid, selectedBoundingBox, imageNaturalSize, resetSelectedBoundingBox }) => {
	const {
		positionX,
		setPositionX,
		positionY,
		setPositionY,
		width,
		setWidth,
		height,
		setHeight,
		label,
		boundingBoxProperties,
		isNewBoundingBox,
		deleteBoundingBox,
		handleOnCancel,
		onClickSaveButton,
		setLabel,
	} = UseImageBoundingBoxPropertiesEdition(imageUniqueid, selectedBoundingBox, resetSelectedBoundingBox, imageNaturalSize);

	React.useEffect(() => {
		if (isClosed) {
			handleOnCancel();
		}
	}, [isClosed, handleOnCancel]);

	return (
		<div className={style.imageBoundingBoxPropertiesEdition}>
			<p className={style.titleLeft}>Bounding Box Properties</p>
			<div className={style.propertiesContainerLeft}>
				<div key={'label'} className={style.propertyField}>
					<p className={style.propertyName}>
						Label
					</p>
					<TextField
						className={style.inputField}
						value={label}
						onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setLabel(event.target.value)}
						type={'text'}
						name={'label'}
						error={!label}
						helperText={!label ? 'Label can not be empty' : ''}
					/>
				</div>
				<div key={'height'} className={style.propertyField}>
					<p className={style.propertyName}>
						Height
					</p>
					<TextField
						className={style.inputField}
						value={height.value}
						onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setHeight({ ...height, value: parseInt(event.target.value) })}
						type={'number'}
						name={'height'}
						error={height.heightError}
						helperText={height.heightErrorMessage}
					/>
				</div>
				<div key={'width'} className={style.propertyField}>
					<p className={style.propertyName}>
						Width
					</p>
					<TextField
						className={style.inputField}
						value={width.value}
						onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setWidth({ ...width, value: parseInt(event.target.value) })}
						type={'number'}
						name={'width'}
						error={width.widthError}
						helperText={width.widthErrorMessage}
					/>
				</div>
				<div key={'x'} className={style.propertyField}>
					<p className={style.propertyName}>
						X
					</p>
					<TextField
						className={style.inputField}
						value={positionX.value}
						onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setPositionX({ ...positionX, value: parseInt(event.target.value) })}
						type={'number'}
						name={'x'}
						error={positionX.xError}
						helperText={positionX.xErrorMessage}
					/>
				</div>
				<div key={'y'} className={style.propertyField}>
					<p className={style.propertyName}>
						Y
					</p>
					<TextField
						className={style.inputField}
						value={positionY.value}
						onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setPositionY({ ...positionY, value: parseInt(event.target.value) })}
						type={'number'}
						name={'y'}
						error={positionY.yError}
						helperText={positionY.yErrorMessage}
					/>
				</div>
				{
					boundingBoxProperties.filter(item => !item.hide).map((item) => (
						<div key={item.name} className={style.propertyField}>
							<p className={style.propertyName}>
								{item.label}
							</p>
							<TextField
								className={style.inputField}
								value={item.value}
								type={item.type}
								name={item.name}
								error={item.error}
								helperText={item.errorMessage}
								disabled={item.isDisabled}
							/>
						</div>
					))
				}
			</div>
			<div className={style.containerButton}>
				{!isNewBoundingBox &&
					<IconButton style={{ marginRight: 10 }} size="small" aria-label="delete" onClick={deleteBoundingBox}>
						<DeleteOutline style={{ color: 'red' }} />
					</IconButton>
				}
				<div className={style.groupButton}>
					<div style={{ marginRight: 10 }} >
						<Button title={'Save'} onClick={onClickSaveButton} isDisabled={!label} />
					</div>
					<div>
						<Button title={'Cancel'} onClick={handleOnCancel} isSecondary />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ImageBoundingBoxPropertiesEdition;

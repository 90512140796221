import { ImageQuery, ImageResult } from '../interfaces/Image';
import React, { ReactNode } from 'react';
import ImageQueryService from '../services/ImageQueryService';
import { ImageResponse } from '../interfaces/Query';

export interface ImageQueryProviderStore {
	myImageQueryResult: ImageResult[];
	myImages: ImageResponse | undefined;
	isFetching: boolean;
	imagesPerPage: number;
	pageOffset: number;
	userQuery: string;
}

export interface ImageQueryUpdateProviderStore {
	fetchImageMetadata: (myImageQuery: ImageQuery) => Promise<void>;
	setPageOffset: (offset: number) => void;
	fetchMoreImages: (offset: number, queryProps?: ImageQuery | undefined, uniqueIdArray?: number[]) => void;
	setUserQuery: (query: string) => void;
	resetStates: () => void;
	setMyImageQueryResult: (image: ImageResult[]) => void;
}

export const ImageQueryContext = React.createContext({} as ImageQueryProviderStore);
export const ImageQueryUpdateContext = React.createContext({} as ImageQueryUpdateProviderStore);

const ImageQueryProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const [myImageQueryResult, setMyImageQueryResult] = React.useState<ImageResult[]>([]);
	const [myImages, setMyImages] = React.useState<ImageResponse | undefined>(undefined);
	const [isFetching, setIsFetching] = React.useState<boolean>(false);
	const [pageOffset, setPageOffset] = React.useState<number>(1);
	const [userQuery, setUserQuery] = React.useState<string>('[]');
	const [imageQuery, setImageQuery] = React.useState<ImageQuery | undefined>(undefined);
	const [uniqueIds, setUniqueIds] = React.useState<number[]>([]);
	const imagesPerPage = 50;

	const resetStates = (): void => {
		setMyImageQueryResult([]);
		setMyImages(undefined);
		setIsFetching(false);
		setPageOffset(1);
		setUserQuery('[]');
		setImageQuery(undefined);
		setUniqueIds([]);
	};

	const fetchImages = async (query: ImageQuery[]): Promise<void> => {
		setIsFetching(true);

		try {
			const images = await ImageQueryService.getImages(query);

			setMyImages(images);
		} catch (err) {
			Promise.reject((err as unknown as { name: string; message: string }).message);
		}

		setIsFetching(false);
		Promise.resolve();
	};

	const fetchMoreImages = async (offset: number, queryProps: ImageQuery | undefined = imageQuery, uniqueIdArray: number[] = uniqueIds): Promise<void> => {
		if (queryProps && uniqueIdArray.length) {
			const query: ImageQuery[] = ImageQueryService.getIndividualImageQueriesForBlob(queryProps, uniqueIdArray.slice((offset - 1) * imagesPerPage, ((offset - 1) * imagesPerPage) + imagesPerPage));

			await fetchImages(query);
		}
	};

	const fetchImageMetadata = async (myImageQuery: ImageQuery): Promise<void> => {
		setIsFetching(true);
		setImageQuery(myImageQuery);
		setMyImages(undefined);
		setMyImageQueryResult([]);
		setPageOffset(1);
		setUniqueIds([]);
		const query = myImageQuery.FindBoundingBox ?
			[{
				FindImage: myImageQuery.FindImage,
			}, {
				FindBoundingBox: myImageQuery.FindBoundingBox,
			}] :
			[myImageQuery];

		try {
			const imageMetadata = await ImageQueryService.getImageMetadata(JSON.parse(JSON.stringify(query)));

			setMyImageQueryResult(imageMetadata);

			if (imageMetadata.length) {
				const uniqueIdArray: number[] = ImageQueryService.getUniqueIdArray(imageMetadata);

				setUniqueIds(uniqueIdArray);
				await fetchMoreImages(1, myImageQuery, uniqueIdArray);
			}
		} catch (err) {
			setIsFetching(false);

			throw (err);
		}

		setIsFetching(false);
	};

	const store: ImageQueryProviderStore = {
		myImageQueryResult,
		myImages,
		isFetching,
		imagesPerPage,
		pageOffset,
		userQuery,
	};

	const updateStore: ImageQueryUpdateProviderStore = {
		fetchImageMetadata,
		setPageOffset,
		fetchMoreImages,
		setUserQuery,
		resetStates,
		setMyImageQueryResult,
	};

	return (
		<ImageQueryContext.Provider value={store}>
			<ImageQueryUpdateContext.Provider value={updateStore}>
				{children}
			</ImageQueryUpdateContext.Provider>
		</ImageQueryContext.Provider>
	);
};

export { ImageQueryProvider };
export const ImageQueryConsumer = ImageQueryContext.Consumer;

import './ManageToken.scss';
import { IconButton, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button } from '../components/Button';
import copyIcon from '../assets/copy-icon.png';
import deleteIcon from '../assets/delete-icon.png';
import { Modal } from '../components/Modal';
import React from 'react';
import { TextField } from '../components/TextField';
import tokenIcon from '../assets/token-icon.png';
import useManageToken from './Hooks/useManageToken';

const ManageToken: React.FC = () => {
	const { isManageTokenModalOpen, setIsManageTokenModalOpen, tokens, addTokenToRemoveList, generateToken, saveTokens, setError, error } = useManageToken();
	const [open, setOpen] = React.useState(false);

	const handleClick = (): void => {
		setOpen(true);
	};

	const handleClose = (event?: React.SyntheticEvent, reason?: string): void => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const handleErrorClose = (event?: React.SyntheticEvent, reason?: string): void => {
		if (reason === 'clickaway') {
			return;
		}

		setError('');
	};

	const copyToken = async (token: string): Promise<void> => {
		await navigator.clipboard.writeText(token);
		handleClick();
	};

	return (
		<Modal
			open={isManageTokenModalOpen}
			setOpen={setIsManageTokenModalOpen}
			title={'Manage tokens'}
			successButton={'save tokens'}
			successButtonOnClick={saveTokens}
		>
			<div className='manage-token-content'>
				{
					tokens.map(token => (
						<div className='manage-token-input' key={token}>
							<div className='manage-token-textfield'><TextField value={token} onChange={console.log} disabled={true} label={'Token'} iconInput={<img src={copyIcon} alt='Copy' onClick={async (): Promise<void> => await copyToken(token)} />} /></div>
							<IconButton aria-label="delete" className="manage-token-button" onClick={(): void => console.log()}>
								<img src={deleteIcon} alt='Delete' onClick={(): void => addTokenToRemoveList(token)} />
							</IconButton>
						</div>
					))
				}
				<Button title="Generate token" onClick={generateToken} startIcon={<img src={tokenIcon} alt='Token' />} isControlAccess={true} />
			</div>
			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<Alert onClose={handleClose} severity="success">
					Token copied to clipboard
				</Alert>
			</Snackbar>
			<Snackbar open={!!error} autoHideDuration={6000} onClose={handleErrorClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<Alert onClose={handleErrorClose} severity="error">
					{error}
				</Alert>
			</Snackbar>
		</Modal>
	);
};

export default ManageToken;

import React from 'react';
import style from './SectionTitle.module.scss';
import Typography from '@material-ui/core/Typography';

interface SectionTitleProps {
	title: string;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ title }) => {
	return (
		<Typography className={style.sectionTitle} component="span">{title}</Typography>
	);
};

export default SectionTitle;

import { fetchSimilaritySearch, fetchSimilaritySearchQuery } from '../api/SimilaritySearchQueryRepository';
import { SimilaritySearchResponse, SimilaritySearchResponseQuery } from '../interfaces/Query';

const getSimilaritySearch = async (uniqueId: string, descriptor: string = '', kNeighbors:number): Promise<SimilaritySearchResponse> => {
	const response = await fetchSimilaritySearch(uniqueId, descriptor, kNeighbors);
	const {blobs, descriptors, json} = response.data as SimilaritySearchResponse;

	return {blobs, descriptors, json};
};

const getSimilaritySearchQuery = async (uniqueId: string): Promise<SimilaritySearchResponseQuery> => {
	const response = await fetchSimilaritySearchQuery(uniqueId);
	const {blobs, json} = response.data as SimilaritySearchResponseQuery;

	return {blobs, json};
};

export default {
	getSimilaritySearch,
	getSimilaritySearchQuery,
};

import React from 'react';
import style from './VideoProperties.module.scss';
import { VideoData } from './Video';

interface VideoPropertiesProps {
	video: VideoData;
}

const VideoProperties: React.FC<VideoPropertiesProps> = ({ video }) => {
	return (
		<div className={style.videoProperties}>
			<p className={style.titleLeft}>
				Properties
			</p>
			<div className={style.container}>
				{Object.keys(video.properties).length ? (
					Object.entries(video.properties).map(([name, value]) => (
						<div
							key={name}
							className={style.property}
						>
							<p className={style.propertyName}>
								{name}
							</p>
							<p className={style.propertyValue}>
								{value === null || Array.isArray(value) ? 'null' : (typeof value === 'object' ? value['_date'] : value).toString()}
							</p>
						</div>
					))
				) : (
					<div className={style.property}>
						<p className={style.propertyName}>No properties</p>
						<p className={style.propertyValue}>No values</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default VideoProperties;

import './AccessControlNewRole.scss';
import { FullRoleAccessLevelModel, RolePermissionsModel } from '../interfaces/AccessControl';
import { Snackbar, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import AccessControlService from '../services/AccessControlService';
import { Alert } from '@material-ui/lab';
import { CheckBox } from '../components/CheckBox';
import { Modal } from '../components/Modal';
import Paper from '@material-ui/core/Paper';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import React from 'react';
import { TextField } from '../components/TextField';
import useAccessControlNewRole from './Hooks/useAccessControlNewRole';

const Row = (props: { row: RolePermissionsModel; roleNameType: string; showColor: boolean; updateOptions: (option: keyof FullRoleAccessLevelModel, permission: keyof RolePermissionsModel, value: boolean) => void; option: keyof FullRoleAccessLevelModel}): JSX.Element => {
	const { row, roleNameType, showColor, updateOptions, option } = props;

	return (
		<React.Fragment>
			<TableRow className={showColor ? 'access-control-table__row' : undefined}>
				<TableCell>
					<Typography component="span" className="access-control-inside-table__entity-name">
						{AccessControlService.getRoleTypeName(roleNameType)}
					</Typography>
				</TableCell>
				<TableCell align="center" className="access-control-inside-table__text-body">
					<CheckBox
						isChecked={row.read}
						onChange={(value: boolean): void => {
							updateOptions(option, 'read' as keyof RolePermissionsModel, value);
						}}
					/>
				</TableCell>
				<TableCell align="center" className="access-control-inside-table__text-body">
					<CheckBox
						isChecked={row.create}
						onChange={(value: boolean): void => {
							updateOptions(option, 'create' as keyof RolePermissionsModel, value);
						}}
					/>
				</TableCell>
				<TableCell align="center" className="access-control-inside-table__text-body">
					<CheckBox
						isChecked={row.update}
						onChange={(value: boolean): void => {
							updateOptions(option, 'update' as keyof RolePermissionsModel, value);
						}}
					/>
				</TableCell>
				<TableCell align="center" className="access-control-inside-table__text-body">
					<CheckBox
						isChecked={row.delete}
						onChange={(value: boolean): void => {
							updateOptions(option, 'delete' as keyof RolePermissionsModel, value);
						}}
					/>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
};

const AccessControlNewRole: React.FC = () => {
	const { isNewRoleModalOpen, setIsNewRoleModalOpen, rolename, setRolename, saveNewRole, error, setError, options, updateOptions } = useAccessControlNewRole();

	const rolenameHandler = (value: string): void => {
		setRolename(value);
	};

	const handleErrorClose = (event?: React.SyntheticEvent, reason?: string): void => {
		if (reason === 'clickaway') {
			return;
		}

		setError('');
	};

	return (
		<Modal open={isNewRoleModalOpen} setOpen={setIsNewRoleModalOpen} title={'Add a new role'} successButton={'add a new role'} successButtonOnClick={(): void => saveNewRole()} successButtonDisabled={!rolename}>
			<div className='new-role-content'>
				<div className='new-role-input'>
					<TextField value={rolename} onChange={rolenameHandler} label={'Role name'} iconInput={<PersonOutlineIcon />} required />
				</div>
				<TableContainer component={Paper} className="access-control-interior-table" elevation={0}>
					<Table size="small" aria-label="roles">
						<colgroup>
							<col style={{ width: '40%' }} />
							<col style={{ width: '15%' }} />
							<col style={{ width: '15%' }} />
							<col style={{ width: '15%' }} />
							<col style={{ width: '15%' }} />
						</colgroup>
						<TableBody className="access-control-inside-table__header">
							<TableRow>
								<TableCell scope="row" className="access-control-inside-table__text-header"></TableCell>
								<TableCell scope="row" align="center" className="access-control-inside-table__text-header">View</TableCell>
								<TableCell scope="row" align="center" className="access-control-inside-table__text-header">Create</TableCell>
								<TableCell scope="row" align="center" className="access-control-inside-table__text-header">Update</TableCell>
								<TableCell scope="row" align="center" className="access-control-inside-table__text-header">Delete</TableCell>
							</TableRow>
							{
								Object.entries(options as FullRoleAccessLevelModel).map(([roleNameType, values], index) => (<Row key={rolename + roleNameType} row={values} roleNameType={roleNameType} showColor={index % 2 === 0} updateOptions={updateOptions} option={roleNameType as keyof FullRoleAccessLevelModel} />))
							}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
			<Snackbar open={!!error} autoHideDuration={6000} onClose={handleErrorClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<Alert onClose={handleErrorClose} severity="error">
					{error}
				</Alert>
			</Snackbar>
		</Modal>
	);
};

export default AccessControlNewRole;

import { ImageData } from './Image';
import React from 'react';
import style from './ImageProperties.module.scss';

interface ImagePropertiesProps {
	image: ImageData;
}

const ImageProperties: React.FC<ImagePropertiesProps> = ({ image }) => {
	return (
		<>
			<p className={style.titleLeft}>Properties</p>
			<div className={style.propertiesContainerLeft}>
				{
					Object.keys(image.properties).length
						? (
							Object.entries(image.properties).map(([name, value]) => (
								<div key={name} className={style.property}>
									<p className={style.propertyName}>{name}</p>
									<p className={style.propertyValue}>{value === null || Array.isArray(value) ? 'null' : (typeof value === 'object' ? value['_date'] : value).toString()}</p>
								</div>
							))
						)
						: (
							<div className={style.property}>
								<p className={style.propertyName}>No properties</p>
								<p className={style.propertyValue}>No values</p>
							</div>
						)
				}
			</div>
		</>
	);
};

export default ImageProperties;

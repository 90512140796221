import './UsersTable.scss';
import { MyUserModel, UserInfo } from '../../interfaces/AccessControl';
import AddIcon from '@material-ui/icons/Add';
import { AuthenticationContext } from '../../contexts/AuthenticationContext';
import { Button } from '../../components/Button';
import EditIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import { ModalUpdateContext } from '../../contexts/ModalContext';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import { RefreshButton } from '../../components/RefreshButton';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import { SectionTitle } from '../../components/SectionTitle';
import style from './UsersTable.module.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TextField } from '../../components/TextField';
import Typography from '@material-ui/core/Typography';
import { useAccessControl } from './Hooks/useAccessControl';

const Row = (props: { row: UserInfo; username: string; showColor: boolean; myUser: MyUserModel | null }): JSX.Element => {
	const { row, username, showColor, myUser } = props;
	const { setIsEditUserModalOpen, setUserToEdit } = React.useContext(ModalUpdateContext);

	return (
		<React.Fragment>
			<TableRow className={showColor ? `${style.rowWithColor}` : `${style.rowWithOutColor}`}>
				<TableCell>
					<Typography component="span" className={style.entityName}>
						{username}
					</Typography>
				</TableCell>
				<TableCell align="left" className={style.text}>
					{row.roles?.join(', ')}
				</TableCell>
				{myUser?.permissions.access_control.update && <TableCell align="right" className={style.text}>
					<IconButton className={style.arrowButton} aria-label="expand row" size="small" onClick={(): void => { setIsEditUserModalOpen(true); setUserToEdit({ username, ...row }); }}>
						<EditIcon style={{ fontSize: 18 }} />
					</IconButton>
				</TableCell>}
			</TableRow>
		</React.Fragment>
	);
};

const UsersTable: React.FC = () => {
	const { users, fetchValues, isFetching, userSearch, setUserSearch } = useAccessControl();
	const { setIsNewUserModalOpen } = React.useContext(ModalUpdateContext);
	const { myUser } = React.useContext(AuthenticationContext);

	return (
		<div className={style.userTable}>
			<div className={style.headerContainer}>
				<div className={style.title}>
					<SectionTitle title="Users" />
					<RefreshButton
						isLoading={isFetching}
						onClick={fetchValues}
					/>
				</div>
				<div className={style.actions}>
					<div className={style.search}>
						<TextField
							value={userSearch}
							onChange={setUserSearch}
							placeholder="Search"
							iconInput={<SearchIcon />}
						/>
					</div>
					{
						myUser?.permissions.access_control.create && (
							<div className={style.ddUserButton}>
								<Button
									title="Add User"
									onClick={(): void => { setIsNewUserModalOpen(true); }}
									isSecondary={true}
									startIcon={<AddIcon />}
								/>
							</div>
						)
					}
				</div>
			</div>
			<div className={style.tableContainer}>
				<TableContainer component={Paper} className={style.table} elevation={0}>
					<Table size="small" aria-label="users">
						<colgroup>
							<col style={{ width: '50%' }} />
							<col style={{ width: '40%' }} />
							{myUser?.permissions.access_control.update && <col style={{ width: '10%' }} />}
						</colgroup>
						<TableHead className={style.tableHeader}>
							<TableRow>
								<TableCell scope="row" className={style.text} >USER NAME</TableCell>
								<TableCell className={style.text} >ROLE</TableCell>
								{myUser?.permissions.access_control.update && <TableCell className={style.text}></TableCell>}
							</TableRow>
						</TableHead>
						<TableBody className={style.body}>
							{
								users
									? (
										Object
											.entries(users.GetUsers)
											.filter(([username]) => {
												if (userSearch && username.search(new RegExp(userSearch, 'i')) === -1) {
													return false;
												}

												if (username === 'status' || username === 'info') {
													return false;
												}

												return true;
											}).length
											? (
												Object.entries(users.GetUsers)
													.filter(([username]) => {
														if (userSearch && username.search(new RegExp(userSearch, 'i')) === -1) {
															return false;
														}

														if (username === 'status' || username === 'info') {
															return false;
														}

														return true;
													})
													.sort((a, b) => {
														if (a[0].toLocaleLowerCase() === b[0].toLocaleLowerCase()) {
															return 0;
														}

														if (a[0].toLocaleLowerCase() === 'admin') {
															return -1;
														}

														if (b[0].toLocaleLowerCase() === 'admin') {
															return 1;
														}

														return a[0].localeCompare(b[0]);
													})
													.map(([username, values], index) => (<Row key={username} row={values} username={username} showColor={index % 2 !== 0} myUser={myUser} />))
											)
											: (
												<TableRow><TableCell align="center" className={style.text} colSpan={3}>No search results</TableCell></TableRow>
											)
									)
									: (
										<TableRow><TableCell align="center" className={style.text} colSpan={3}>Database is empty</TableCell></TableRow>
									)
							}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</div>
	);
};

export default UsersTable;

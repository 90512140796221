import React, { ReactNode } from 'react';
import { AuthenticationContext } from './AuthenticationContext';
import { DataBaseStatus } from '../interfaces/DataBaseStatus';
import StatusService from '../services/StatusService';

export interface StatusProviderStore {
	myStatus: DataBaseStatus[];
	isFetching: boolean;
}

export interface StatusUpdateProviderStore {
	setMyStatus: (myStatus: DataBaseStatus[]) => void;
	fetchStatus: () => void;
}

export const StatusContext = React.createContext({} as StatusProviderStore);
export const StatusUpdateContext = React.createContext({} as StatusUpdateProviderStore);

const StatusProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const { isAuthentication, myUser } = React.useContext(AuthenticationContext);
	const [myStatus, setMyStatus] = React.useState<DataBaseStatus[]>([]);
	const [isFetching, setIsFetching] = React.useState(false);

	const fetchStatus = async (): Promise<void> => {
		if (isAuthentication && myUser?.permissions.objects.read) {
			setIsFetching(true);

			try {
				const status = await StatusService.getDataBaseStatusInfo();

				setMyStatus(status);
			} catch (err) {
				Promise.reject((err as unknown as { name: string; message: string }).message);
			}

			setIsFetching(false);
		} else {
			setMyStatus([]);
		}

		Promise.resolve();
	};

	React.useEffect(() => {
		fetchStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthentication, myUser]);

	const store: StatusProviderStore = {
		myStatus,
		isFetching,
	};

	const updateStore: StatusUpdateProviderStore = {
		setMyStatus,
		fetchStatus,
	};

	return (
		<StatusContext.Provider value={store}>
			<StatusUpdateContext.Provider value={updateStore}>
				{children}
			</StatusUpdateContext.Provider>
		</StatusContext.Provider>
	);
};

export { StatusProvider };
export const StatusConsumer = StatusContext.Consumer;

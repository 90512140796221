import { Box, Typography } from '@material-ui/core';
import React from 'react';
import style from './OperationResizeBox.module.scss';
import { TextField } from '../../../components/TextField';

interface OperationResizeBoxProps {
	data: unknown;
	updateOperation: (data: unknown) => void;
}

export interface OperationResizeData {
	height: string;
	width: string;
}

const OperationResizeBox: React.FC<OperationResizeBoxProps> = ({ data, updateOperation }) => {
	const textData = data as OperationResizeData;

	const onChangeHeight = (value: string): void => {
		updateOperation({ ...textData, height: value } as OperationResizeData);
	};

	const onChangeWidth = (value: string): void => {
		updateOperation({ ...textData, width: value } as OperationResizeData);
	};

	return (
		<Box className={style.operationResizeBox}>
			<Box className={style.height}>
				<Typography className={style.text}>
					Height
				</Typography>
				<TextField placeholder="Integer..." value={textData.height} onChange={onChangeHeight} type={'number-integer'} />
			</Box>
			<Box className={style.width}>
				<Typography className={style.text}>
					Width
				</Typography>
				<TextField placeholder="Integer..." value={textData.width} onChange={onChangeWidth} type={'number-integer'} />
			</Box>
		</Box>
	);
};

export default OperationResizeBox;

import { AppBar, Tab, Tabs, Toolbar } from '@material-ui/core';
import { AuthenticationContext, AuthenticationUpdateContext } from '../../contexts/AuthenticationContext';
import { useHistory, useLocation } from 'react-router-dom';
import {appVersion} from '../../helpers/appVersion';
import { cleanUserLoginData } from '../../services/LocalStorageService';
import { CustomQueryUpdateContext } from '../../contexts/CustomQueryContext';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import { ImageQueryUpdateContext } from '../../contexts/ImageQueryContext';
import logo from '../../assets/logo-horizontal-color.svg';
import { ModalUpdateContext } from '../../contexts/ModalContext';
import React from 'react';
import style from './Appbar.module.scss';
import Typography from '@material-ui/core/Typography';
import { useRoutes } from '../../helpers/hooks';

const Appbar: React.FC = () => {
	const { routes } = useRoutes();
	const location = useLocation();
	const history = useHistory();
	const [value, setValue] = React.useState<string>(Object.keys(routes).some(route => routes[route] === location.pathname) && location.pathname !== routes.root ? location.pathname : routes.status);
	const { setIsManageTokenModalOpen, setIsOpenUpdate } = React.useContext(ModalUpdateContext);
	const { myUser } = React.useContext(AuthenticationContext);
	const { setIsAuthentication } = React.useContext(AuthenticationUpdateContext);
	const { resetStates: resetImageQueryStates } = React.useContext(ImageQueryUpdateContext);
	const { resetStates: resetCustomQueryStates } = React.useContext(CustomQueryUpdateContext);

	const handleChange = (event: React.ChangeEvent<unknown>, newValue: string): void => {
		setValue(newValue);
	};

	const menuOptions = [
		{
			option: 'Advanced Search',
			link: '',
			onClick: (): void => { history.push(`${routes.advancedSearch}?type=image&_uniqueId=`); },
		},
		{
			option: 'Similarity Search',
			link: '',
			onClick: (): void => { history.push(`${routes.similaritySearch}?_uniqueId=`); },
		},
		{
			option: 'Update password',
			link: '',
			onClick: (): void => { setIsOpenUpdate(true); },
		},
		{
			option: 'Manage tokens',
			link: '',
			onClick: (): void => { setIsManageTokenModalOpen(true); },
		},
		{
			option: 'Log Out',
			link: '',
			onClick: (): void => {
				cleanUserLoginData();
				setIsAuthentication(false);
				resetImageQueryStates();
				resetCustomQueryStates();
			},
		},
		{
			option: 'Documentation',
			link: 'https://docs.aperturedata.io/',
			target: '_blank',
			isDivider: true,
		},
		{
			option: `App version ${appVersion}`,
			link: '',
			isDisabled: true,
			isDivider: true,
		},
	];

	React.useEffect(() => {
		setValue(Object.keys(routes).some(route => routes[route] === location.pathname) && location.pathname !== routes.root ? location.pathname : routes.status);
	}, [location, routes]);

	return (
		<AppBar position={'static'} elevation={0}>
			<Toolbar className={style.appBar}>
				<div className={style.appBarTitleContainer}>
					<img className={style.appBarTitle} src={logo} alt="ApertureDB" />
				</div>
				<div className={style.navButtons}>
					<Tabs value={value} onChange={handleChange} className={style.appBarTabs} classes={{ flexContainer: style.flexContainer, indicator: style.tabsIndicator}}>
						<Tab label="STATUS" value={routes.status} className={style.appBarTab} onClick={(): void => history.push(routes.status)} />
						{myUser?.permissions.objects.read && <Tab label="IMAGE SEARCH" value={routes.imageSearch} className={style.appBarTab} onClick={(): void => history.push(routes.imageSearch)} />}
						<Tab label="VIDEO SEARCH" value={routes.videoSearch} className={style.appBarTab} onClick={(): void => history.push(routes.videoSearch)} />
						<Tab label="CUSTOM QUERY" value={routes.customQuery} className={style.appBarTab} onClick={(): void => history.push(routes.customQuery)} />
						{myUser?.permissions.access_control.read && <Tab label="ACCESS CONTROL" value={routes.accessControl} className={style.appBarTab} onClick={(): void => history.push(routes.accessControl)} />}
					</Tabs>
				</div>
				<Typography className={style.appBarUser} component="span">{myUser?.username}</Typography>
				<DropdownMenu options={menuOptions} />
			</Toolbar>
		</AppBar>
	);
};

export default Appbar;

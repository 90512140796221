import { IconButton, Modal } from '@material-ui/core';
import { Button } from '../Button';
import CheckIcon from '@material-ui/icons/Check';
import { Close } from '@material-ui/icons';
import React from 'react';
import styles from './ModalConfirm.module.scss';

interface ModalProps {
	isOpenConfirm: boolean;
	handleConfirm: () => void;
	handleClose: () => void;
}
const ModalConfirm: React.FC<ModalProps> = ({isOpenConfirm, handleConfirm, handleClose}) => {
	return (
		<Modal open={isOpenConfirm}>
			<div className={styles.modalBody}>
				<div className={styles.headModal}>
					<div>
						<IconButton onClick={handleClose}>
							<Close />
						</IconButton>
					</div>
				</div>
				<div className={styles.textContainer}>
					<p>By selecting this query we will replace the current
						one and any changes not saved might get lost.
						Are you sure you want to continue?
					</p>
				</div>
				<div className={styles.btnContainer}>
					<div>
						<Button
							title="Cancel"
							isSecondary={true}
							onClick={handleClose}
							startIcon={<Close />}
						/>
					</div>
					<div>
						<Button
							title="Accept"
							onClick={handleConfirm}
							startIcon={<CheckIcon />}
						/>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ModalConfirm;

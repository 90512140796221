import { AddBoundingBoxRequest, DeleteBoundingBoxRequest, UpdateBoundingBoxRequest } from './../../services/BoundingBoxService';
import { BoundingBoxEntities, Coordinates } from '../../interfaces/Image';
import { CustomQueryContext, CustomQueryUpdateContext } from '../../contexts/CustomQueryContext';
import { getBoundingBoxProperties, InputFieldProperties } from './BoundingProperties/BoundingProperties';
import { ImageQueryContext, ImageQueryUpdateContext } from '../../contexts/ImageQueryContext';
import { InputsFieldHeight, InputsFieldPositionX, InputsFieldPositionY, InputsFieldWidth } from '../../interfaces/InputsFieldsBoundigEdition';
import BoundingBoxService from '../../services/BoundingBoxService';
import React from 'react';

interface Entity {
	area: number;
	area_perc: number;
	bbox_id: number;
	category_id: number;
	image_id: number;
	is_crowd: number;
	_coordinates: {
		height: number;
		width: number;
		x: number;
		y: number;
	};
	_label: string;
	_uniqueid: string;
}

interface UseImageBoundingBoxPropertiesEditionStore {
	height: InputsFieldHeight;
	setHeight: (values: InputsFieldHeight) => void;
	width: InputsFieldWidth;
	setWidth: (values: InputsFieldWidth) => void;
	positionX: InputsFieldPositionX;
	setPositionX: (values: InputsFieldPositionX) => void;
	positionY: InputsFieldPositionY;
	setPositionY: (values: InputsFieldPositionY) => void;
	label: string;
	setLabel: (label: string) => void;
	boundingBoxProperties: InputFieldProperties[];
	isNewBoundingBox: boolean;
	deleteBoundingBox: () => Promise<void>;
	handleOnCancel: () => void;
	onClickSaveButton: () => void;
}

interface CoordinatesErrors {
	heightError: boolean;
	heightErrorMessage: string;
	widthError: boolean;
	widthErrorMessage: string;
	xError: boolean;
	xErrorMessage: string;
	yError: boolean;
	yErrorMessage: string;
}

const coordinatesErrorsInitialValues = {
	heightError: false,
	heightErrorMessage: '',
	widthError: false,
	widthErrorMessage: '',
	xError: false,
	xErrorMessage: '',
	yError: false,
	yErrorMessage: '',
};

export const UseImageBoundingBoxPropertiesEdition: (imageUniqueid: number, selectedBoundingBox: BoundingBoxEntities | null, resetSelectedBoundingBox: () => void, imageNaturalSize: HTMLImageElement | null) => UseImageBoundingBoxPropertiesEditionStore = (imageUniqueid, selectedBoundingBox, resetSelectedBoundingBox, imageNaturalSize) => {
	const defaultCoordinatesValues = {
		height: 0,
		width: 0,
		x: 0,
		y: 0,
	};
	const [coordinates, setCoordinates] = React.useState<Coordinates>(selectedBoundingBox?._coordinates ?? defaultCoordinatesValues);
	const [label, setLabel] = React.useState<string>(selectedBoundingBox?._label || '');
	const [newBoundingBox, setNewBoundingBox] = React.useState<BoundingBoxEntities | null>(null);
	const [boundingBoxProperties, setBoundingBoxProperties] = React.useState<InputFieldProperties[]>([]);
	const [errors, setErrors] = React.useState<CoordinatesErrors>(coordinatesErrorsInitialValues);
	const [height, setHeight] = React.useState<InputsFieldHeight>({
		value: selectedBoundingBox?._coordinates.height || 0,
		heightError: false,
		heightErrorMessage: ''});
	const [width, setWidth] = React.useState<InputsFieldWidth>({
		value: selectedBoundingBox?._coordinates.width || 0,
		widthError: false,
		widthErrorMessage: ''});
	const [positionX, setPositionX] = React.useState<InputsFieldPositionX>({
		value: selectedBoundingBox?._coordinates.x || 0,
		xError: false,
		xErrorMessage: ''});
	const [positionY, setPositionY] = React.useState<InputsFieldPositionY>({
		value: selectedBoundingBox?._coordinates.y || 0,
		yError: false,
		yErrorMessage: ''});

	React.useEffect(() => {
		if(selectedBoundingBox?._coordinates){
			setHeight({...height, value: selectedBoundingBox?._coordinates.height });
			setWidth({...width, value: selectedBoundingBox?._coordinates.width });
			setPositionX({...positionX, value: selectedBoundingBox?._coordinates.x });
			setPositionY({...positionY, value: selectedBoundingBox?._coordinates.y });
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedBoundingBox]);

	const { myImageQueryResult } = React.useContext(ImageQueryContext);
	const { setMyImageQueryResult } = React.useContext(ImageQueryUpdateContext);

	const { myCustomQueryResult } = React.useContext(CustomQueryContext);
	const { setMyCustomQueryResult } = React.useContext(CustomQueryUpdateContext);

	const checkCoordinatesErrors = (): boolean => {
		const naturalHeight = imageNaturalSize?.naturalHeight || 0;
		const naturalWidth = imageNaturalSize?.naturalWidth || 0;
		const xHasError = isNaN(positionX.value) || positionX.value < 0 || positionX.value >= naturalWidth;
		const yHasError = isNaN(positionY.value) || positionY.value < 0 || positionY.value >= naturalHeight;

		if (xHasError || yHasError) {
			setPositionX({...positionX,
				xError: xHasError,
				xErrorMessage: xHasError ? `Value must be between 0 and ${naturalWidth - 1}` : '',
			});
			setPositionY({...positionY,
				yError: yHasError,
				yErrorMessage: yHasError ? `Value must be between 0 and ${naturalHeight - 1}` : '',
			});
		} else {
			const widthHasError = isNaN(width.value) || width.value < 1 || width.value > naturalWidth;
			const heightHasError = isNaN(height.value) || height.value < 1 || height.value > naturalHeight;

			if (widthHasError || heightHasError) {
				setWidth({...width,
					widthError: widthHasError,
					widthErrorMessage: widthHasError ? `Value must be between 1 and ${naturalWidth}` : '',
				});
				setHeight({
					...height, heightError: heightHasError,
					heightErrorMessage: heightHasError ? `Value must be between 1 and ${naturalHeight}` : '',
				});
			} else {
				const xWidthHasError = positionX.value + width.value > naturalWidth;
				const yHeightHasError = positionY.value + height.value > naturalHeight;

				if (xWidthHasError || yHeightHasError) {
					setWidth({...width,
						widthError: xWidthHasError,
						widthErrorMessage: xWidthHasError ? `Width plus X must be less or equal than ${naturalWidth}` : '',
					});
					setHeight({
						...height, heightError: yHeightHasError,
						heightErrorMessage: yHeightHasError ? `Height plus Y must be less or equal than ${naturalHeight}` : '',
					});
				} else {
					setErrors(coordinatesErrorsInitialValues);

					return false;
				}
			}
		}

		return true;
	};

	const boundingBoxExists = (uniqueid: string | undefined): boolean => {
		let boundingBoxExists = false;

		if (uniqueid && myImageQueryResult[1]?.FindBoundingBox && (!myImageQueryResult[1]?.FindBoundingBox?.entities || !myImageQueryResult[1]?.FindBoundingBox?.entities[imageUniqueid])) {
			myImageQueryResult[1].FindBoundingBox['entities'] = { [imageUniqueid]: [] };
		}

		if (uniqueid && myImageQueryResult[1]?.FindBoundingBox?.entities && myImageQueryResult[1]?.FindBoundingBox?.entities[imageUniqueid]) {
			myImageQueryResult[1].FindBoundingBox?.entities[imageUniqueid].forEach(entity => {
				if (String(entity._uniqueid) === uniqueid) {
					boundingBoxExists = true;
				}
			});
		}

		myCustomQueryResult.forEach((item)=>{
			if (uniqueid && (!item?.FindBoundingBox?.entities || !item?.FindBoundingBox?.entities[imageUniqueid])) {
				item.FindBoundingBox['entities'] = { [imageUniqueid]: [] };
			}

			if (uniqueid && item?.FindBoundingBox?.entities && item?.FindBoundingBox?.entities[imageUniqueid]) {
				item.FindBoundingBox?.entities[imageUniqueid].forEach((entity: Entity) => {
					if (String(entity._uniqueid) === uniqueid) {
						boundingBoxExists = true;
					}
				});
			}
		});

		return boundingBoxExists;
	};

	const addNewBoundingBoxToMyImageQueryResult = (boundingBox: BoundingBoxEntities | null): void => {
		if (boundingBox && myImageQueryResult[1]?.FindBoundingBox && (!myImageQueryResult[1]?.FindBoundingBox?.entities || !myImageQueryResult[1]?.FindBoundingBox?.entities[imageUniqueid])) {
			myImageQueryResult[1].FindBoundingBox['entities'] = { [imageUniqueid]: [] };
		}

		if (boundingBox && myImageQueryResult[1]?.FindBoundingBox?.entities && myImageQueryResult[1]?.FindBoundingBox?.entities[imageUniqueid]) {
			const imageQueryResultCopy = myImageQueryResult.map(value => ({...value}));

			imageQueryResultCopy[1].FindBoundingBox?.entities[imageUniqueid].push(boundingBox);
			setNewBoundingBox(boundingBox);

			setMyImageQueryResult([...imageQueryResultCopy]);
		}

		myCustomQueryResult.forEach((item)=>{
			if (boundingBox && (!item?.FindBoundingBox?.entities || !item?.FindBoundingBox?.entities[imageUniqueid])) {
				item.FindBoundingBox['entities'] = { [imageUniqueid]: [] };
			}

			if (boundingBox && item?.FindBoundingBox?.entities && item?.FindBoundingBox?.entities[imageUniqueid]) {
				const imageQueryResultCopy = myCustomQueryResult.map(value => ({...value}));

				item.FindBoundingBox?.entities[imageUniqueid].push(boundingBox);
				setNewBoundingBox(boundingBox);

				setMyCustomQueryResult([...imageQueryResultCopy]);
			}
		});
	};

	const updateMyQueryResult = (uniqueid?: string, updatedUniqueid?: string): void => {
		if (uniqueid && myImageQueryResult[1]?.FindBoundingBox && (!myImageQueryResult[1]?.FindBoundingBox?.entities || !myImageQueryResult[1]?.FindBoundingBox?.entities[imageUniqueid])) {
			myImageQueryResult[1].FindBoundingBox['entities'] = { [imageUniqueid]: [] };
		}

		if (uniqueid && myImageQueryResult[1]?.FindBoundingBox?.entities && myImageQueryResult[1]?.FindBoundingBox?.entities[imageUniqueid]) {
			const imageQueryResultCopy = myImageQueryResult.map(value => ({...value}));

			imageQueryResultCopy[1].FindBoundingBox?.entities[imageUniqueid].forEach(entity => {
				if (String(entity._uniqueid) === uniqueid) {
					if (updatedUniqueid) {
						entity._uniqueid = updatedUniqueid;
					}

					entity._label = label.trim();
					entity._coordinates = {...coordinates, height: height.value, width: width.value, x: positionX.value, y: positionY.value};
				}
			});

			setMyImageQueryResult([...imageQueryResultCopy]);
		}

		myCustomQueryResult.forEach((item) => {
			if (uniqueid && (!item?.FindBoundingBox?.entities || !item?.FindBoundingBox?.entities[imageUniqueid])) {
				item.FindBoundingBox['entities'] = { [imageUniqueid]: [] };
			}

			if (uniqueid && item?.FindBoundingBox?.entities && item?.FindBoundingBox?.entities[imageUniqueid]) {
				const imageQueryResultCopy = myCustomQueryResult.map(value => ({...value}));

				item.FindBoundingBox?.entities[imageUniqueid].forEach((entity: Entity) => {
					if (String(entity._uniqueid) === uniqueid) {
						if (updatedUniqueid) {
							entity._uniqueid = updatedUniqueid;
						}

						entity._label = label.trim();
						entity._coordinates = {...coordinates, height: height.value, width: width.value, x: positionX.value, y: positionY.value};
					}
				});

				setMyCustomQueryResult([...imageQueryResultCopy]);
			}
		});

	};

	const addBoundingBox = async (): Promise<void> => {
		if (newBoundingBox) {
			const labelValue = label.trim();
			const query: AddBoundingBoxRequest = {
				FindImage: {
					constraints: {
						_uniqueid: ['==', imageUniqueid],
					},
					blobs: false,
					_ref: 1,
				},
				AddBoundingBox: {
					'image_ref': 1,
					rectangle: {
						x: positionX.value,
						y: positionY.value,
						width: width.value,
						height: height.value,
					},
					label: labelValue,
				},
				FindBoundingBox: {
					'with_label': labelValue,
					constraints: {
						_x: ['==', positionX.value],
						_y: ['==', positionY.value],
						_width: ['==', width.value],
						_height: ['==', height.value],
					},
					'group_by_source': true,
					coordinates: true,
					results: {
						'all_properties': true,
					},
				},
			};

			await BoundingBoxService.addBoundingBox(query).then((response: [any, any, { FindBoundingBox: any }] | undefined) => {
				if (response) {
					const firstEntityKey = Object.keys(response[2].FindBoundingBox.entities)[0];

					updateMyQueryResult(newBoundingBox._uniqueid, response[2].FindBoundingBox.entities[firstEntityKey]._uniqueid);
				}

				resetSelectedBoundingBox();
				setNewBoundingBox(null);
			}).catch(err => Promise.reject(err));
		}
	};

	const updateBoundingBox = async (): Promise<void> => {
		if (selectedBoundingBox?._uniqueid) {
			const query: UpdateBoundingBoxRequest[] = [{
				UpdateBoundingBox: {
					constraints: {
						_uniqueid: ['==', selectedBoundingBox._uniqueid],
					},
					label: label.trim(),
					rectangle: {
						height: height.value,
						width: width.value,
						x: positionX.value,
						y: positionY.value,
					},
				},
			}];

			await BoundingBoxService.updateBoundingBoxProperties(query).then(() => {
				updateMyQueryResult(selectedBoundingBox?._uniqueid);
				resetSelectedBoundingBox();
			}).catch(err => Promise.reject(err));
		}
	};

	const saveBoundingBox = async (): Promise<void> => {
		if (newBoundingBox) {
			await addBoundingBox();
		} else {
			await updateBoundingBox();
		}
	};

	const removeBoundingBoxFromMyImageQueryResult = (uniqueid: string | undefined): void => {
		let indexToDelete: number = 0;

		if (uniqueid && myImageQueryResult[1]?.FindBoundingBox && (!myImageQueryResult[1]?.FindBoundingBox?.entities || !myImageQueryResult[1]?.FindBoundingBox?.entities[imageUniqueid])) {
			myImageQueryResult[1].FindBoundingBox['entities'] = { [imageUniqueid]: [] };
		}

		if (uniqueid && myImageQueryResult[1]?.FindBoundingBox?.entities && myImageQueryResult[1]?.FindBoundingBox?.entities[imageUniqueid]) {
			const imageQueryResultCopy = myImageQueryResult.map(value => ({...value}));

			imageQueryResultCopy[1].FindBoundingBox?.entities[imageUniqueid].forEach((entity, index: number )=> {
				if (String(entity._uniqueid) === uniqueid) {
					indexToDelete = index;
				}
			});

			imageQueryResultCopy[1].FindBoundingBox?.entities[imageUniqueid].splice(indexToDelete, 1);

			setMyImageQueryResult([...imageQueryResultCopy]);
			resetSelectedBoundingBox();
		}

		myCustomQueryResult.forEach((item)=>{
			if (uniqueid && (!item?.FindBoundingBox?.entities || !item?.FindBoundingBox?.entities[imageUniqueid])) {
				item.FindBoundingBox['entities'] = { [imageUniqueid]: [] };
			}

			if (uniqueid && item?.FindBoundingBox?.entities && item?.FindBoundingBox?.entities[imageUniqueid]) {
				const imageQueryResultCopy = myCustomQueryResult.map(value => ({...value}));

				item.FindBoundingBox?.entities[imageUniqueid].forEach((entity: Entity, index: number )=> {
					if (String(entity._uniqueid) === uniqueid) {
						indexToDelete = index;
					}
				});

				item.FindBoundingBox?.entities[imageUniqueid].splice(indexToDelete, 1);

				setMyCustomQueryResult([...imageQueryResultCopy]);
				resetSelectedBoundingBox();
			}
		});
	};

	const deleteBoundingBox = async (): Promise<void> => {
		if (selectedBoundingBox?._uniqueid) {
			const query: DeleteBoundingBoxRequest[] = [{
				DeleteBoundingBox: {
					constraints: {
						_uniqueid: ['==', selectedBoundingBox._uniqueid],
					},
				},
			}];

			await BoundingBoxService.deleteBoundingBoxProperties(query).then(() => {
				removeBoundingBoxFromMyImageQueryResult(selectedBoundingBox?._uniqueid);
			}).catch(err => Promise.reject(err));
		}
	};

	const handleOnCancel = (): void => {
		resetSelectedBoundingBox();

		if(newBoundingBox) {
			removeBoundingBoxFromMyImageQueryResult(newBoundingBox._uniqueid);
			setNewBoundingBox(null);
		}
	};

	const onClickSaveButton = (): void => {
		const hasErrors = checkCoordinatesErrors();

		if (!hasErrors) {
			saveBoundingBox();
		}
	};

	React.useEffect(() => {
		const boundingBoxExist = boundingBoxExists(selectedBoundingBox?._uniqueid);

		if (!boundingBoxExist && imageUniqueid) {
			addNewBoundingBoxToMyImageQueryResult(selectedBoundingBox);
		} else {
			setNewBoundingBox(null);
		}

		setLabel(selectedBoundingBox?._label ?? '');
		setCoordinates(selectedBoundingBox?._coordinates ?? defaultCoordinatesValues);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedBoundingBox, imageUniqueid]);

	React.useEffect(() => {
		setBoundingBoxProperties([...getBoundingBoxProperties(selectedBoundingBox, newBoundingBox)]);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [label, coordinates, errors, newBoundingBox]);

	return {
		positionX,
		setPositionX,
		positionY,
		setPositionY,
		width,
		setWidth,
		height,
		setHeight,
		label,
		boundingBoxProperties,
		isNewBoundingBox: !!newBoundingBox,
		deleteBoundingBox,
		handleOnCancel,
		onClickSaveButton,
		setLabel,
	};
};

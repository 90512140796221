import './SelectBox.scss';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';

export interface SelectBoxItem {
	value: string;
	label: string;
}

interface SelectBoxProps {
	selectedValues: string[];
	onChange: (value: unknown) => void;
	items: SelectBoxItem[];
}

const MultipleSelectBox: React.FC<SelectBoxProps> = ({ selectedValues, onChange, items }) => {
	return (
		<FormControl variant="outlined" className="platform-select-box-control">
			<Select
				value={selectedValues}
				onChange={(event: React.ChangeEvent<{ value: unknown }>): void => onChange(event.target.value)}
				className="platform-select-box"
				multiple
			>
				{
					items.map(item => (
						<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
					))
				}
			</Select>
		</FormControl>
	);
};

export default MultipleSelectBox;

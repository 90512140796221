import { BoundingBoxEntities } from '../../../interfaces/Image';

export interface InputFieldProperties {
	label: string;
	name: string;
	value: string | number | boolean;
	type: string;
	error?: boolean;
	errorMessage?: string;
	isDisabled?: boolean;
	hide?: boolean;
}

export const getBoundingBoxProperties = (selectedBoundingBox: BoundingBoxEntities | null, newBoundingBox: BoundingBoxEntities | null ): InputFieldProperties[] => [{
	label: 'Area',
	name: 'area',
	value: parseFloat((selectedBoundingBox?.area ?? 0).toFixed(4)),
	type: 'number',
	isDisabled: true,
	hide: !!newBoundingBox,
}, {
	label: 'Area %',
	name: 'areaPerc',
	value: parseFloat((selectedBoundingBox?.area_perc ?? 0).toFixed(4)),
	type: 'number',
	isDisabled: true,
	hide: !!newBoundingBox,
}, {
	label: 'Bounding box id',
	name: 'bbox_id',
	value: selectedBoundingBox?.bbox_id ?? 0,
	type: 'number',
	isDisabled: true,
	hide: !!newBoundingBox,
}, {
	label: 'Category id',
	name: 'category_id',
	value: selectedBoundingBox?.category_id ?? 0,
	type: 'number',
	isDisabled: true,
	hide: !!newBoundingBox,
}, {
	label: 'Image id',
	name: 'image_id',
	value: selectedBoundingBox?.image_id ?? 0,
	type: 'number',
	isDisabled: true,
	hide: !!newBoundingBox,
}, {
	label: 'Crowd',
	name: 'is_crowd',
	value: !!selectedBoundingBox?.is_crowd,
	type: 'boolean',
	isDisabled: true,
	hide: !!newBoundingBox,
}];

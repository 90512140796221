import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';
import style from './CheckBox.module.scss';

interface CheckBoxProps {
	isChecked: boolean;
	label?: string;
	onChange: (value: boolean) => void;
}

const CheckBox: React.FC<CheckBoxProps> = ({ isChecked, onChange, label }) => {
	return (
		<FormControlLabel
			control={
				<Checkbox
					checked={isChecked}
					onChange={(event: React.ChangeEvent<HTMLInputElement>): void => onChange(event.target.checked)}
					name={label}
					color="primary"
				/>
			}
			label={label}
			className={style.platformCheckBoxControlLabel}
		/>
	);
};

export default CheckBox;

import { ModalContext, ModalUpdateContext } from '../../contexts/ModalContext';
import { PartialRoleAccessLevelModel, RolePermissionsModel } from '../../interfaces/AccessControl';
import AccessControlService from '../../services/AccessControlService';
import { AccessControlUpdateContext } from '../../contexts/AccessControlContext';
import React from 'react';

interface UseAccessControlEditRoleStore {
	setIsEditRoleModalOpen: (open: boolean) => void;
	isEditRoleModalOpen: boolean;
	rolename: string;
	saveRole: () => void;
	removeRole: () => void;
	error: string;
	setError: (value: string) => void;
	options: PartialRoleAccessLevelModel;
	updateOptions: (option: keyof PartialRoleAccessLevelModel, permission: keyof RolePermissionsModel, value: boolean) => void;
}

const basePermissions: RolePermissionsModel = {
	create: false,
	read: false,
	update: false,
	delete: false,
};
const baseOptions: PartialRoleAccessLevelModel = {
	'access_control': { ...basePermissions },
	indexes: { ...basePermissions },
	objects: { ...basePermissions },
};

const useAccessControlEditRole = (): UseAccessControlEditRoleStore => {
	const { isEditRoleModalOpen, roleToEdit } = React.useContext(ModalContext);
	const { setIsEditRoleModalOpen, setRoleToEdit } = React.useContext(ModalUpdateContext);
	const { fetchRoles } = React.useContext(AccessControlUpdateContext);
	const [ options, setOptions ] = React.useState<PartialRoleAccessLevelModel>(baseOptions);
	const [ error, setError ] = React.useState('');

	React.useEffect(() => {
		if (roleToEdit) {
			setOptions({
				'access_control': { ...(roleToEdit.access_control ? roleToEdit.access_control : basePermissions) },
				indexes: { ...(roleToEdit.indexes ? roleToEdit.indexes : basePermissions) },
				objects: { ...(roleToEdit.objects ? roleToEdit.objects : basePermissions) },
			});
		}
	}, [roleToEdit]);

	const cleanModalState = (): void => {
		setOptions({
			'access_control': { ...basePermissions },
			indexes: { ...basePermissions },
			objects: { ...basePermissions },
		});
		setError('');
	};

	const openModal = (open: boolean): void => {
		if (!open) {
			setRoleToEdit(undefined);
			cleanModalState();
		}

		setIsEditRoleModalOpen(open);
	};

	const updateOptions = (option: keyof PartialRoleAccessLevelModel, permission: keyof RolePermissionsModel, value: boolean): void => {
		const newOptions = { ...options };

		newOptions[option][permission] = value;

		setOptions(newOptions);
	};

	const saveRole = async (): Promise<void> => {
		try {
			const response = await AccessControlService.updateRoleInDataBase({
				name: roleToEdit?.name || '',
				...options,
			});

			if (response.status === -1) {
				setError(response.info);
			} else {
				setError('');
				await fetchRoles();
				openModal(false);
			}
		} catch {
			setError('An error occurred while editing role');
		}
	};

	const removeRole = async (): Promise<void> => {
		try {
			const response = await AccessControlService.removeRoleInDataBase({
				name: roleToEdit?.name || '',
			});

			if (response.status === -1) {
				setError(response.info);
			} else {
				setError('');
				await fetchRoles();
				openModal(false);
			}
		} catch {
			setError('An error occurred while removing role');
		}
	};

	return {
		setIsEditRoleModalOpen: openModal,
		isEditRoleModalOpen,
		rolename: roleToEdit?.name || '',
		saveRole,
		error,
		setError,
		options,
		updateOptions,
		removeRole,
	};
};

export default useAccessControlEditRole;

import React, { EventHandler, SyntheticEvent } from 'react';
import { FindBoundingBoxResponse } from '../../interfaces/Image';
import style from './Image.module.scss';
export interface ImageProperties {
	[key: string]: string | number | boolean;
}

export interface ImageData {
	boundingBox?: FindBoundingBoxResponse;
	src: string;
	title?: string;
	uniqueId: number;
	properties: ImageProperties;
	alt?: string;
	hasSimilarImages?: boolean;
}

interface ImageProps {
	image: ImageData;
	onImageClick: (image: ImageData) => void;
}

const Image: React.FC<ImageProps> = ({ image, onImageClick }) => {
	const handleClick: EventHandler<SyntheticEvent> = e => {
		e.preventDefault();
		onImageClick(image);
	};

	const _formatProperties: (properties: ImageProperties) => string = ({ width, height }) => `${width}x${height} px`;

	return (
		<>
			<div className={style.image}>
				<div className={style.container}>
					<img
						className={style.contentElement}
						key={image.alt || image.src}
						src={`data:image/png;base64, ${image.src}`}
						alt={image.alt}
						onClick={handleClick}
					/>
				</div>
				<div className={style.description}>
					{
						image.properties.height && image.properties.height !== 'Missing property' && image.properties.width && image.properties.width !== 'Missing property' && <p className={style.propertiesDescription}>{_formatProperties(image.properties)}</p>
					}
				</div>
			</div>
		</>
	);
};

export default Image;

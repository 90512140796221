import { addNewBoundingBox, deleteBoundingBox, updateBoundingBox } from '../api/BoundingBoxRepository';
import { Coordinates, FindImageRequest } from '../interfaces/Image';
import { Constraints } from './../interfaces/Image';
import { CustomResponse } from '../interfaces/Query';

interface AddBoundingBox {
	image_ref: number;
	label: string;
	rectangle: Coordinates;
}

export interface AddBoundingBoxRequest {
	FindImage?: FindImageRequest;
	AddBoundingBox?: AddBoundingBox;
	FindBoundingBox?: {
		'with_label': string;
		constraints: Constraints;
		coordinates: boolean;
		group_by_source: boolean;
		results: {
			all_properties?: boolean;
		};
	};
}

interface UpdateBoundingBox {
	constraints: Constraints;
	label: string;
	rectangle: Coordinates;
}

export interface UpdateBoundingBoxRequest {
	UpdateBoundingBox: UpdateBoundingBox;
}

interface DeleteBoundingBox {
	constraints: Constraints;
}

export interface DeleteBoundingBoxRequest {
	DeleteBoundingBox: DeleteBoundingBox;
}

const addBoundingBox = async (query: AddBoundingBoxRequest): Promise<[any, any, { FindBoundingBox: any }] | undefined> => {
	const request = [{
		FindImage: query.FindImage,
	}, {
		AddBoundingBox: query.AddBoundingBox,
	}, {
		FindBoundingBox: query.FindBoundingBox,
	}];
	const response = await addNewBoundingBox(request);
	const responseData = response.data as CustomResponse;

	return JSON.parse(JSON.stringify(responseData.json)) as [any, any, { FindBoundingBox: any }] | undefined;
};

const updateBoundingBoxProperties = async (query: UpdateBoundingBoxRequest[]): Promise<void> => {
	await updateBoundingBox(query);
};

const deleteBoundingBoxProperties = async (query: DeleteBoundingBoxRequest[]): Promise<void> => {
	await deleteBoundingBox(query);
};

export default {
	updateBoundingBoxProperties,
	deleteBoundingBoxProperties,
	addBoundingBox,
};

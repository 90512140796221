import './AccessControlEditUser.scss';
import { Snackbar, Typography } from '@material-ui/core';
import AccessControlService from '../services/AccessControlService';
import { Alert } from '@material-ui/lab';
import { AuthenticationContext } from '../contexts/AuthenticationContext';
import { Button } from '../components/Button';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Modal } from '../components/Modal';
import { MultipleSelectBox } from '../components/SelectBox';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import React from 'react';
import { TextField } from '../components/TextField';
import tokenIcon from '../assets/token-icon.png';
import useAccessControlEditUser from './Hooks/useAccessControlEditUser';
import visibility from '../assets/visibility.png';

const AccessControlEditUser: React.FC = () => {
	const {
		isEditUserModalOpen,
		setIsEditUserModalOpen,
		username,
		email,
		setEmail,
		password,
		setPassword,
		roles,
		selectedRoles,
		setSelectedRoles,
		error,
		setError,
		editUser,
		removeUser,
	} = useAccessControlEditUser();
	const [showPassword, setShowPassword] = React.useState<boolean>(false);
	const { myUser } = React.useContext(AuthenticationContext);

	const emailHandler = (value: string): void => {
		setEmail(value);
	};

	const passwordHandler = (value: string): void => {
		setPassword(value);
	};

	const toggleShowPasswordHandler = (): void => {
		setShowPassword(!showPassword);
	};

	const handleErrorClose = (event?: React.SyntheticEvent, reason?: string): void => {
		if (reason === 'clickaway') {
			return;
		}

		setError('');
	};

	return (
		<Modal
			open={isEditUserModalOpen}
			setOpen={setIsEditUserModalOpen}
			title={'Edit user'}
			successButton={'save changes'}
			successButtonOnClick={editUser}
			successButtonDisabled={!selectedRoles.length}
			handleThirdOption={username !== 'admin' && myUser?.permissions.access_control.delete ? removeUser : undefined}
			dialogTitle={'Remove user'}
			dialogContent={'Are you sure you want to remove this user?'}
		>
			<div className='edit-user-content'>
				<div className='edit-user-input'>
					<TextField value={username} onChange={console.log} label={'Username'} iconInput={<PersonOutlineIcon />} disabled={true} />
				</div>
				<div className='edit-mail-input'>
					<TextField value={email} onChange={emailHandler} label={'Email'} iconInput={<MailOutlineIcon />} />
				</div>
				<div className='edit-user-input'>
					<TextField value={password} autocomplete="current-password" onChange={passwordHandler} label={'Password'} type={showPassword ? undefined : 'password'} iconInput={<img src={visibility} alt='Visibility' onClick={toggleShowPasswordHandler} />} />
				</div>
				<Button title="Generate password" onClick={(): void => passwordHandler(AccessControlService.generateUserPassword())} startIcon={<img src={tokenIcon} alt='Token' />} isControlAccess={true} />
				<div className='edit-user-input'>
					<Typography className="edit-user_select-title">Role</Typography>
					<MultipleSelectBox selectedValues={selectedRoles} onChange={(value: unknown): void => setSelectedRoles(value as string[])} items={roles.map(role => ({ value: role, label: role }))} />
				</div>
			</div>
			<Snackbar open={!!error} autoHideDuration={6000} onClose={handleErrorClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<Alert onClose={handleErrorClose} severity="error">
					{error}
				</Alert>
			</Snackbar>
		</Modal >
	);
};

export default AccessControlEditUser;

import axios, { AxiosResponse } from 'axios';
import { CreateRoleModel, CreateRoleRequest, CreateUserModel, CreateUserRequest, GenerateTokenRequest, GetRolesRequest, GetUserDetailsRequest, GetUsersRequest, RemoveRoleModel, RemoveRoleRequest, RemoveUserModel, RemoveUserRequest, UpdateRoleModel, UpdateRoleRequest, UpdateUserModel, UpdateUserRequest } from '../interfaces/AccessControl';
import ApiAddressService from '../services/ApiAddressService';
import { convertToFormData } from '../helpers/request';
import { getUsernameKey } from '../services/LocalStorageService';

export const getDataBaseUsers = (): Promise<AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		const query: GetUsersRequest[] = [
			{
				GetUsers: {
					roles: true,
					emails: true,
				},
			},
		];

		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const getMyInfo = (): Promise<AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		const query: GetUserDetailsRequest[] = [
			{
				GetUserDetails: {
					username: getUsernameKey() || '',
				},
			},
		];

		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const addDataBaseUser = (userData: CreateUserModel): Promise<AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		const query: CreateUserRequest[] = [
			{
				CreateUser: {
					...userData,
				},
			},
		];

		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const updateDataBaseUser = (userData: UpdateUserModel): Promise<AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		const query: UpdateUserRequest[] = [
			{
				UpdateUser: {
					...userData,
				},
			},
		];

		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const removeDataBaseUser = (userData: RemoveUserModel): Promise<AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		const query: RemoveUserRequest[] = [
			{
				RemoveUser: {
					...userData,
				},
			},
		];

		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const getDataBaseRoles = (): Promise<AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		const query: GetRolesRequest[] = [
			{
				GetRoles: {
					objects: true,
					indexes: true,
					access_control: true,
				},
			},
		];

		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const addDataBaseRole = (userData: CreateRoleModel): Promise<AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		const query: CreateRoleRequest[] = [
			{
				CreateRole: {
					name: userData.name,
					objects: userData.objects,
					indexes: userData.indexes,
					access_control: userData.access_control,
				},
			},
		];

		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const updateDataBaseRole = (userData: UpdateRoleModel): Promise<AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		const query: UpdateRoleRequest[] = [
			{
				UpdateRole: {
					name: userData.name,
					objects: userData.objects,
					indexes: userData.indexes,
					access_control: userData.access_control,
				},
			},
		];

		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const removeDataBaseRole = (userData: RemoveRoleModel): Promise<AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		const query: RemoveRoleRequest[] = [
			{
				RemoveRole: {
					...userData,
				},
			},
		];

		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const generateToken = (): Promise<AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		const query: GenerateTokenRequest[] = [
			{
				GenerateToken: {},
			},
		];

		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

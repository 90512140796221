import {
	EntityClassProperties,
	EntityInfoResponse
} from '../../../interfaces/DataBaseStatus';
import { FormatOptions } from '../ResultsBoxes/ResultsBoxes';
import { PropertyBoxType } from '../PropertyBox';
import React from 'react';
import { StatusContext } from '../../../contexts/StatusContext';
import StatusService from '../../../services/StatusService';

export interface FilterProperty {
	title: string;
	type: PropertyBoxType;
	data: unknown;
}

export interface FilterResults {
	as_format: FormatOptions;
	limit: string;
	unique: boolean;
	boundingBox: boolean;
	sortBy: string;
	list: string[];
	max_size_mb: number;
}

interface UseVideoSearchFilterStore {
	propertyFilters: FilterProperty[];
	resultFilters: FilterResults;
	removePropertyFilter: (index: number) => void;
	updatePropertyFilter: (
		index: number,
		propertyFilter: FilterProperty
	) => void;
	setResultFilters: (resultFilters: FilterResults) => void;
	videoEntityClassViewModel: EntityClassProperties[];
	openSelectPropertyFilterDialog: boolean;
	onCloseSelectPropertyFilterDialog: () => void;
	onOpenSelectPropertyFilterDialog: () => void;
	onSelectPropertyFilterDialog: (label: string) => void;
	resetFilters: () => void;
}

const filterResultsByDefault: FilterResults = {
	'as_format': 'DEFAULT',
	limit: '10',
	unique: false,
	boundingBox: true,
	sortBy: '',
	list: [],
	'max_size_mb': 10,
};

const useVideoSearchFilter = (
	setFilters: (
		resultFilters: FilterResults | undefined,
		properties: FilterProperty[]
	) => void
): UseVideoSearchFilterStore => {
	const { myStatus } = React.useContext(StatusContext);
	const [propertyFilters, setPropertyFilters] = React.useState<
		FilterProperty[]
	>([]);
	const [resultFilters, setResultFilters] = React.useState<FilterResults>(
		filterResultsByDefault
	);
	const [videoEntityClassViewModel, setVideoEntityClassViewModel] =
		React.useState<EntityClassProperties[]>([]);
	const [openSelectPropertyFilterDialog, setOpenSelectPropertyFilterDialog] =
		React.useState<boolean>(false);

	React.useEffect((): void => {
		if (myStatus && myStatus.length) {
			const getSchema = myStatus[0].GetSchema as EntityInfoResponse;

			if (getSchema && getSchema.entities && getSchema.entities.classes) {
				const rows = Object.keys(getSchema.entities.classes).map(
					(entityClassName) => {
						return {
							name: entityClassName,
							properties: StatusService.getPropertiesByClass(
								getSchema.entities.classes[entityClassName]
							),
							totalAmount:
								StatusService.getTotalNumberOfEntitiesByClass(
									getSchema.entities.classes[entityClassName]
								),
						};
					}
				);

				const videoClass = rows.filter((row) => row.name === '_Video');

				setVideoEntityClassViewModel(
					videoClass.length ? videoClass[0].properties : []
				);
			}
		}
	}, [myStatus]);

	React.useEffect(() => {
		setFilters(resultFilters, propertyFilters);
	}, [setFilters, resultFilters, propertyFilters]);

	const resetFilters = (): void => {
		setPropertyFilters([]);
		setResultFilters(filterResultsByDefault);
	};

	const addPropertyFilter = (title: string, type: PropertyBoxType): void => {
		let data: unknown;
		const defaultDate = new Date();

		switch (type) {
			case 'BOOLEAN':
				data = {
					value: true,
				};
				break;
			case 'DATE':
				data = {
					operation: 'EQUAL',
					value: `${new Date(defaultDate).getFullYear()}-${(
						'0' +
						(new Date(defaultDate).getMonth() + 1)
					).slice(-2)}-${(
						'0' + new Date(defaultDate).getDate()
					).slice(-2)}`,
					isAdvanced: false,
				};
				break;
			case 'DATETIME':
				data = {
					operation: 'EQUAL',
					value: `${new Date(defaultDate).getFullYear()}-${(
						'0' +
						(new Date(defaultDate).getMonth() + 1)
					).slice(-2)}-${(
						'0' + new Date(defaultDate).getDate()
					).slice(-2)}`,
					isAdvanced: false,
				};
				break;
			case 'NUMBER-FLOAT':
				data = {
					operation: 'EQUAL',
					value: '',
				};
				break;
			case 'NUMBER-INTEGER':
				data = {
					operation: 'EQUAL',
					value: '',
				};
				break;
			case 'NUMBER':
				data = {
					operation: 'EQUAL',
					value: '',
				};
				break;
			case 'TEXT':
			default:
				data = {
					operation: 'EQUAL',
					value: '',
				};
				break;
		}

		const propertyFilter: FilterProperty = {
			title,
			type,
			data,
		};

		setPropertyFilters([...propertyFilters, propertyFilter]);
	};

	const removePropertyFilter = (index: number): void => {
		const propertyFiltersCopy = [...propertyFilters];

		propertyFiltersCopy.splice(index, 1);

		setPropertyFilters(propertyFiltersCopy);
	};

	const updatePropertyFilter = (
		index: number,
		propertyFilter: FilterProperty
	): void => {
		const propertyFiltersCopy = [...propertyFilters];

		propertyFiltersCopy.splice(index, 1, propertyFilter);

		setPropertyFilters(propertyFiltersCopy);
	};

	const onCloseSelectPropertyFilterDialog = (): void => {
		setOpenSelectPropertyFilterDialog(false);
	};

	const onOpenSelectPropertyFilterDialog = (): void => {
		setOpenSelectPropertyFilterDialog(true);
	};

	const onSelectPropertyFilterDialog = (label: string): void => {
		addPropertyFilter(
			label,
			StatusService.getPropertyBoxType(
				videoEntityClassViewModel.find(
					(property) => property.name === label
				)?.type || ''
			)
		);
		setOpenSelectPropertyFilterDialog(false);
	};

	return {
		propertyFilters,
		resultFilters,
		removePropertyFilter,
		updatePropertyFilter,
		setResultFilters,
		videoEntityClassViewModel,
		openSelectPropertyFilterDialog,
		onCloseSelectPropertyFilterDialog,
		onOpenSelectPropertyFilterDialog,
		onSelectPropertyFilterDialog,
		resetFilters,
	};
};

export default useVideoSearchFilter;

import { BoundingBoxEntities } from '../../interfaces/Image';
import { ImageData } from './Image';
import React from 'react';
import style from './ImageBoundingBox.module.scss';
import { Tooltip } from '@material-ui/core';

interface ImageBoundingBoxProps {
	image: ImageData;
	showBoundingBox?: boolean;
	showBoundingBoxLabels?: boolean;
	imageNaturalSize: HTMLImageElement | null;
	setImageNaturalSize: (naturalSize: HTMLImageElement) => void;
	setSelectedBoundingBox: (value: BoundingBoxEntities) => void;
}

const COLORS = [
	'#39ff14',
	'#13F4EF',
	'#1A9334',
	'#FAFF00',
	'#FF005C',
	'#FF00FF',
	'#FF9D97',
	'#FF6600',
];

const ImageBoundingBox: React.FC<ImageBoundingBoxProps> = ({ image, showBoundingBox = true, showBoundingBoxLabels = true, imageNaturalSize = null, setImageNaturalSize, setSelectedBoundingBox }) => {
	const [boundingBoxesComponent, setBoundingBoxesComponent] = React.useState<JSX.Element[]>();
	const aspectRatio = (imageNaturalSize?.naturalWidth ?? 1) / (imageNaturalSize?.naturalHeight ?? 1);
	const labelsColor: Map<string, string> = new Map<string, string>();

	const getRandomColor = (): string => '#' + Math.floor(Math.random() * 16777215).toString(16);

	const getBoundingBoxColor = (label: string): string | undefined => {
		if (!labelsColor.get(label)) {
			labelsColor.set(label, COLORS[labelsColor.size] || getRandomColor());
		}

		return labelsColor.get(label);
	};

	const renderBoundingBoxes = (naturalHeight: number = 1, naturalWidth: number = 1): JSX.Element[] => {
		const entities = image.boundingBox?.entities[0] || [];
		const orderedEntities = entities.sort((entityA, entityB) => entityA._coordinates.width * entityA._coordinates.height < entityB._coordinates.width * entityB._coordinates.height ? 1 : -1);

		return orderedEntities.map(entity => {
			const coord = entity._coordinates;
			const label = entity._label;
			const boundingBoxColor = getBoundingBoxColor(label);

			return (
				<Tooltip key={image.alt || image.src} title={label}>
					<div
						onDoubleClick={(): void => setSelectedBoundingBox(entity)}
						style={{
							position: 'absolute',
							top: `${(coord.y / (naturalHeight)) * 100}%`,
							left: `${(coord.x / (naturalWidth)) * 100}%`,
							width: `${(coord.width / (naturalWidth)) * 100}%`,
							height: `${(coord.height / (naturalHeight)) * 100}%`,
							border: '2px solid ' + boundingBoxColor,
							boxSizing: 'border-box',
						}}
					>
						{ showBoundingBoxLabels && (
							<span style={{ color: boundingBoxColor, fontSize: 10, position: 'absolute', fontWeight: 'bold', padding: 2, whiteSpace: 'nowrap' }}>
								{label}
							</span>
						)}
					</div>
				</Tooltip>
			);
		});
	};

	React.useEffect(() => {
		setBoundingBoxesComponent(renderBoundingBoxes(imageNaturalSize?.naturalHeight, imageNaturalSize?.naturalWidth));
		// eslint-disable-next-line
	}, [image, image.boundingBox, showBoundingBoxLabels, imageNaturalSize]);

	return (
		<div className={style.container} style={{ aspectRatio: aspectRatio.toString() }}>
			<img
				id={'image'}
				ref={setImageNaturalSize}
				className={style.content}
				key={image.alt || image.src}
				src={`data:image/png;base64, ${image.src}`}
				alt={image.alt}
			/>
			{ showBoundingBox &&
				boundingBoxesComponent
			}
		</div>
	);
};

export default ImageBoundingBox;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Constraints,	CropOperation, FlipOperation, MaxSizeMb, ResizeOperation, RotateOperation, ThresholdOperation, VideoQuery, VideoResult } from '../interfaces/Video';
import { CustomResponse, VideoResponse } from '../interfaces/Query';
import { fetchVideo, fetchVideoMetadata, fetchVideos } from '../api/VideoQueryRepository';
import { FilterProperty, FilterResults } from '../pages/ImageSearch/Hooks/useImageSearchFilter';
import ContentService from './ContentService';
import { OperationCropData } from '../pages/ImageSearch/OperationTypeBoxes/OperationCropBox';
import { OperationFlipData } from '../pages/ImageSearch/OperationTypeBoxes/OperationFlipBox';
import { OperationProperty } from '../pages/ImageSearch/Hooks/useImageSearchOperations';
import { OperationResizeData } from '../pages/ImageSearch/OperationTypeBoxes/OperationResizeBox';
import { OperationRotateData } from '../pages/ImageSearch/OperationTypeBoxes/OperationRotateBox';
import { OperationThresholdData } from '../pages/ImageSearch/OperationTypeBoxes/OperationThresholdBox';
import { PropertyBooleanData } from '../pages/ImageSearch/PropertyTypeBoxes/PropertyBooleanBox';
import { PropertyDateData } from '../pages/ImageSearch/PropertyTypeBoxes/PropertyDateBox';
import { PropertyNumberData } from '../pages/ImageSearch/PropertyTypeBoxes/PropertyNumberBox';
import { PropertyNumberFloatData } from '../pages/ImageSearch/PropertyTypeBoxes/PropertyNumberFloatBox';
import { PropertyNumberIntegerData } from '../pages/ImageSearch/PropertyTypeBoxes/PropertyNumberIntegerBox';
import { PropertyTextData } from '../pages/ImageSearch/ResultsBoxes/ResultsBoxes';

const getVideoMetadata = async (
	query: VideoQuery[]
): Promise<VideoResult[]> => {
	const response = await fetchVideoMetadata(query);
	const responseData = response.data as CustomResponse;

	if (JSON.parse(JSON.stringify(responseData.json)).info) {
		throw {
			name: 'InternalError',
			message: JSON.parse(JSON.stringify(responseData.json)).info,
		};
	}

	return JSON.parse(JSON.stringify(responseData.json)) as VideoResult[];
};

const getVideos = async (query: VideoQuery[]): Promise<VideoResponse> => {
	const blobsData: any = [];
	const response = await fetchVideos(query);
	const queryResponse = response.data as VideoResponse;
	const findVideoFilter = queryResponse.json.filter((item)=> {
		if (item.FindVideo) {
			queryResponse.blobs.forEach((blob, key) => {
				if(key === item.FindVideo.blobs_start ){
					blobsData.push(blob);
				}
			});

			return item.FindVideo;
		}});

	return {blobs: blobsData, json: findVideoFilter};
};

const getVideo = async (uniqueId: string): Promise<VideoResponse> => {
	const response = await fetchVideo(uniqueId);
	const queryResponse = response.data as VideoResponse;

	return {blobs: queryResponse.blobs, json: queryResponse.json};
};

const getUniqueIdArray = (response: any[]): number[] => {
	if (response[0] && response[0].FindVideo && response[0].FindVideo.group_by_source) {
		const uniqueIds:number[] = [];

		for (const key in response[0].FindVideo.entities) {
			if (response[0].FindVideo.entities[key].length > 0) {
				uniqueIds.push(...response[0].FindVideo.entities[key].map((entity: any): number => entity._uniqueid));
			}
		}

		return uniqueIds;
	}

	return response[0] && response[0].FindVideo && response[0].FindVideo.entities ? response[0].FindVideo.entities.map((entity: any): number => entity._uniqueid) : [];
};

const buildConstraints = (properties: FilterProperty[]): Constraints => {
	const constraints: Constraints = {};
	const operations = ContentService.getPropertiesOperations();

	if (properties.length) {
		properties.forEach(property => {
			if (property.type !== 'BOOLEAN') {
				const data = property.data as (PropertyDateData | PropertyTextData | PropertyNumberFloatData | PropertyNumberIntegerData | PropertyNumberData);

				if (constraints[property.title] && typeof constraints[property.title] === 'object') {
					(constraints[property.title] as (string | number | { _date: string })[]).concat([
						operations.find(operation => operation.value === data.operation)?.label || '==',
						property.type ==='NUMBER'? parseFloat(data.value) || 0 : property.type === 'NUMBER-INTEGER' ? parseInt(data.value) || 0 : (property.type === 'NUMBER-FLOAT' ? parseFloat(data.value) || 0 : (property.type === 'DATE' ? { _date: data.value } : (property.type === 'DATETIME' ? { _date: data.value } : data.value))),
					]);
				} else {
					constraints[property.title] = [
						operations.find(operation => operation.value === data.operation)?.label || '==',
						property.type ==='NUMBER'? parseFloat(data.value) || 0 : property.type === 'NUMBER-INTEGER' ? parseInt(data.value) || 0 : (property.type === 'NUMBER-FLOAT' ? parseFloat(data.value) || 0 : (property.type === 'DATE' ? { _date: data.value } : (property.type === 'DATETIME' ? { _date: data.value } : data.value))),
					];
				}
			} else {
				const data = property.data as PropertyBooleanData;

				constraints[property.title] = ['==', data.value];
			}
		});
	}

	return constraints;
};

const buildOperations = (operationProperties: OperationProperty[]): (RotateOperation | FlipOperation | ResizeOperation | ThresholdOperation | CropOperation | MaxSizeMb)[] => {
	const operations: (RotateOperation | FlipOperation | ResizeOperation | ThresholdOperation | CropOperation | MaxSizeMb | undefined)[] = operationProperties.map(operationProperty => {
		let data;

		switch (operationProperty.type) {
			case 'Resize':
				data = operationProperty.data as OperationResizeData;

				if (!data.width) {
					data.width = '1';
				}

				if (!data.height) {
					data.height = '1';
				}

				return ({ type: 'resize', width: parseInt(data.width), height: parseInt(data.height) } as ResizeOperation);
			case 'Threshold':
				data = operationProperty.data as OperationThresholdData;

				return ({ type: 'threshold', value: data.value } as ThresholdOperation);
			case 'Max-Size Limit':
				data = operationProperty.data as OperationThresholdData;

				return ({ type: 'preview', 'max_size_mb': data.value } as MaxSizeMb);
			case 'Rotate':
				data = operationProperty.data as OperationRotateData;

				if (!data.degree) {
					data.degree = '0.0';
				}

				return ({ type: 'rotate', angle: parseFloat(data.degree), resize: data.resize } as RotateOperation);
			case 'Crop':
				data = operationProperty.data as OperationCropData;

				if (!data.width) {
					data.width = '1';
				}

				if (!data.height) {
					data.height = '1';
				}

				if (!data.x) {
					data.x = '0';
				}

				if (!data.y) {
					data.y = '0';
				}

				return ({ type: 'crop', width: parseInt(data.width), height: parseInt(data.height), x: parseInt(data.x), y: parseInt(data.y) } as CropOperation);
			case 'Flip':
				data = operationProperty.data as OperationFlipData;

				return ({ type: 'flip', code: data.value === 'vertical' ? 0 : (data.value === 'horizontal' ? 1 : -1) } as FlipOperation);
		}
	});

	const operationsWithoutUndefined = operations.filter(
		(operation) => operation !== undefined
	) as (
		| RotateOperation
		| FlipOperation
		| ResizeOperation
		| ThresholdOperation
		| CropOperation
		| MaxSizeMb
	)[];

	return operationsWithoutUndefined;
};

const buildVideoQuery = (
	properties: FilterProperty[],
	results: FilterResults | undefined,
	operationProperties: OperationProperty[]
): VideoQuery => {
	const constraints: Constraints = buildConstraints(properties);
	const operations: (
		| RotateOperation
		| FlipOperation
		| ResizeOperation
		| ThresholdOperation
		| CropOperation
		| MaxSizeMb
	)[] = buildOperations(operationProperties);

	const videoQuery: VideoQuery = {
		FindVideo: {},
	};

	if (Object.keys(constraints).length) {
		videoQuery.FindVideo.constraints = constraints;
	}

	if (operations.length) {
		videoQuery.FindVideo.operations = operations;
	}

	if (results) {
		videoQuery.FindVideo.unique = results.unique;

		if (results.list.length) {
			videoQuery.FindVideo.results = {
				...videoQuery.FindVideo.results,
				list: results.list,
			};
		}

		if (results.limit) {
			videoQuery.FindVideo.results = {
				...videoQuery.FindVideo.results,
				limit: parseInt(results.limit),
			};
		}

		if (results.sortBy) {
			videoQuery.FindVideo.results = {
				...videoQuery.FindVideo.results,
				sort: results.sortBy,
			};
		}

		if (results.as_format && results.as_format !== 'DEFAULT') {
			videoQuery.FindVideo['as_format'] =
				results.as_format === 'JPG' ? 'jpg' : 'png';
		}
	}

	return videoQuery;
};

const getProcessedVideoQuery = (videoQuery: VideoQuery): VideoQuery => {
	const videoQueryCopy = JSON.parse(JSON.stringify(videoQuery)) as VideoQuery;

	if (!videoQueryCopy.FindVideo.results) {
		videoQueryCopy.FindVideo.results = {};
	}

	videoQueryCopy.FindVideo.blobs = videoQuery.FindVideo.blobs ?? false;
	videoQueryCopy.FindVideo.uniqueids = true;

	return videoQueryCopy;
};

const getIndividualVideoQueriesForBlob = (
	videoQuery: VideoQuery,
	uniqueIds: number[]
): VideoQuery[] => {
	const videoQueryCopy = JSON.parse(JSON.stringify(videoQuery)) as VideoQuery;
	const videoQueriesForBlob: VideoQuery[] = uniqueIds.map((uniqueId) => {
		const videoQueryForBlob: VideoQuery = {
			FindVideo: {
				blobs: true,
				uniqueids: true,
				constraints: {
					_uniqueid: ['==', uniqueId],
				},
			},
		};

		if (videoQueryCopy.FindVideo.operations) {
			videoQueryForBlob.FindVideo['operations'] =
				videoQueryCopy.FindVideo.operations;
		}

		if (videoQueryCopy.FindVideo.as_format) {
			videoQueryForBlob.FindVideo['as_format'] =
				videoQueryCopy.FindVideo.as_format;
		}

		if (videoQueryCopy.FindVideo.results) {
			videoQueryForBlob.FindVideo['results'] = {
				list: videoQueryCopy.FindVideo.results.list,
			};

			if (videoQueryCopy.FindVideo.results.all_properties) {
				videoQueryForBlob.FindVideo['results'] = {
					...videoQueryForBlob.FindVideo['results'],
					'all_properties': videoQueryCopy.FindVideo.results.all_properties,
				};
			}
		}

		return videoQueryForBlob;
	});

	return videoQueriesForBlob;
};

export default {
	getVideoMetadata,
	getVideo,
	getVideos,
	getUniqueIdArray,
	buildVideoQuery,
	getProcessedVideoQuery,
	getIndividualVideoQueriesForBlob,
};

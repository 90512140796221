import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@material-ui/core';
import AlertDialog from '../AlertDialog/AlertDialog';
import { DeleteOutline } from '@material-ui/icons';
import React from 'react';
import style from './Modal.module.scss';

interface ModalProps {
	open: boolean;
	setOpen: (open: boolean) => void;
	title: string;
	successButton: string;
	successButtonOnClick: () => void;
	successButtonDisabled?: boolean;
	handleThirdOption?: () => void;
	dialogTitle?: string;
	dialogContent?: string;
}

const Modal: React.FC<ModalProps> = ({ title, children, successButton, successButtonOnClick, open, setOpen, successButtonDisabled, handleThirdOption, dialogTitle, dialogContent }) => {
	const [openDialog, setOpenDialog] = React.useState(false);

	const handleOpenDialog = (): void => {
		setOpenDialog(true);
	};

	const handleCloseDialog = (): void => {
		setOpenDialog(false);
	};

	const handleClose = (): void => {
		setOpen(false);
	};

	const handleSuccessDialog = (): void => {
		handleCloseDialog();
		handleThirdOption && handleThirdOption();
	};

	return (
		<div>
			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<div className={style.controlAccessDialog}>
					<DialogTitle className={style.controlAccessTitle} id="form-dialog-title">{title}</DialogTitle>
					<DialogContent>
						{children}
					</DialogContent>
					<Divider orientation={'horizontal'} />
					<DialogActions>
						<div className={style.buttonContainer}>
							{
								handleThirdOption && (
									<IconButton size="small" aria-label="delete" className={style.editUserDeleteButton} onClick={handleOpenDialog}>
										<DeleteOutline className={style.deleteIcon} />
									</IconButton>
								)
							}
							<div className={style.confirmButtons}>
								<Button onClick={handleClose} color="primary">
									Cancel
								</Button>
								<Button onClick={successButtonOnClick} color="primary" disabled={successButtonDisabled}>
									{successButton}
								</Button>
							</div>
						</div>
					</DialogActions>
				</div>
			</Dialog>
			<AlertDialog
				open={openDialog}
				onClose={handleCloseDialog}
				successButtonOnClick={handleSuccessDialog}
				primaryActionLabel={'Yes, I am sure'}
				cancelActionLabel={'Cancel'}
				dialogTitle={dialogTitle}
				dialogContent={dialogContent}
			/>
		</div>
	);
};

export default Modal;

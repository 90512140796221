import { cleanUserLoginData, getAuthToken, getRefreshToken, setAuthToken, setRefreshToken } from './LocalStorageService';
import ApiAddressService from './ApiAddressService';
import axios from 'axios';
import { convertToFormData } from '../helpers/request';
import swal from 'sweetalert';

axios.interceptors.request.use(async config => {
	const authToken = getAuthToken();

	if (authToken) {
		config.headers.Authorization = 'Bearer ' + authToken;
	}

	return config;
}, error => {
	return Promise.reject(error);
});

axios.interceptors.response.use(async response => {
	if (response.status === 200 && response.data.json && response.data.json?.info === 'Not Authenticated!') {
		const apiUrl = ApiAddressService.getApiUrl();

		if (apiUrl) {
			cleanUserLoginData();
			await swal('Oops!', response.data.json?.info || 'Something went wrong, please try again later', 'error');
			window.location.href = '/login';
		}
	}

	return response;
}, async (error) => {
	const originalRequest = error.config;

	if (error.response && error.response.status === 401 && !originalRequest._retry) {
		originalRequest._retry = true;
		const refreshToken = getRefreshToken();

		if (refreshToken) {
			const apiUrl = ApiAddressService.getApiUrl();

			if (apiUrl) {
				try {
					const query = [{
						RefreshToken: {
							refresh_token: refreshToken,
						},
					}];

					return axios.create().post(
						`${apiUrl}/`,
						convertToFormData(query)
					).then(async resultString => {
						const result = JSON.parse(resultString.data).json[0].Authenticate;

						if (result.status === -1) {
							cleanUserLoginData();
							window.location.href = apiUrl;
						}

						setAuthToken(result.session_token);
						setRefreshToken(result.refresh_token);

						axios.defaults.headers.Authorization = 'Bearer ' + result.session_token;
						originalRequest.headers['Authorization'] = 'Bearer ' + result.session_token;

						return axios(originalRequest);
					}).catch(() => {
						cleanUserLoginData();
						window.location.href = apiUrl;
					});
				} catch {
					cleanUserLoginData();
					window.location.href = apiUrl;
				}
			}

			return Promise.reject('API url not defined');
		}
	}

	if (error.response) {
		throw ({
			name: error.response.data.name,
			message: error.response.data.message,
		});
	}

	return Promise.reject(error);
});

import Randexp from 'randexp';

const containDigits = '(?=.*[0-9])';
const digits = '0-9';
const containLowerCase = '(?=.*[a-z])';
const lowerCase = 'a-z';
const containUpperCase = '(?=.*[A-Z])';
const upperCase = 'A-Z';
const containSpecialCharacters = '(?=.*[!@#?$%^&*()_+,-=<>.[\\]{}])';
const specialCharacters = '!@#?$%^&*()_+,-=<>.[\\]{}';

export const passwordGenerator = (
	minLength: number = 10,
	maxLength: number = 20,
	shouldContainDigits: boolean = true,
	shouldContainLowerCase: boolean = true,
	shouldContainUpperCase: boolean = true,
	shouldContainSpecialCharacters: boolean = true
): string => {
	let regex = '';
	let final = '';

	if (shouldContainDigits) {
		regex += containDigits;
		final += digits;
	}

	if (shouldContainLowerCase) {
		regex += containLowerCase;
		final += lowerCase;
	}

	if (shouldContainUpperCase) {
		regex += containUpperCase;
		final += upperCase;
	}

	if (shouldContainSpecialCharacters) {
		regex += containSpecialCharacters;
		final += specialCharacters;
	}

	if (!regex || !final) {
		throw ('Error while generating password');
	}

	const values = `${regex}[${final}]{${minLength},${maxLength}}`;
	const regexExp = new RegExp(values);
	const randexp = new Randexp(regexExp);
	const checkRegExp = new RegExp(`^${values}`);

	let password: string;

	do {
		password = randexp.gen();
	} while (!password.match(checkRegExp));

	return password;
};

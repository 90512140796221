import { OperationBoxType } from '../OperationBox';
import React from 'react';

export interface OperationProperty {
	type: OperationBoxType;
	data: unknown;
}

interface UseImageSearchOperationsStore {
	operations: OperationProperty[];
	removeOperation: (index: number) => void;
	updateOperation: (index: number, operation: OperationProperty) => void;
	openSelectOperationDialog: boolean;
	onCloseSelectOperationDialog: () => void;
	onOpenSelectOperationDialog: () => void;
	onSelectOperationDialog: (type: OperationBoxType) => void;
	resetOperations: () => void;
}

const useImageSearchOperations = (setOperationsSearch: (operations: OperationProperty[]) => void): UseImageSearchOperationsStore => {
	const [operations, setOperations] = React.useState<OperationProperty[]>([]);
	const [openSelectOperationDialog, setOpenSelectOperationDialog] = React.useState<boolean>(false);

	React.useEffect(() => {
		setOperationsSearch(operations);
	}, [operations, setOperationsSearch]);

	const resetOperations = (): void => {
		setOperations([]);
	};

	const addOperation = (type: OperationBoxType): void => {
		let data: unknown;

		switch (type) {
			case 'Resize':
				data = {
					height: '',
					width: '',
				};
				break;
			case 'Threshold':
				data = {
					value: 127,
				};
				break;
			case 'Rotate':
				data = {
					degrees: '',
					resize: false,
				};
				break;
			case 'Crop':
				data = {
					height: '',
					width: '',
					x: '',
					y: '',
				};
				break;
			case 'Flip':
			default:
				data = {
					value: 'vertical',
				};
				break;
		}

		const operation: OperationProperty = {
			type,
			data,
		};

		setOperations([...operations, operation]);
	};

	const removeOperation = (index: number): void => {
		const operationsCopy = [...operations];

		operationsCopy.splice(index, 1);

		setOperations(operationsCopy);
	};

	const updateOperation = (index: number, operation: OperationProperty): void => {
		const operationsCopy = [...operations];

		operationsCopy.splice(index, 1, operation);

		setOperations(operationsCopy);
	};

	const onCloseSelectOperationDialog = (): void => {
		setOpenSelectOperationDialog(false);
	};

	const onOpenSelectOperationDialog = (): void => {
		setOpenSelectOperationDialog(true);
	};

	const onSelectOperationDialog = (type: OperationBoxType): void => {
		addOperation(type);
		setOpenSelectOperationDialog(false);
	};

	return {
		operations,
		removeOperation,
		updateOperation,
		openSelectOperationDialog,
		onCloseSelectOperationDialog,
		onOpenSelectOperationDialog,
		onSelectOperationDialog,
		resetOperations,
	};
};

export default useImageSearchOperations;

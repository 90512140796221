import { IconButton, Modal } from '@material-ui/core';
import { Button } from '../Button';
import CheckIcon from '@material-ui/icons/Check';
import { Close } from '@material-ui/icons';
import React from 'react';
import styles from './ModalDeleteSaveQuery.module.scss';

interface ModalProps {
	isOpenDelete: boolean;
	handleConfirmDelete: () => void;
	handleCloseModalDelete: () => void;
}
const ModalDeleteSaveQuery: React.FC<ModalProps> = ({
	isOpenDelete,
	handleConfirmDelete,
	handleCloseModalDelete,
}) => {
	return (
		<Modal open={isOpenDelete}>
			<div className={styles.modalBody}>
				<div className={styles.headModal}>
					<div>
						<IconButton onClick={handleCloseModalDelete}>
							<Close />
						</IconButton>
					</div>
				</div>
				<div className={styles.textContainer}>
					<p>
						You are about to delete this query from the list.
					</p>
				</div>
				<div className={styles.btnContainer}>
					<div>
						<Button
							title="Cancel"
							isSecondary={true}
							onClick={handleCloseModalDelete}
							startIcon={<Close />}
						/>
					</div>
					<div>
						<Button
							isTertiary={true}
							title="Continue"
							onClick={handleConfirmDelete}
							startIcon={<CheckIcon />}
						/>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ModalDeleteSaveQuery;

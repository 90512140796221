import { Box, Link, Paper, TableContainer } from '@material-ui/core';
import OperationBox, { OperationBoxType } from '../ImageSearch/OperationBox';
import useVideoSearchOperations, { OperationProperty } from '../ImageSearch/Hooks/useVideoSearchOperations';
import { Button } from '../../components/Button';
import React from 'react';
import { SectionTitle } from '../../components/SectionTitle';
import { SimpleDialog } from '../../components/SimpleDialog';
import style from './VideoSearchOperation.module.scss';

interface VideoSearchOperationsProps {
	setOperations: (operations: OperationProperty[]) => void;
}

const VideoSearchOperations: React.FC<VideoSearchOperationsProps> = ({ setOperations }) => {
	const {
		operations,
		openSelectOperationDialog,
		onCloseSelectOperationDialog,
		onOpenSelectOperationDialog,
		onSelectOperationDialog,
		updateOperation,
		removeOperation,
		resetOperations,
	} = useVideoSearchOperations(setOperations);

	return (
		<div className={style.videoSearchOperation}>
			<TableContainer component={Paper} className={style.container} elevation={0}>
				<Box className={style.top}>
					<SectionTitle title="Operations" />
					<div className={style.button}>
						<Button
							title="Reset"
							onClick={resetOperations}
							isSecondary={true}
						/>
					</div>
				</Box>
				<Box className={style.operations}>
					<Box className={style.boxes}>
						{
							operations.map((operation, index) => (
								<OperationBox
									key={operation.type + index}
									type={operation.type}
									data={operation.data}
									updateOperation={(data: unknown): void => updateOperation(index, { ...operation, data })}
									removeOperation={(): void => removeOperation(index)}
								/>
							))
						}
					</Box>
				</Box>
				<Link className={style.addOperation} onClick={onOpenSelectOperationDialog}>+ Add new operation</Link>
				<div style={{ flex: 1, height: 3, backgroundColor: '#f8f8fc', margin: 20 }}></div>
			</TableContainer>
			<SimpleDialog open={openSelectOperationDialog} onClose={onCloseSelectOperationDialog} onSelect={(value: string): void => onSelectOperationDialog(value as OperationBoxType)} labels={['Resize', 'Threshold', 'Rotate', 'Crop', 'Flip', 'Max-Size Limit']} />
		</div>
	);
};

export default VideoSearchOperations;

import { AuthenticateModel, AuthenticateRequest } from '../interfaces/Auth';
import axios, { AxiosResponse } from 'axios';
import ApiAddressService from '../services/ApiAddressService';
import { convertToFormData } from '../helpers/request';

export const login = (userData: AuthenticateModel): Promise<AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		const query: AuthenticateRequest[] = [
			{
				Authenticate: {
					username: userData.username,
					password: userData.password,
				},
			},
		];

		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

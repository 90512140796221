import { CustomResponse } from './../interfaces/Query';
import { getSystemStatus } from '../api/StatusRepository';
import { SystemStatus } from '../interfaces/SystemStatus';

const getSystemStatusInfo = async (): Promise<SystemStatus[]> => {
	const systemStatusResponse = await getSystemStatus();
	const systemStatus = systemStatusResponse.data as CustomResponse;

	return JSON.parse(JSON.stringify(systemStatus.json)) as SystemStatus[];
};

export default {
	getSystemStatusInfo,
};


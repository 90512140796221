import React, { ReactNode } from 'react';
import { UpdateRoleModel, UserModel } from '../interfaces/AccessControl';

export interface ModalProviderStore {
	isManageTokenModalOpen: boolean;
	isOpenUpdate: boolean;
	isNewUserModalOpen: boolean;
	isNewRoleModalOpen: boolean;
	isEditUserModalOpen: boolean;
	isEditRoleModalOpen: boolean;
	userToEdit: UserModel | undefined;
	roleToEdit: UpdateRoleModel | undefined;
}

export interface ModalUpdateProviderStore {
	setIsManageTokenModalOpen: (open: boolean) => void;
	setIsOpenUpdate: (open: boolean) => void;
	setIsNewUserModalOpen: (open: boolean) => void;
	setIsNewRoleModalOpen: (open: boolean) => void;
	setIsEditUserModalOpen: (open: boolean) => void;
	setIsEditRoleModalOpen: (open: boolean) => void;
	setUserToEdit: (user: UserModel | undefined) => void;
	setRoleToEdit: (role: UpdateRoleModel | undefined) => void;
}

export const ModalContext = React.createContext({} as ModalProviderStore);
export const ModalUpdateContext = React.createContext({} as ModalUpdateProviderStore);

const ModalProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const [isManageTokenModalOpen, setIsManageTokenModalOpen] = React.useState<boolean>(false);
	const [isOpenUpdate, setIsOpenUpdate] = React.useState<boolean>(false);
	const [isNewUserModalOpen, setIsNewUserModalOpen] = React.useState<boolean>(false);
	const [isNewRoleModalOpen, setIsNewRoleModalOpen] = React.useState<boolean>(false);
	const [isEditUserModalOpen, setIsEditUserModalOpen] = React.useState<boolean>(false);
	const [isEditRoleModalOpen, setIsEditRoleModalOpen] = React.useState<boolean>(false);
	const [userToEdit, setUserToEdit] = React.useState<UserModel | undefined>(undefined);
	const [roleToEdit, setRoleToEdit] = React.useState<UpdateRoleModel | undefined>(undefined);

	const store: ModalProviderStore = {
		isManageTokenModalOpen,
		isOpenUpdate,
		isNewUserModalOpen,
		isNewRoleModalOpen,
		isEditUserModalOpen,
		isEditRoleModalOpen,
		userToEdit,
		roleToEdit,
	};

	const updateStore: ModalUpdateProviderStore = {
		setIsManageTokenModalOpen,
		setIsOpenUpdate,
		setIsNewUserModalOpen,
		setIsNewRoleModalOpen,
		setIsEditUserModalOpen,
		setIsEditRoleModalOpen,
		setUserToEdit,
		setRoleToEdit,
	};

	return (
		<ModalContext.Provider value={store}>
			<ModalUpdateContext.Provider value={updateStore}>
				{children}
			</ModalUpdateContext.Provider>
		</ModalContext.Provider>
	);
};

export { ModalProvider };
export const ModalConsumer = ModalContext.Consumer;

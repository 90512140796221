import { ConnectionClassViewModel, ConnectionObject, EntityClassViewModel, EntityInfoResponse } from '../../../interfaces/DataBaseStatus';
import { StatusContext, StatusUpdateContext } from '../../../contexts/StatusContext';
import React from 'react';
import StatusService from '../../../services/StatusService';
import { SunburstData } from '../../../components/PieChart/PieChart';

export interface StatusStore {
	chartData: SunburstData | undefined;
	entityClassesViewModel: EntityClassViewModel[];
	fetchStatus: () => void;
	isFetching: boolean;
	connectionClassesViewModel: ConnectionClassViewModel[];
}

export const useStatus = (): StatusStore => {
	const { myStatus, isFetching } = React.useContext(StatusContext);
	const { fetchStatus } = React.useContext(StatusUpdateContext);
	const [entityClassesViewModel, setEntityClassesViewModel] = React.useState<EntityClassViewModel[]>([]);
	const [connectionClassesViewModel, setConnectionClassesViewModel] = React.useState<ConnectionClassViewModel[]>([]);
	const [chartData, setChartData] = React.useState<SunburstData | undefined>();

	React.useEffect((): void => {
		if (myStatus && myStatus.length) {
			const getSchema = myStatus[0].GetSchema as EntityInfoResponse;

			processSchemaEntities(getSchema);
			processSchemaConnections(getSchema);
		}
	}, [myStatus]);

	const processSchemaEntities = (getSchema: EntityInfoResponse) => {
		if (getSchema && getSchema.entities && getSchema.entities.classes) {
			const rows = Object.keys(getSchema.entities.classes).map(entityClassName => {
				return {
					name: entityClassName,
					properties: StatusService.getPropertiesByClass(getSchema.entities.classes[entityClassName]),
					totalAmount: StatusService.getTotalNumberOfEntitiesByClass(getSchema.entities.classes[entityClassName]),
				};
			});

			setEntityClassesViewModel(rows);
		} else {
			setEntityClassesViewModel([]);
		}
	};

	const processSchemaConnections = (getSchema: EntityInfoResponse) => {
		if (getSchema && getSchema.connections && getSchema.connections.classes) {
			const rowsConnection:any = [];

			Object.keys(getSchema.connections.classes).forEach(connectionClassName => {
				if (getSchema.connections) {
					if (typeof getSchema.connections.classes[connectionClassName] === 'object' && !Array.isArray(getSchema.connections.classes[connectionClassName])) {
						const objectConnection: ConnectionObject = getSchema.connections.classes[connectionClassName] as ConnectionObject;

						rowsConnection.push({
							name: connectionClassName,
							properties: StatusService.getPropertiesByClassConn(objectConnection),
							totalAmount: objectConnection.matched,
							src: objectConnection.src,
							dst: objectConnection.dst,
						});
					} else if (typeof getSchema.connections.classes[connectionClassName] === 'object' && Array.isArray(getSchema.connections.classes[connectionClassName])) {
						const objectsConnection: ConnectionObject[] = getSchema.connections.classes[connectionClassName] as ConnectionObject[];

						objectsConnection.forEach(object => {
							rowsConnection.push({
								name: connectionClassName,
								properties: StatusService.getPropertiesByClassConn(object),
								totalAmount: StatusService.getTotalNumberOfConnectionByClass(object),
								src: object.src,
								dst: object.dst});
						});
					} else {
						rowsConnection.push({
							name: '',
							properties: [],
							totalAmount: 0,
							src: '',
							dst: '',
						});
					}
				}
			});
			setConnectionClassesViewModel(rowsConnection);
		} else {
			setConnectionClassesViewModel([]);
		}
	};

	React.useEffect((): void => {
		const tenPercent: number = Math.floor(entityClassesViewModel.reduce((acc: number, val: EntityClassViewModel) => acc + val.totalAmount, 0) / 10);
		const mainChild: SunburstData[] = entityClassesViewModel.reduce((acc: { name: string; amount?: number; children?: { name: string; amount?: number }[] }[], val: EntityClassViewModel) => {
			const className = StatusService.formatClassNames(val.name);

			if (val.totalAmount < tenPercent) {
				const child = { name: className, amount: val.totalAmount };

				acc[0].children?.push(child);
			}
			else {
				const child = { name: className, amount: val.totalAmount };

				acc.push(child);
			}

			return acc;
		}, [{ name: 'Others', children: [] }]);

		if (mainChild[0].children?.length === 0) {
			mainChild.shift();
		} else if (mainChild[0].children?.length === 1) {
			mainChild.push(mainChild[0].children[0]);
			mainChild.shift();
		}

		setChartData({ name: 'ALL', children: mainChild });
	}, [entityClassesViewModel, connectionClassesViewModel]);

	return {
		chartData,
		entityClassesViewModel,
		fetchStatus,
		isFetching,
		connectionClassesViewModel,
	};
};

import axios, { AxiosResponse } from 'axios';
import { VideoQuery, VideoResult } from './../interfaces/Video';
import ApiAddressService from '../services/ApiAddressService';
import { convertToFormData } from '../helpers/request';
import { VideoResponse } from '../interfaces/Query';

export const fetchVideoMetadata = (query: VideoQuery[]): Promise<VideoResult[] & AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const fetchVideos = async (query: VideoQuery[]): Promise<VideoResponse & AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const fetchVideo = async (uniqueId: string): Promise<VideoResponse & AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		return axios.get(`${apiUrl}/videos/`, {
			params: {
				_uniqueid: uniqueId,
			}});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

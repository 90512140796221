import { EntityClassProperties, EntityClassViewModel } from '../../interfaces/DataBaseStatus';
import { AuthenticationContext } from '../../contexts/AuthenticationContext';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { formatAmounts } from '../../helpers/numbers';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { MyUserModel } from '../../interfaces/AccessControl';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import { RefreshButton } from '../../components/RefreshButton';
import { SectionTitle } from '../../components/SectionTitle';
import StatusService from '../../services/StatusService';
import style from './StatusTable.module.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { useStatus } from './Hooks/useStatus';

const Row = (props: { row: EntityClassViewModel; showColor: boolean; myUser: MyUserModel | null }): JSX.Element => {
	const { row, showColor, myUser } = props;
	const [open, setOpen] = React.useState(false);

	return (
		<React.Fragment>
			<TableRow className={showColor ? `${style.rowWithColor}` : `${style.rowWithoutColor}`}>
				<TableCell>
					<Typography component="span" className={style.entityName}>
						{StatusService.formatClassNames(row.name)}
					</Typography>
				</TableCell>
				<TableCell align="left" className={style.textBody}>
					{formatAmounts(row.totalAmount)}
				</TableCell>
				<TableCell>
					{row.properties.length
						? (
							<IconButton className={style.arrowButton} aria-label="expand row" size="small" onClick={(): void => setOpen(!open)}>
								{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							</IconButton>
						)
						: (
							<IconButton className={style.arrowButton} aria-label="expand row" size="small" disabled>
								<KeyboardArrowUpIcon className={style.arrowButton} style={{ color: 'transparent' }} />
							</IconButton>
						)}
				</TableCell>
			</TableRow>
			<TableRow className={showColor ? `${style.rowWithColor}` : `${style.rowWithoutColor}`}>
				<TableCell style={{ padding: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Table size="small" aria-label="purchases">
							<colgroup>
								<col style={{ width: '60%' }} />
								<col style={{ width: '30%' }} />
								{myUser?.permissions.indexes.read && <col style={{ width: '10%' }} />}
							</colgroup>
							<TableBody>
								<TableRow>
									<TableCell scope="row" className={style.textHeader} style={{ paddingLeft: 65 }}>PROPERTY</TableCell>
									<TableCell className={style.textHeader}>TYPE</TableCell>
									{myUser?.permissions.indexes.read && <TableCell align="center" className={style.textHeader}>INDEX</TableCell>}
								</TableRow>
								{row.properties.map((property: EntityClassProperties) => (
									<TableRow key={property.name}>
										<TableCell scope="row" className={style.textBody} style={{ paddingLeft: 65 }}>
											{property.name}
										</TableCell>
										<TableCell className={style.showBordersAndText}>{property.type}</TableCell>
										{myUser?.permissions.indexes.read && <TableCell align="center" className={style.textBody}>{property.isIndex ? <FiberManualRecordIcon className={style.index} /> : ''}</TableCell>}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
};

const StatusTable: React.FC = () => {
	const { entityClassesViewModel, fetchStatus, isFetching } = useStatus();
	const { myUser } = React.useContext(AuthenticationContext);

	const sortEntities = (a: EntityClassViewModel, b: EntityClassViewModel): number => {
		if (a.totalAmount === b.totalAmount) {
			return (a.name.localeCompare(b.name));
		}

		return (a.totalAmount < b.totalAmount) ? 1 : -1;
	};

	return (
		<TableContainer component={Paper} className={style.statusTable} elevation={0}>
			<Box className={style.top}>
				<SectionTitle title="Objects" />
				<RefreshButton
					isLoading={isFetching}
					onClick={fetchStatus}
				/>
			</Box>
			<Table size="small" aria-label="purchases">
				<colgroup>
					<col style={{ width: '30%' }} />
					<col style={{ width: '60%' }} />
					<col style={{ width: '10%' }} />
				</colgroup>
				<TableBody className={style.header}>
					<TableRow>
						<TableCell scope="row" className={style.text}>CLASS</TableCell>
						<TableCell className={style.text}>COUNT</TableCell>
						<TableCell className={style.text}></TableCell>
					</TableRow>
				</TableBody>
			</Table>
			<Table size={entityClassesViewModel.length ? 'small' : undefined} aria-label="collapsible table">
				<colgroup>
					<col style={{ width: '30%' }} />
					<col style={{ width: '60%' }} />
					<col style={{ width: '10%' }} />
				</colgroup>
				<TableBody>
					{
						entityClassesViewModel.length
							? (
								entityClassesViewModel.sort(sortEntities).map((row, index) => (
									<Row key={row.name} row={row} showColor={index % 2 !== 0} myUser={myUser} />
								))
							)
							: (
								<TableRow>
									<TableCell align="center" className={style.textEmptyDB} colSpan={3}>
										{myUser?.permissions.objects.read ? 'Database is empty' : 'You don\'t have permissions to read data'}
									</TableCell>
								</TableRow>
							)
					}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default StatusTable;

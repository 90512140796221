/* eslint-disable @typescript-eslint/no-explicit-any */
import { FilterProperty, FilterResults } from './useImageSearchFilter';
import { ImageQueryContext, ImageQueryUpdateContext } from '../../../contexts/ImageQueryContext';
import ContentService from '../../../services/ContentService';
import ImageQueryService from '../../../services/ImageQueryService';
import { ImageResponse } from '../../../interfaces/Query';
import { ImageResult } from '../../../interfaces/Image';
import { OperationProperty } from './useImageSearchOperations';
import React from 'react';

interface UseImageSearchStore {
	isFetching: boolean;
	myImages: ImageResponse | undefined;
	setFilters: (resultFilters: FilterResults | undefined, properties: FilterProperty[]) => void;
	setOperations: (operations: OperationProperty[]) => void;
	runQuery: () => void;
	userQuery: string;
	queryErr: string;
	myImageQueryResult: any[];
	imagesPerPage: number;
	setPageOffset: (offset: number) => void;
	pageOffset: number;
	handleArrowClick: () => void;
	isEditorShown: boolean;
	duration: number;
	blobsSize: number;
	setIncludeBoundingBox: (value: boolean) => void;
}

const useImageSearch = (): UseImageSearchStore => {
	const { isFetching, myImages, myImageQueryResult, imagesPerPage, pageOffset, userQuery } = React.useContext(ImageQueryContext);
	const { fetchImageMetadata, setPageOffset, fetchMoreImages, setUserQuery } = React.useContext(ImageQueryUpdateContext);
	const [properties, setProperties] = React.useState<FilterProperty[]>([]);
	const [results, setResults] = React.useState<FilterResults | undefined>(undefined);
	const [operations, setOperations] = React.useState<OperationProperty[]>([]);
	const [queryErr, setQueryErr] = React.useState<string>('');
	const [queryResultErr, setResultQueryErr] = React.useState<string>('');
	const [isEditorShown, setIsEditorShown] = React.useState<boolean>(false);
	const [startTime, setStartTime] = React.useState<Date>(new Date());
	const [duration, setDuration] = React.useState<number>(0);
	const [blobsSize, setBlobsSize] = React.useState<number>(0);
	const [includeBoundingBox, setIncludeBoundingBox] = React.useState<boolean>(true);

	const getTimeDiff = (startQuery: Date, finishQuery: Date): void => {
		setDuration(finishQuery.getTime() - startQuery.getTime());
	};

	const setFilters = (filterResults: FilterResults | undefined, filterProperties: FilterProperty[]): void => {
		setProperties(filterProperties);
		setResults(filterResults);
	};

	const runQuery = (): void => {
		const filterResults = results ? {...results, boundingBox: includeBoundingBox} : results;

		const baseQuery = ImageQueryService.buildImageQuery(properties, filterResults, operations);
		const processedBaseQuery = ImageQueryService.getProcessedImageQuery(baseQuery);

		setStartTime(new Date());
		setBlobsSize(0);
		setQueryErr('');
		setResultQueryErr('');
		setUserQuery(JSON.stringify([baseQuery]));

		fetchImageMetadata(processedBaseQuery).catch(err => {
			if (err.name === 'InternalError') {
				setQueryErr('DB Error: Please check the Response for more information.');
				setResultQueryErr(err.message);
			} else {
				setQueryErr(err.message);
			}
		});
	};

	React.useEffect(() => {
		if (myImages) {
			const { json: queryResult } = myImages;

			if (queryResult.some(item => item.FindImage.status < 0)) {
				setQueryErr('DB Error: Some of the images are not displayed due to errors on bad operations.');
			} else {
				const now = new Date();

				getTimeDiff(startTime ? startTime : now, now);
				setBlobsSize(ContentService.getBlobsTotalSize(myImages.blobs));
			}
		}
	}, [myImages, startTime]);

	React.useEffect(() => {
		const now = new Date();
		let totalBytes = 0;

		getTimeDiff(startTime ? startTime : now, now);

		if (myImages) {
			totalBytes += ContentService.getBlobsTotalSize(myImages.blobs) + myImages.json.toString().length;
		}

		totalBytes += JSON.stringify(myImageQueryResult).length;

		setBlobsSize(totalBytes);
	}, [myImages, myImageQueryResult, startTime]);

	const changeImageGalleryPage = (offset: number): void => {
		setStartTime(new Date());
		setPageOffset(offset);
		fetchMoreImages(offset);
	};

	const myImageQueryResultProcessedForUser: any[] = (queryResultErr && [{ info: queryResultErr }]) || (JSON.parse(JSON.stringify(myImageQueryResult)) as ImageResult[]);

	myImageQueryResultProcessedForUser.forEach((item, index) => {
		if (typeof item === 'object' && item.FindImage) {
			const query = JSON.parse(userQuery);
			const entities = (item as ImageResult).FindImage.entities ?? [];

			if (query[index].FindImage && !query[index].FindImage.uniqueids) {
				if (entities.length && Object.keys(entities[0]).length === 1) {
					delete (item as ImageResult).FindImage['entities'];
				} else {
					entities.forEach(entity => {
						delete entity['_blob_index'];
					});
					(item as ImageResult).FindImage.entities = entities;
				}
			}
		}
	});

	const handleArrowClick = (): void => {
		setIsEditorShown((prevState) => !prevState);
	};

	const parsedUserQuery = JSON.parse(userQuery);
	const newUserQuery = [];

	(parsedUserQuery && parsedUserQuery[0] && parsedUserQuery[0].FindImage) && newUserQuery.push({ FindImage: parsedUserQuery[0].FindImage });
	(parsedUserQuery && parsedUserQuery[0] && parsedUserQuery[0].FindBoundingBox) && newUserQuery.push({ FindBoundingBox: parsedUserQuery[0].FindBoundingBox });

	return {
		isFetching,
		myImages,
		setFilters,
		setOperations,
		runQuery,
		userQuery: JSON.stringify(newUserQuery),
		queryErr,
		myImageQueryResult: myImageQueryResultProcessedForUser,
		imagesPerPage,
		setPageOffset: changeImageGalleryPage,
		pageOffset,
		handleArrowClick,
		isEditorShown,
		duration,
		blobsSize,
		setIncludeBoundingBox,
	};
};

export default useImageSearch;

import { FullRoleAccessLevelModel } from '../../interfaces/AccessControl';
import React from 'react';
import RolesTable from './RolesTable';
import { SplitPanes } from '../../components';
import style from './AccessControl.module.scss';
import useRoutes from '../../helpers/hooks/useRoutes';
import UsersTable from './UsersTable';
import useVisibility from '../../helpers/hooks/useVisibility';

const PERMISSION_OPTION_REQUIRED =
	'access_control' as keyof FullRoleAccessLevelModel;

const AccessControl: React.FC = () => {
	const { routes } = useRoutes();
	const { show } = useVisibility(
		routes.accessControl,
		PERMISSION_OPTION_REQUIRED
	);

	return (
		<div className={style.accessControl} style={{ visibility: show ? 'visible' : 'hidden' }}>
			<SplitPanes allowResize={false}>
				<div className={style.leftContainer}>
					<div className={style.table}>
						<UsersTable />
					</div>
				</div>
				<div className={style.rightContainer}>
					<div className={style.table}>
						<RolesTable />
					</div>
				</div>
			</SplitPanes>
		</div>
	);
};

export default AccessControl;

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

interface AlertDialogProps {
	open: boolean;
	onClose: (open: boolean) => void;
	primaryActionLabel?: string;
	cancelActionLabel?: string;
	successButtonOnClick: () => void;
	dialogTitle?: string;
	dialogContent?: string;
}

const AlertDialog: React.FC<AlertDialogProps> = ({ open, onClose, primaryActionLabel, cancelActionLabel, successButtonOnClick, dialogTitle, dialogContent }) => {
	const handleClose = (): void => {
		onClose(false);
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{dialogTitle || 'Message'}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{dialogContent || 'Are you sure you want to continue?'}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						{cancelActionLabel || 'Disagree'}
					</Button>
					<Button onClick={successButtonOnClick} color="primary" autoFocus>
						{primaryActionLabel || 'Agree'}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default AlertDialog;

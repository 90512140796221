import { AccessControlProvider } from './contexts/AccessControlContext';
import { AdvancedSearchProvider } from './contexts/AdvancedSearchContext';
import { AuthenticationProvider } from './contexts/AuthenticationContext';
import { CustomQueryProvider } from './contexts/CustomQueryContext';
import { Home } from './pages';
import { ImageQueryProvider } from './contexts/ImageQueryContext';
import { ModalProvider } from './contexts/ModalContext';
import { OverlayProvider } from './contexts/OverlayContext';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { SimilaritySearchProvider } from './contexts/SimilaritySearchContext';
import { StatusProvider } from './contexts/StatusContext';
import { SystemStatusProvider } from './contexts/SystemStatusContext';
import { VideoQueryProvider } from './contexts/VideoQueryContext';

const App: React.FC = () => (
	<OverlayProvider>
		<AuthenticationProvider>
			<ModalProvider>
				<SystemStatusProvider>
					<StatusProvider>
						<ImageQueryProvider>
							<CustomQueryProvider>
								<VideoQueryProvider>
									<AdvancedSearchProvider>
										<SimilaritySearchProvider>
											<AccessControlProvider>
												<Router basename='/'>
													<Home />
												</Router>
											</AccessControlProvider>
										</SimilaritySearchProvider>
									</AdvancedSearchProvider>
								</VideoQueryProvider>
							</CustomQueryProvider>
						</ImageQueryProvider>
					</StatusProvider>
				</SystemStatusProvider>
			</ModalProvider>
		</AuthenticationProvider>
	</OverlayProvider>
);

export default App;

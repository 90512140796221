import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Button } from '../../components/Button';
import ContentGallery from '../../components/ContentGallery/ContentGallery';
import { formatByteAmounts } from '../../helpers/numbers';
import { IconButton } from '@material-ui/core';
import { MessageBox } from '../../components/MessageBox';
import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import { SectionEditor } from '../../components/SectionEditor';
import { SectionTabs } from '../../components/SectionTabs';
import { SplitPanes } from '../../components';
import style from './VideoSearch.module.scss';
import useRoutes from '../../helpers/hooks/useRoutes';
import useVideoSearch from './Hooks/useVideoSearch';
import useVisibility from '../../helpers/hooks/useVisibility';
import VideoSearchFilter from './VideoSearchFilter';
import VideoSearchOperations from './VideoSearchOperations';

const VideoSearch: React.FC = () => {
	const { routes } = useRoutes();
	const { show } = useVisibility(routes.videoSearch);

	const {
		isFetching,
		myVideos,
		setFilters,
		setOperations,
		runQuery,
		userQuery,
		queryErr,
		myVideoQueryResult,
		videosPerPage,
		setPageOffset,
		pageOffset,
		handleArrowClick,
		isEditorShown,
		duration,
		blobsSize,
	} = useVideoSearch();

	const tabLists = [
		{
			id: 0,
			title: 'Query',
			bodyTab: <div></div>,
			bodyPanel: (
				<div>
					<SectionEditor
						readonly={true}
						height={'30vh'}
						body={JSON.stringify(JSON.parse(userQuery), null, 2)}
					/>
				</div>
			),
		},
		{
			id: 1,
			title: 'Response',
			bodyTab: <div></div>,
			bodyPanel: (
				<div>
					<SectionEditor
						readonly={true}
						height={'30vh'}
						body={JSON.stringify(myVideoQueryResult, null, 2)}
					/>
				</div>
			),
		},
	];

	return (
		<div style={{ flex: 1, visibility: show ? 'visible' : 'hidden' }}>
			<div className={style.videoSearch}>
				<SplitPanes primaryMaxSize={400}>
					<div className={style.leftContainer}>
						<div className={style.runButton}>
							<Button
								title="Run"
								onClick={runQuery}
								loadingTitle="Running..."
								isLoading={isFetching}
								startIcon={<RefreshIcon />}
							/>
						</div>
						<div className={style.errorMessage}>
							{queryErr ? (
								<MessageBox text={queryErr} />
							) : (
								myVideoQueryResult &&
							myVideoQueryResult.length > 0 &&
							!isFetching && (
									<MessageBox
										type={'success'}
										text={
											<>
												<strong>Time:</strong>
												{` ${duration}ms — `}
												<strong>Size:</strong>
												{` ${formatByteAmounts(blobsSize)}`}
											</>
										}
									/>
								)
							)}
						</div>
						<div className={style.filter}>
							<VideoSearchFilter setFilters={setFilters} />
						</div>
						<div className={style.operations}>
							<VideoSearchOperations setOperations={setOperations} />
						</div>
					</div>
					<div className={style.rightContainer}>
						<div className={style.video}>
							<ContentGallery
								rawVideos={myVideos}
								rawImages={undefined}
								isFetching={isFetching}
								pageOffset={pageOffset}
								rawPerPage={videosPerPage}
								total={
									(myVideoQueryResult &&
									myVideoQueryResult[0] &&
									myVideoQueryResult[0].FindVideo &&
									myVideoQueryResult[0].FindVideo.returned) ||
								0
								}
								onPageChange={setPageOffset}
							/>
						</div>
						<div
							style={{
								justifyContent: 'center',
								display: 'flex',
								flexDirection: 'column',
								marginTop: 21,
							}}
						>
							<div style={{ alignSelf: 'center' }}>
								<IconButton
									onClick={handleArrowClick}
									style={{
										backgroundColor: 'white',
										boxShadow: '0 1px 6px 0 #e4e5ee',
									}}
								>
									{isEditorShown ? (
										<ExpandLess />
									) : (
										<ExpandMore />
									)}
								</IconButton>
							</div>
							<div className={style.queries}>
								<SectionTabs
									sectionTabs={tabLists}
									hideContent={isEditorShown}
								/>
							</div>
						</div>
					</div>
				</SplitPanes>
			</div>
		</div>
	);
};

export default VideoSearch;

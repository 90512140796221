import './UpdatePassword.scss';
import AccessControlService from '../services/AccessControlService';
import { Alert } from '@material-ui/lab';
import { Button } from '../components/Button';
import { Modal } from '../components/Modal';
import React from 'react';
import { Snackbar } from '@material-ui/core';
import { TextField } from '../components/TextField';
import tokenIcon from '../assets/token-icon.png';
import useUpdatePassword from './Hooks/useUpdatePassword';
import visibility from '../assets/visibility.png';

const UpdatePassword: React.FC = () => {
	const { isOpenUpdate, setIsOpenUpdate, setNewPassword, newPassword, setOldPassword, oldPassword, setRepeatPassword, repeatPassword, changePassword, error, setError } = useUpdatePassword();
	const [oldPasswordShown, setOldPasswordShown] = React.useState<boolean>(true);
	const [newPasswordShown, setNewPasswordShown] = React.useState<boolean>(true);
	const [repeatPasswordShown, setRepeatPasswordShown] = React.useState<boolean>(true);
	const [match, setMatch] = React.useState<boolean>(true);

	const toggleOldPassword = (): void => {
		setOldPasswordShown(!oldPasswordShown);
	};

	const oldPasswordHandler = (value: string): void => {
		setOldPassword(value);
	};

	const toggleNewPassword = (): void => {
		setNewPasswordShown(!newPasswordShown);
	};

	const validatePassword = (value: string): void => {
		if (newPassword !== value) {
			setMatch(false);
		} else {
			setMatch(true);
		}
	};

	const newPasswordHandler = (value: string): void => {
		validatePassword(value);
		setNewPassword(value);
	};

	const toggleRepeatPassword = (): void => {
		setRepeatPasswordShown(!repeatPasswordShown);
	};

	const repeatPasswordHandler = (value: string): void => {
		validatePassword(value);
		setRepeatPassword(value);
	};

	const handleErrorClose = (event?: React.SyntheticEvent, reason?: string): void => {
		if (reason === 'clickaway') {
			return;
		}

		setError('');
	};

	const generatePassword = (): void => {
		const passwordGenerated = AccessControlService.generateUserPassword();

		setNewPassword(passwordGenerated);
		setRepeatPassword(passwordGenerated);
		setMatch(true);
	};

	return (
		<Modal open={isOpenUpdate} setOpen={setIsOpenUpdate} title={'Update password'} successButton={'update password'} successButtonOnClick={(): void => changePassword()} successButtonDisabled={!oldPassword || !newPassword || !repeatPassword || !match}>
			<div className='update-password-content'>
				<form>
					<div className='update-password-input'>
						<TextField value={oldPassword} autocomplete="current-password" onChange={oldPasswordHandler} label={'Enter old password'} type={oldPasswordShown ? 'password' : undefined} iconInput={<img src={visibility} onClick={toggleOldPassword} />} />
					</div>
					<div className='update-password-input'>
						<TextField value={newPassword} autocomplete="new-password" onChange={newPasswordHandler} label={'Enter new password'} type={newPasswordShown ? 'password' : undefined} iconInput={<img src={visibility} onClick={toggleNewPassword} />} />
					</div>
					<div className='update-password-input'>
						<TextField value={repeatPassword} autocomplete="repeat-password" onChange={repeatPasswordHandler} label={'Repeat new password'} type={repeatPasswordShown ? 'password' : undefined} iconInput={<img src={visibility} onClick={toggleRepeatPassword} />} />
						{repeatPassword ? (match ? null : <div className='update-password-error'>Password do not match</div>) : null}
					</div>
					<Button title="Generate password" onClick={(): void => generatePassword()} startIcon={<img src={tokenIcon} alt='Token' />} isControlAccess={true} />
				</form>
			</div>
			<Snackbar open={!!error} autoHideDuration={6000} onClose={handleErrorClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<Alert onClose={handleErrorClose} severity="error">
					{error}
				</Alert>
			</Snackbar>
		</Modal>
	);
};

export default UpdatePassword;

import { AuthenticateModel, AuthenticateResponse } from '../interfaces/Auth';
import { CustomResponse } from '../interfaces/Query';
import { login } from '../api/AuthRepository';

const loginToDatabase = async (userData: AuthenticateModel): Promise<AuthenticateResponse[]> => {
	const response = await login(userData);
	const responseData = response.data as CustomResponse;

	return JSON.parse(JSON.stringify(responseData.json)) as AuthenticateResponse[];
};

export default {
	loginToDatabase,
};

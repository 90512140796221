import { QuerySaved } from '../interfaces/SaveQueries';

const tokenKey = 'token';
const refreshTokenKey = 'refresh_token';
const usernameKey = 'username';
const apiKey = 'api';
const optionLabelKey = 'labelKey';
const optionKey = 'optionKey';
const saveQueryCustoms = 'savedQueryCustoms';

export function getAuthToken(): string | null {
	return localStorage.getItem(tokenKey);
}

export function setAuthToken(token: string): void {
	localStorage.setItem(tokenKey, token);
}

export function getRefreshToken(): string | null {
	return localStorage.getItem(refreshTokenKey);
}

export function setRefreshToken(refreshToken: string): void {
	localStorage.setItem(refreshTokenKey, refreshToken);
}

export function getUsernameKey(): string | null {
	return localStorage.getItem(usernameKey);
}

export function setUsernameKey(username: string): void {
	localStorage.setItem(usernameKey, username);
}

export function getApiKey(): string | null {
	return localStorage.getItem(apiKey);
}

export function setApiKey(api: string): void {
	localStorage.setItem(apiKey, api);
}

export function removeToken(): void {
	localStorage.removeItem(tokenKey);
}

export function removeApiKey(): void {
	localStorage.removeItem(apiKey);
}

export function clearLocalStorage(): void {
	localStorage.clear();
}

export function cleanUserLoginData(): void {
	localStorage.removeItem(apiKey);
	localStorage.removeItem(tokenKey);
	localStorage.removeItem(usernameKey);
}

export function getOptionLabelModalImage(): string | null {
	return localStorage.getItem(optionLabelKey);
}

export function setOptionLabelModalImage(value: boolean): void {
	const formatData = JSON.stringify(value);

	localStorage.setItem(optionLabelKey, formatData);
}

export function getOptionModalImage(): string | null {
	return localStorage.getItem(optionKey);
}

export function setOptionModalImage(value: boolean): void {
	const formatData = JSON.stringify(value);

	localStorage.setItem(optionKey, formatData);
}

export function getSavedCustomQueries(): string | null {
	return localStorage.getItem(saveQueryCustoms);
}

export function setSaveQueryCustoms(value: QuerySaved[]): void {
	const formatData = JSON.stringify(value);

	localStorage.setItem(saveQueryCustoms, formatData);
}

import { SystemInfoPanelStateKey, SystemStatusContext } from '../../../contexts/SystemStatusContext';
import React from 'react';
import { SystemInfoResponse } from '../../../interfaces/SystemStatus';
import { useLocalStorage } from '../../../helpers/hooks/useLocalStorage';

export interface SystemStatusStore {
	systemInfoResponse: SystemInfoResponse;
	open: boolean;
	toggleSystemDataPanel: () => void;
}

const baseSystemData = { 'connection_count': 0, 'cpu_utilization': 0, 'info': 'OK',
	'memory_utilization': 0, 'memory_utilization_gb': 0, 'queries_queued': 0, 'queries_running': 0, 'status': 0, 'storage_gb': {
		'blobs': 0,
		'graph': 0,
		'images': 0,
		'total': 0,
		'videos': 0,
	}, 'system': 'ApertureDB', 'version': '0.0.0' };

export const useSystemStatus = (): SystemStatusStore => {
	const { mySystemStatus } = React.useContext(SystemStatusContext);
	const { setItem, isKeyStored, getItem } = useLocalStorage(SystemInfoPanelStateKey);
	const [systemInfoResponse, setsystemInfoResponse] = React.useState<SystemInfoResponse>(baseSystemData);
	const [open, setOpen] = React.useState(isKeyStored() ? getItem() === 'OPENED' : false);

	React.useEffect((): void => {
		if (mySystemStatus && mySystemStatus.length) {
			const getStatus = mySystemStatus[0].GetStatus as SystemInfoResponse;

			setsystemInfoResponse(getStatus);

		}
	}, [mySystemStatus]);

	const toggleSystemDataPanel = (): void => {
		setItem(open ? 'CLOSED' : 'OPENED');
		setOpen(!open);
	};

	return {
		systemInfoResponse,
		open,
		toggleSystemDataPanel,
	};
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomResponse } from '../interfaces/Query';
import { fetchData } from '../api/CustomQueryRepository';
import { ImageQuery } from '../interfaces/Image';
import ImageQueryService from './ImageQueryService';
import { VideoQuery } from '../interfaces/Video';
import VideoQueryService from './VideoQueryService';

const getData = async (query: ImageQuery[] | VideoQuery[]): Promise<any[]> => {
	const rawMetadataResponse = await fetchData(query);
	const rawMetadata = rawMetadataResponse.data as CustomResponse;

	if (JSON.parse(JSON.stringify(rawMetadata.json)).info) {
		throw ({
			name: 'InternalError',
			message: JSON.parse(JSON.stringify(rawMetadata.json)).info,
		});
	}

	return JSON.parse(JSON.stringify(rawMetadata.json)) as any[];
};

const getProcessedCustomQuery = (customQuery: any[]): any[] => {
	const customQueryCopy = [...customQuery];

	customQueryCopy.forEach(individualQuery => {
		if (typeof individualQuery === 'object' && individualQuery.FindImage) {
			individualQuery.FindImage = ImageQueryService.getProcessedImageQuery(individualQuery as ImageQuery).FindImage;
		} else {
			if (typeof individualQuery === 'object' && individualQuery.FindVideo){
				individualQuery.FindVideo = VideoQueryService.getProcessedVideoQuery(individualQuery as VideoQuery).FindVideo;
			}
		}
	});

	return customQueryCopy;
};

export default {
	getData,
	getProcessedCustomQuery,
};

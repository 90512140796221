import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Moment from 'react-moment';
import { QuerySaved } from '../../../interfaces/SaveQueries';
import React from 'react';
import SaveIcon from '@material-ui/icons/Save';
import styles from './QuerySaveList.module.scss';

interface Props {
	data: QuerySaved[];
	handleSelected: (id: number) => void;
	handleDeleteModal: (value: number) => void;
	handleModalSave: (query: QuerySaved) => void;
}

const QuerySaveList: React.FC<Props> = ({ data, handleSelected, handleDeleteModal, handleModalSave }) => {
	return (
		<div className={styles.querySaveList}>
			{data.length !== 0 ? data.map((element: QuerySaved) => (
				<div key={element.id} className={`${styles.saveQueryCard} ${element.isSelected && styles.saveQueryCardSelected}`} onClick={() => handleSelected(element.id)}>
					<div>
						<Tooltip title={`Select ${element.name}`} placement="right" arrow={true}>
							<div className={styles.nameQueryCard} dangerouslySetInnerHTML={{ __html: `${element.name || '&nbsp;'}` }}></div>
						</Tooltip>
					</div>
					<div className={styles.bottomQueryCard}>
						<Moment format="DD/MM/YYYY HH:mm:ss">{element.date}</Moment>
						<div className={styles.actions}>
							<Tooltip title={'Save'} placement="right" arrow={true}>
								<IconButton aria-label="save" onClick={() => handleModalSave(element)} className={`${styles.saveItem} ${element.isModified && styles.active}`} disabled={!element.isModified} >
									<SaveIcon fontSize="small" />
								</IconButton>
							</Tooltip>
							<Tooltip title={'Delete'} placement="right" arrow={true}>
								<IconButton aria-label="delete"
									className={styles.deleteItem} onClick={() => handleDeleteModal(element.id)}>
									<DeleteIcon fontSize="small" />
								</IconButton>
							</Tooltip>
						</div>
					</div>
				</div>
			)) :
				<div className={styles.emptyHistoryQueries}>
					No queries at the moment
				</div>
			}
		</div>
	);
};

export default QuerySaveList;

import { Box, Switch, Typography } from '@material-ui/core';
import { CheckBox } from '../../../components/CheckBox';
import { FilterResults } from '../Hooks/useVideoSearchFilter';
import { HelpText } from '../../../components/HelpText';
import React from 'react';
import { SelectBox } from '../../../components/SelectBox';
import style from './ResultsBoxes.module.scss';
import { TextField } from '../../../components/TextField';

interface ResultsBoxesProps {
	resultFilters: FilterResults;
	setResultFilters: (resultFilters: FilterResults) => void;
	propertyNames: string[];
}

export type FormatOptions = 'JPG' | 'PNG' | 'DEFAULT';

export interface PropertyTextData {
	operation: FormatOptions;
	value: string;
}

const ResultsBoxes: React.FC<ResultsBoxesProps> = ({
	resultFilters,
	setResultFilters,
	propertyNames,
}) => {
	return (
		<div className={style.resultsBoxes}>
			<Box className={style.box}>
				<Box className={style.top}>
					<Typography className={style.title}>Limit</Typography>
				</Box>
				<Box className={style.limit}>
					<Box className={style.text}>
						<TextField
							placeholder='Integer value...'
							value={resultFilters.limit}
							onChange={(value: string): void =>
								setResultFilters({
									...resultFilters,
									limit: value,
								})
							}
							type='number-integer'
						/>
					</Box>
				</Box>
			</Box>
			<Box className={style.box}>
				<Box className={style.top}>
					<div className={style.groupLeft}>
						<Typography className={style.title}>
							Unique
						</Typography>
						<HelpText
							text={'Validates the existence of unique result'}
						/>
					</div>
				</Box>
				<Box className={style.unique}>
					<Typography className={style.label}>
						False
					</Typography>
					<Switch
						checked={resultFilters.unique}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>
						): void =>
							setResultFilters({
								...resultFilters,
								unique: event.target.checked,
							})
						}
						color='primary'
					/>
					<Typography className={style.label}>
						True
					</Typography>
				</Box>
			</Box>
			<Box className={style.box}>
				<Box className={style.top}>
					<Typography className={style.title}>
						Sort by
					</Typography>
				</Box>
				<Box className={style.sortBy}>
					<Box className={style.select}>
						<SelectBox
							selectedValue={resultFilters.sortBy}
							onChange={(value: unknown): void =>
								setResultFilters({
									...resultFilters,
									sortBy: value as string,
								})
							}
							items={[
								...propertyNames.map((propertyName) => ({
									label: propertyName,
									value: propertyName,
								})),
								{ label: 'None', value: ' '},
							]}
						/>
					</Box>
				</Box>
			</Box>
			<Box className={style.box}>
				<Box className={style.top}>
					<Typography className={style.title}>
						Properties
					</Typography>
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<Typography className={style.select}>
							None
						</Typography>
						<Switch
							checked={
								resultFilters.list.length ===
								propertyNames.length
							}
							onChange={(
								event: React.ChangeEvent<HTMLInputElement>
							): void => {
								if (event.target.checked) {
									setResultFilters({
										...resultFilters,
										list: propertyNames,
									});
								} else {
									setResultFilters({
										...resultFilters,
										list: [],
									});
								}
							}}
							color='primary'
						/>
						<Typography className={style.select}>
							All
						</Typography>
					</Box>
				</Box>
				<Box className={style.list}>
					<Box className={style.checkbox}>
						{propertyNames.map((propertyName) => (
							<CheckBox
								isChecked={resultFilters.list.some(
									(listKey) => listKey === propertyName
								)}
								label={propertyName}
								onChange={(value: boolean): void => {
									if (value) {
										setResultFilters({
											...resultFilters,
											list: [
												...resultFilters.list,
												propertyName,
											],
										});
									} else {
										const listCopy = resultFilters.list;

										listCopy.splice(
											resultFilters.list.indexOf(
												propertyName
											),
											1
										);

										setResultFilters({
											...resultFilters,
											list: listCopy,
										});
									}
								}}
								key={propertyName}
							/>
						))}
					</Box>
				</Box>
			</Box>
		</div>
	);
};

export default ResultsBoxes;

import { AuthenticationContext, AuthenticationUpdateContext } from '../../contexts/AuthenticationContext';
import { ModalContext, ModalUpdateContext } from '../../contexts/ModalContext';
import AccessControlService from '../../services/AccessControlService';
import React from 'react';

interface UseManageTokenStore {
	setIsManageTokenModalOpen: (open: boolean) => void;
	isManageTokenModalOpen: boolean;
	tokens: string[];
	addTokenToRemoveList: (token: string) => void;
	generateToken: () => void;
	saveTokens: () => void;
	error: string;
	setError: (value: string) => void;
}

const useManageToken = (): UseManageTokenStore => {
	const { isManageTokenModalOpen } = React.useContext(ModalContext);
	const { setIsManageTokenModalOpen } = React.useContext(ModalUpdateContext);
	const { myUser } = React.useContext(AuthenticationContext);
	const { fetchMyUserInfo } = React.useContext(AuthenticationUpdateContext);
	const [ tokensToRemove, setTokensToRemove ] = React.useState<string[]>([]);
	const [ tokensToAdd, setTokensToAdd ] = React.useState<string[]>([]);
	const [ error, setError ] = React.useState('');

	const cleanModalState = (): void => {
		setTokensToRemove([]);
		setTokensToAdd([]);
		setError('');
	};

	const openModal = (open: boolean): void => {
		if (!open) {
			cleanModalState();
		}

		setIsManageTokenModalOpen(open);
	};

	const addTokenToRemoveList = (token: string): void => {
		if (tokensToAdd.includes(token)) {
			setTokensToAdd([ ...tokensToAdd.filter(tokenToAdd => tokenToAdd !== token) ]);

			return;
		}

		setTokensToRemove([ ...tokensToRemove, token ]);
	};

	const generateToken = async (): Promise<void> => {
		try {
			const response = await AccessControlService.generateTempToken();

			if (response[0].GenerateToken.status === -1) {
				setError(response[0].GenerateToken.info);
			} else {
				const newToken = response[0].GenerateToken.token;

				setError('');
				setTokensToAdd([ ...tokensToAdd, newToken ]);
			}
		} catch {
			setError('An error occurred while creating a token');
		}
	};

	const saveTokens = async (): Promise<void> => {
		try {
			if (myUser) {
				const response = await AccessControlService.updateUserInDataBase({
					username: myUser.username,
					'add_tokens': tokensToAdd,
					'remove_tokens': tokensToRemove,
				});

				if (response.status === -1) {
					setError(response.info);
				} else {
					setError('');
					await fetchMyUserInfo();
					openModal(false);
				}
			} else {
				setError('An error occurred while updating tokens');
			}
		} catch {
			setError('An error occurred while updating tokens');
		}
	};

	return {
		setIsManageTokenModalOpen: openModal,
		isManageTokenModalOpen,
		tokens: [ ...(myUser?.tokens || []).filter(token => !tokensToRemove.includes(token)), ...tokensToAdd ],
		addTokenToRemoveList,
		generateToken,
		saveTokens,
		error,
		setError,
	};
};

export default useManageToken;

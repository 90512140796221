import { ImageResponse, VideoResponse } from '../interfaces/Query';
import React, { ReactNode } from 'react';
import { AdvancedSearch } from '../interfaces/AdvancedSearch';
import { AuthenticationContext } from './AuthenticationContext';
import ImageQueryService from '../services/ImageQueryService';
import VideoQueryService from '../services/VideoQueryService';

export interface AdvancedSearchStore {
	myAdvancedSearchResult: ImageResponse | VideoResponse | undefined;
	isFetching: boolean;
}

export interface AdvancedSearchProviderStore {
	fetchAdvancedSearch: (search: AdvancedSearch) => Promise<void>;
}

export const AdvancedSearchContext = React.createContext({} as AdvancedSearchStore);
export const AdvancedSearchUpdateContext = React.createContext({} as AdvancedSearchProviderStore);

const AdvancedSearchProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const { isAuthentication } = React.useContext(AuthenticationContext);
	const [myAdvancedSearchResult, setMyAdvancedSearchResult] = React.useState<ImageResponse | VideoResponse | undefined>(undefined);
	const [isFetching, setIsFetching] = React.useState(false);

	const fetchAdvancedSearch = async (search: AdvancedSearch): Promise<void> => {
		if (isAuthentication) {
			setIsFetching(true);

			try {
				const advancedSearch = search.type === 'image' ? await ImageQueryService.getImage(search.uniqueId) as ImageResponse : await VideoQueryService.getVideo(search.uniqueId) as VideoResponse;

				setMyAdvancedSearchResult(advancedSearch);
			} catch (err) {
				Promise.reject((err as unknown as { name: string; message: string }).message);
			}

			setIsFetching(false);
		} else {
			setMyAdvancedSearchResult(undefined);
		}

		Promise.resolve();
	};

	const store: AdvancedSearchStore = {
		myAdvancedSearchResult,
		isFetching,
	};

	const updateStore: AdvancedSearchProviderStore = {
		fetchAdvancedSearch,
	};

	return (
		<AdvancedSearchContext.Provider value={store}>
			<AdvancedSearchUpdateContext.Provider value={updateStore}>
				{children}
			</AdvancedSearchUpdateContext.Provider>
		</AdvancedSearchContext.Provider>
	);
};

export { AdvancedSearchProvider };
export const AdvancedSearchConsumer = AdvancedSearchContext.Consumer;

import { ConnectionClassViewModel, EntityClassViewModel } from '../../interfaces/DataBaseStatus';
import { LinearProgress, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { AuthenticationContext } from '../../contexts/AuthenticationContext';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import { RefreshButton } from '../../components/RefreshButton';
import { SectionTitle } from '../../components/SectionTitle';
import StatusService from '../../services/StatusService';
import style from './StatusList.module.scss';
import TableContainer from '@material-ui/core/TableContainer';
import { useStatus } from './Hooks/useStatus';

const StatusList: React.FC = () => {
	const { entityClassesViewModel, connectionClassesViewModel, isFetching, fetchStatus } = useStatus();
	const highestAmountEnt = StatusService.getHighestAmount(entityClassesViewModel);
	const highestAmountConn = StatusService.getHighestAmountConn(connectionClassesViewModel);
	const { myUser } = React.useContext(AuthenticationContext);

	const sortConnections = (a: ConnectionClassViewModel, b: ConnectionClassViewModel): number => {
		if (a.totalAmount === b.totalAmount) {
			return (a.name.localeCompare(b.name));
		}

		return (a.totalAmount < b.totalAmount) ? 1 : -1;
	};

	const sortEntities = (a: EntityClassViewModel, b: EntityClassViewModel): number => {
		if (a.totalAmount === b.totalAmount) {
			return (a.name.localeCompare(b.name));
		}

		return (a.totalAmount < b.totalAmount) ? 1 : -1;
	};

	return (
		<TableContainer component={Paper} className={style.statusList} elevation={0}>
			<Box className={style.top}>
				<SectionTitle title="Summary" />
				<RefreshButton
					isLoading={isFetching}
					onClick={fetchStatus}
				/>
			</Box>
			<Table size="small" aria-label="summary">
				<colgroup>
					<col style={{ width: 150 }} />
					<col />
					<col style={{ width: 120 }} />
				</colgroup>
				<TableBody className={style.header}>
					<TableRow>
						<TableCell align="right" scope="row" className={style.text}>OBJECTS</TableCell>
						<TableCell />
						<TableCell align="right" scope="row" className={style.text}>COUNT</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			<Table aria-label="summary">
				<colgroup>
					<col style={{ width: 150 }} />
					<col />
					<col style={{ width: 120 }} />
				</colgroup>
				<TableBody>
					{
						entityClassesViewModel.length
							? (
								entityClassesViewModel.sort(sortEntities).map(entityClassViewModel => {
									return (
										<TableRow key={entityClassViewModel.name} className={style.row}>
											<TableCell align="right" scope="row" className={style.text}>{StatusService.formatClassNames(entityClassViewModel.name)}</TableCell>
											<TableCell>
												<LinearProgress variant="determinate" value={entityClassViewModel.totalAmount / highestAmountEnt * 100} className={style.bar} />
											</TableCell>
											<TableCell align="right" scope="row" className={style.text}>{new Intl.NumberFormat('en-US').format(entityClassViewModel.totalAmount)}</TableCell>
										</TableRow>
									);
								})
							)
							: (
								<TableRow>
									<TableCell align="center" className={style.textHeader} colSpan={3}>
										{myUser?.permissions.objects.read ? 'Database is empty' : 'You don\'t have permissions to read data'}
									</TableCell>
								</TableRow>
							)
					}
				</TableBody>
			</Table>
			<Table size="small" aria-label="summary">
				<colgroup>
					<col style={{ width: 150 }} />
					<col />
					<col style={{ width: 120 }} />
				</colgroup>
				<TableBody className={style.header}>
					<TableRow>
						<TableCell align="right" scope="row" className={style.text}>CONNECTIONS</TableCell>
						<TableCell />
						<TableCell align="right" scope="row" className={style.text}>COUNT</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			<Table aria-label="summary">
				<colgroup>
					<col style={{ width: 150 }} />
					<col />
					<col style={{ width: 120 }} />
				</colgroup>
				<TableBody>
					{
						connectionClassesViewModel.length
							? (
								connectionClassesViewModel.sort(sortConnections).map(connectionClassViewModel => {
									return (
										<TableRow key={connectionClassViewModel.name} className={style.row}>
											<TableCell align="right" scope="row" className={style.text}>{StatusService.formatClassNames(connectionClassViewModel.name)}</TableCell>
											<TableCell>
												<LinearProgress variant="determinate" value={connectionClassViewModel.totalAmount / highestAmountConn * 100} className={style.bar} />
											</TableCell>
											<TableCell align="right" scope="row" className={style.text}>{new Intl.NumberFormat('en-US').format(connectionClassViewModel.totalAmount)}</TableCell>
										</TableRow>
									);
								})
							)
							: (
								<TableRow>
									<TableCell align="center" className={style.textHeader} colSpan={3}>
										{myUser?.permissions.objects.read ? 'Database is empty' : 'You don\'t have permissions to read data'}
									</TableCell>
								</TableRow>
							)
					}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default StatusList;

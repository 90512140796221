import Box from '@material-ui/core/Box';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import React from 'react';
import style from './ProgressChart.module.scss';

export type ProgressType = 'PERCENT' | 'SIZE';

export interface ProgressBarData {
	type: ProgressType;
	data: number;
	dataType: string;
}

interface ProgressChartProps {
	type: ProgressType;
	data: number;
	dataValue: number;
	dataType: string;
	dataTitle: string;
	boxTitle: string;
	boxData: number;
}

const ProgressChart: React.FC<ProgressChartProps> = ({ type, data, dataValue, dataType, dataTitle, boxTitle, boxData }) => {
	const typeInfo = type === 'PERCENT' ? '%' : type === 'SIZE' ? 'GB ' : null;

	return (
		<div className={style.platformGraphic}>
			<div className={style.platformGraphicProgress}>
				<div className={style.platformGraphicProgressCircle}>
					<CircularProgressbarWithChildren value={dataValue} >
						<div className={style.platformGraphicProgressData}> {`${Math.round(data * 10) / 10}${typeInfo}`} </div>
						<div className={style.platformGraphicProgressTitle}> {dataType}	</div>
					</CircularProgressbarWithChildren>
				</div>
				<div className={style.platformGraphicTitle}> {dataTitle} </div>
			</div>
			<Box className={style.platformProgressInfo}>
				{boxData}
				<div className={style.platformProgressInfoBoxTitle}> {boxTitle}	</div>
			</Box>
		</div>
	);
};

export default ProgressChart;

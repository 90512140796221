import { Box, Slider, Typography } from '@material-ui/core';
import React from 'react';
import style from './OperationThresholdBox.module.scss';

interface OperationThresholdBoxProps {
	data: unknown;
	updateOperation: (data: unknown) => void;
}

export interface OperationThresholdData {
	value: number;
}

const OperationThresholdBox: React.FC<OperationThresholdBoxProps> = ({ data, updateOperation }) => {
	const textData = data as OperationThresholdData;

	const onChangeValue = (value: number): void => {
		updateOperation({ ...textData, value } as OperationThresholdData);
	};

	return (
		<Box className={style.operationThresholdBox}>
			<Box className={style.value}>
				<Slider min={0} max={255} value={textData.value} valueLabelDisplay="auto" onChange={(event: React.ChangeEvent<unknown>, newValue: number | number[]): void => onChangeValue(newValue as number)} />
			</Box>
			<Box className={style.numbers}>
				<Typography className={style.text}>0</Typography>
				<Typography className={style.text}>255</Typography>
			</Box>
		</Box>
	);
};

export default OperationThresholdBox;

import React, { ReactNode } from 'react';

export interface OverlayProviderStore {
	isOpen: boolean;
}

export interface OverlayUpdateProviderStore {
	setIsOpen: (isOpen: boolean) => void;
}

export const OverlayContext = React.createContext({} as OverlayProviderStore);
export const OverlayUpdateContext = React.createContext({} as OverlayUpdateProviderStore);

const OverlayProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);

	const store: OverlayProviderStore = {
		isOpen,
	};

	const updateStore: OverlayUpdateProviderStore = {
		setIsOpen,
	};

	return (
		<OverlayContext.Provider value={store}>
			<OverlayUpdateContext.Provider value={updateStore}>
				{children}
			</OverlayUpdateContext.Provider>
		</OverlayContext.Provider>
	);
};

export { OverlayProvider };
export const OverlayConsumer = OverlayContext.Consumer;

import './SectionTabs.scss';
import 'react-tabs/style/react-tabs.css';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import React from 'react';
import style from './SectionTabs.module.scss';

interface SectionTab {
	id: number;
	title: string;
	bodyTab: JSX.Element;
	bodyPanel: JSX.Element;
}

interface SectionTabsProps {
	sectionTabs: SectionTab[];
	hideContent?: boolean;
	tabIndex?: number;
	onSelect?: (tabIndex: number) => void;
}

const SectionTabs: React.FC<SectionTabsProps> = ({ sectionTabs, hideContent, tabIndex, onSelect }) => {

	return (
		<Tabs className={`react-tabs ${style.tabContainer}`} selectedIndex={tabIndex} onSelect={onSelect} selectedTabClassName={`react-tabs__tab--selected ${style.tabSelected}`}>
			<TabList className={`react-tabs__tab-list ${style.tabList}`}>
				{sectionTabs.map((childTab) => {
					const { id, title, bodyTab } = childTab;

					return (
						<Tab className={`react-tabs__tab ${style.tabItem}`}
							key={id}
							onClick={console.log}
							tabIndex={id.toString()}
						>
							<div className={style.tabSubItem}>
								{title}
								{bodyTab}
							</div>
						</Tab>
					);
				})}
			</TabList>
			{
				!hideContent &&
				sectionTabs.map((childTabPanel) => {
					const { id, bodyPanel } = childTabPanel;

					return (
						<TabPanel className='tab-Panel' key={id}>
							<div className={style.tabPanelContainer}>
								<div className={style.tabPanelItem}>
									<div style={{ flex: 1, backgroundColor: 'green' }}>
										{bodyPanel}
									</div>
								</div>
							</div>
						</TabPanel>
					);
				})
			}
		</Tabs>
	);
};

export default SectionTabs;

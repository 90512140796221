/* eslint-disable @typescript-eslint/no-explicit-any */
import { ImageQuery, ImageResult } from '../interfaces/Image';
import { ImageResponse, VideoResponse } from '../interfaces/Query';
import React, { ReactNode } from 'react';
import CustomQueryService from '../services/CustomQueryService';
import ImageQueryService from '../services/ImageQueryService';
import { VideoQuery } from '../interfaces/Video';
import VideoQueryService from '../services/VideoQueryService';

export interface CustomQueryProviderStore {
	myCustomQueryResult: any[];
	myImages: ImageResponse | undefined;
	myVideos: VideoResponse | undefined;
	isFetching: boolean;
	rawPerPage: number;
	pageOffset: number;
	userQuery: string;
	tabSelected: number;
}

export interface CustomQueryUpdateProviderStore {
	fetchCustomData: (rawQuery: ImageQuery[] | VideoQuery[]) => Promise<void>;
	setPageOffset: (offset: number) => void;
	fetchMoreRaw: (offset: number, pageArray?: any[]) => void;
	setUserQuery: (query: string) => void;
	setTabSelected: (tabIndex: number) => void;
	resetStates: () => void;
	setMyCustomQueryResult: (image: ImageResult[]) => void;
}

export const CustomQueryContext = React.createContext({} as CustomQueryProviderStore);
export const CustomQueryUpdateContext = React.createContext({} as CustomQueryUpdateProviderStore);

interface Page {
	findImage: ImageQuery;
	findVideo: VideoQuery;
	uniqueId: number;
}

const CustomQueryProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const [myCustomQueryResult, setMyCustomQueryResult] = React.useState<any[]>([]);
	const [myImages, setMyImages] = React.useState<ImageResponse | undefined>(undefined);
	const [myVideos, setMyVideos] = React.useState<VideoResponse | undefined>(undefined);
	const [isFetching, setIsFetching] = React.useState<boolean>(false);
	const [pages, setPages] = React.useState<Page[]>([]);
	const [pageOffset, setPageOffset] = React.useState<number>(1);
	const [userQuery, setUserQuery] = React.useState<string>('');
	const [tabSelected, setTabSelected] = React.useState<number>(0);
	const rawPerPage = 20;

	const resetStates = (): void => {
		setMyCustomQueryResult([]);
		setMyImages(undefined);
		setMyVideos(undefined);
		setIsFetching(false);
		setPages([]);
		setPageOffset(1);
		setUserQuery('');
		setTabSelected(0);
	};

	const fetchRaws = async (query: any[]): Promise<void> => {
		setIsFetching(true);

		try {
			const images = await ImageQueryService.getImages(query);

			setMyImages(images);
			const videos = await VideoQueryService.getVideos(query);

			setMyVideos(videos);

		} catch (err) {
			Promise.reject((err as unknown as { name: string; message: string }).message);
		}

		setIsFetching(false);
		Promise.resolve();
	};

	const fetchMoreRaw = async (offset: number, pageArray: Page[] = pages): Promise<void> => {
		let query: any[] = [];

		pageArray.slice((offset - 1) * rawPerPage, ((offset - 1) * rawPerPage) + rawPerPage).forEach(page => {
			if (page.findImage) {
				const queries: ImageQuery[] = ImageQueryService.getIndividualImageQueriesForBlob(page.findImage, [page.uniqueId]);

				query = query.concat(queries);
			} else {
				const queries: VideoQuery[] = VideoQueryService.getIndividualVideoQueriesForBlob(page.findVideo, [page.uniqueId]);

				query = query.concat(queries);
			}
		});

		if (query.length) {
			await fetchRaws(query);
		}
	};

	const fetchCustomData = async (rawQuery: ImageQuery[] | VideoQuery[]): Promise<void> => {
		setIsFetching(true);
		setMyCustomQueryResult([]);
		setMyImages(undefined);
		setMyVideos(undefined);
		setPageOffset(1);
		setPages([]);

		try {
			const dataResult = await CustomQueryService.getData(rawQuery);

			setMyCustomQueryResult(dataResult);

			const uniqueIdArray: number[][] = [];
			let tempPages: any[] = [];
			let findRawCounter = 0;

			dataResult.forEach(individualCustomQueryResult => {
				if (typeof individualCustomQueryResult === 'object' && individualCustomQueryResult.FindImage) {
					uniqueIdArray.push(ImageQueryService.getUniqueIdArray([individualCustomQueryResult]));
				} else {
					if (typeof individualCustomQueryResult === 'object' && individualCustomQueryResult.FindVideo) {
						uniqueIdArray.push(VideoQueryService.getUniqueIdArray([individualCustomQueryResult]));
					}
				}
			});

			rawQuery.forEach((customQueryItem: any, index: number) => {
				if (typeof customQueryItem === 'object' && customQueryItem.FindImage) {
					if (uniqueIdArray[findRawCounter] && uniqueIdArray[findRawCounter].length && (!dataResult[index].FindImage || (customQueryItem.FindImage.blobs === true))) {
						tempPages = tempPages.concat(uniqueIdArray[findRawCounter].map(uniqueId => ({
							findImage: customQueryItem,
							uniqueId,
						})));
					}

					findRawCounter++;
				} else {
					if (typeof customQueryItem === 'object' && customQueryItem.FindVideo) {
						if (uniqueIdArray[findRawCounter] && uniqueIdArray[findRawCounter].length && (!dataResult[index].FindVideo || (customQueryItem.FindVideo.blobs === true))) {
							tempPages = tempPages.concat(uniqueIdArray[findRawCounter].map(uniqueId => ({
								findVideo: customQueryItem,
								uniqueId,
							})));
						}

						findRawCounter++;
					}
				}
			});

			setPages(tempPages);
			await fetchMoreRaw(1, tempPages);
		} catch (err) {
			setIsFetching(false);

			throw (err);
		}

		setIsFetching(false);
	};

	const store: CustomQueryProviderStore = {
		myCustomQueryResult,
		myImages,
		myVideos,
		isFetching,
		rawPerPage,
		pageOffset,
		userQuery,
		tabSelected,
	};

	const updateStore: CustomQueryUpdateProviderStore = {
		setPageOffset,
		fetchCustomData,
		fetchMoreRaw,
		setUserQuery,
		setTabSelected,
		resetStates,
		setMyCustomQueryResult,
	};

	return (
		<CustomQueryContext.Provider value={store}>
			<CustomQueryUpdateContext.Provider value={updateStore}>
				{children}
			</CustomQueryUpdateContext.Provider>
		</CustomQueryContext.Provider>
	);
};

export { CustomQueryProvider };
export const CustomQueryConsumer = CustomQueryContext.Consumer;

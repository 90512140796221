import { Box, Typography } from '@material-ui/core';
import React from 'react';
import style from './OperationCropBox.module.scss';
import { TextField } from '../../../components/TextField';

interface OperationCropBoxProps {
	data: unknown;
	updateOperation: (data: unknown) => void;
}

export interface OperationCropData {
	height: string;
	width: string;
	x: string;
	y: string;
}

const OperationCropBox: React.FC<OperationCropBoxProps> = ({ data, updateOperation }) => {
	const textData = data as OperationCropData;

	const onChangeHeight = (value: string): void => {
		updateOperation({ ...textData, height: value } as OperationCropData);
	};

	const onChangeWidth = (value: string): void => {
		updateOperation({ ...textData, width: value } as OperationCropData);
	};

	const onChangeX = (value: string): void => {
		updateOperation({ ...textData, x: value } as OperationCropData);
	};

	const onChangeY = (value: string): void => {
		updateOperation({ ...textData, y: value } as OperationCropData);
	};

	return (
		<Box className={style.operationCropBox}>
			<Box className={style.row}>
				<Box className={style.height}>
					<Typography className={style.text}>
						Height
					</Typography>
					<TextField placeholder="Integer..." value={textData.height} onChange={onChangeHeight} type={'number-integer'} />
				</Box>
				<Box className={style.width}>
					<Typography className={style.text}>
						Width
					</Typography>
					<TextField placeholder="Integer..." value={textData.width} onChange={onChangeWidth} type={'number-integer'} />
				</Box>
			</Box>
			<Box className={style.row}>
				<Box className={style.height}>
					<Typography className={style.text}>
						X
					</Typography>
					<TextField placeholder="Integer..." value={textData.x} onChange={onChangeX} type={'number-integer'} />
				</Box>
				<Box className={style.width}>
					<Typography className={style.text}>
						Y
					</Typography>
					<TextField placeholder="Integer..." value={textData.y} onChange={onChangeY} type={'number-integer'} />
				</Box>
			</Box>
		</Box>
	);
};

export default OperationCropBox;

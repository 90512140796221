import { Redirect, Route, Switch } from 'react-router-dom';
import ApertureDB from './pages/ApertureDB/ApertureDB';
import { Login } from './pages';
import React from 'react';
import { useRoutes } from './helpers/hooks';

const Navigation: React.FC = () => {
	const { routes } = useRoutes();

	return (
		<Switch>
			<Route exact path={routes.root}>
				<Redirect to={routes.status} />
			</Route>
			<Route exact key={routes.login} path={routes.login} component={Login} />
			<Route path={'/:path'}>
				<ApertureDB />
			</Route>
		</Switch>
	);
};

export default Navigation;

import './SectionEditor.scss';
import MonacoEditor from '@monaco-editor/react';
import React from 'react';

interface SectionEditorProps {
	readonly: boolean;
	body: string;
	onChange?: (value: string) => void;
	height?: string;
}

const SectionEditor: React.FC<SectionEditorProps> = ({ readonly, body, onChange, height }) => {
	const handleOnChange = (value: string | undefined) => {
		onChange && value && onChange(value);
	};

	return (
		<MonacoEditor
			language="json"
			value={body}
			onChange={handleOnChange}
			height={height || '100vh'}
			options={{
				minimap: { enabled: true },
				scrollBeyondLastLine: false,
				scrollbar: { vertical: 'auto', horizontal: 'auto', useShadows: false },
				wordWrap: 'on',
				wrappingIndent: 'same',
				theme: 'vs-light',
				readOnly: readonly,
				tabSize: 2,
			}}
			beforeMount={async (monaco) => {
				const response = await fetch('https://static.aperturedata.io/share/api_schema.json'); // your schema file path in the public folder
				const schema = await response.json();

				monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
					validate: true,
					schemas: [
						{
							uri: 'https://static.aperturedata.io/share/api_schema.json',
							fileMatch: ['*'],
							schema: schema,
						},
					],
				});
			}}
		/>
	);
};

export default SectionEditor;

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import style from './SimpleDialog.module.scss';

export interface SimpleDialogProps {
	open: boolean;
	onClose: () => void;
	onSelect: (value: string) => void;
	labels: string[];
}

const SimpleDialog: React.FC<SimpleDialogProps> = ({ onClose, onSelect, open, labels }) => {
	const handleClose = (): void => {
		onClose();
	};

	const handleListItemClick = (value: string): void => {
		onSelect(value);
	};

	return (
		<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className={style.dialogContainer}>
			<DialogTitle id="simple-dialog-title">Select filter</DialogTitle>
			<List className={style.listContainer}>
				{labels.map((label, index) => (
					<ListItem button onClick={(): void => handleListItemClick(label)} key={label + index}>
						<ListItemText primary={label} />
					</ListItem>
				))}
			</List>
		</Dialog>
	);
};

export default SimpleDialog;

import { ArrowLeft, ArrowRight, Close } from '@material-ui/icons';
import { Box, Button, IconButton, Modal, Tooltip } from '@material-ui/core';
import { getOptionLabelModalImage, getOptionModalImage, setOptionLabelModalImage, setOptionModalImage } from '../../services/LocalStorageService';
import { BoundingBoxEntities } from '../../interfaces/Image';
import { Button as ButtonComponent } from '../../components/Button';
import { CheckBox } from '../CheckBox';
import ImageBoundingBox from './ImageBoundingBox';
import ImageBoundingBoxPropertiesEdition from './ImageBoundingBoxPropertiesEdition';
import { ImageData } from './Image';
import ImageProperties from './ImageProperties';
import { randomBoundingBoxId } from '../../helpers/numbers';
import React from 'react';
import style from './ImageModal.module.scss';

interface ImageModalProps {
	image: ImageData;
	isOpen: boolean;
	onClose: () => void;
	onPreviousClick: () => void;
	onNextClick: () => void;
	isNextButtonDisabled?: boolean;
	isPreviousButtonDisabled?: boolean;
}

const ImageModal: React.FC<ImageModalProps> = ({ image, isOpen, onClose, onPreviousClick, onNextClick, isNextButtonDisabled, isPreviousButtonDisabled }) => {
	const [imageNaturalSize, setImageNaturalSize] = React.useState<HTMLImageElement | null>(null);
	const [isClosed, setIsClosed] = React.useState(false);
	const [selectedBoundingBox, setSelectedBoundingBox] = React.useState<BoundingBoxEntities | null>(null);
	const [showBoundingBox, setShowBoundingBox] = React.useState(true);
	const [showBoundingBoxLabels, setShowBoundingBoxLabels] = React.useState(true);
	const entities = image.boundingBox?.entities[0] || [];

	const addBoundingBox = (): void => {
		const newBoundingBox = {
			'_coordinates': {
				'height': 100,
				'width': 100,
				'x': 0,
				'y': 0,
			},
			'_label': 'Label',
			'_uniqueid': randomBoundingBoxId(),
		};

		setSelectedBoundingBox(newBoundingBox);
	};

	React.useEffect(
		() => {
			const handleOptionStorage = (): void => {
				const labelOption = getOptionLabelModalImage();
				const boundingBoxOption = getOptionModalImage();

				if (labelOption) {
					setShowBoundingBoxLabels(JSON.parse(labelOption));
				}

				if (boundingBoxOption) {
					setShowBoundingBox(JSON.parse(boundingBoxOption));
				}
			};

			handleOptionStorage();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isOpen]);

	const handleLabelOption = (value: boolean): void => {
		setShowBoundingBoxLabels(value);
		setOptionLabelModalImage(value);
	};

	const handleOptionBoundingBox = (value: boolean): void => {
		setShowBoundingBox(value);
		setOptionModalImage(value);
	};

	// React.useEffect(() => setSelectedBoundingBox(null), [entities]);

	const handleClose = (): void => {
		setIsClosed(true);
		setTimeout(() => {
			onClose();
			setIsClosed(false);
		}, 100);
	};

	const keyPress = React.useCallback(
		(e) => {
			if (e.key === 'ArrowLeft') {
				if (!isPreviousButtonDisabled) {
					onPreviousClick();
				}
			}

			if (e.key === 'ArrowRight') {
				if (!isNextButtonDisabled) {
					onNextClick();
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[image, isNextButtonDisabled, isPreviousButtonDisabled, onNextClick, onPreviousClick]);

	React.useEffect(() => {
		document.addEventListener('keydown', keyPress);

		return (): void => document.removeEventListener('keydown', keyPress);

	}, [keyPress]);

	return (
		<Modal
			open={isOpen}
			onClose={handleClose}
		>
			<div className={style.imageModal}>
				<IconButton onClick={handleClose} className={style.closeIcon}>
					<Close className={style.svgIcon} />
				</IconButton>
				<div className={style.titleModal}>
					{
						image.title && <h1>{image.title}</h1>
					}
				</div>
				<div className={style.contentModal}>
					<div className={style.contentLeft}>
						{selectedBoundingBox?._uniqueid
							? <ImageBoundingBoxPropertiesEdition
								isClosed={isClosed}
								selectedBoundingBox={selectedBoundingBox}
								resetSelectedBoundingBox={(): void => setSelectedBoundingBox(null)}
								imageNaturalSize={imageNaturalSize}
								imageUniqueid={image.uniqueId}
							/>
							: <>
								<ImageProperties image={image} />
								<Box style={{ backgroundColor: '#f8f8f8', border: 'none', display: 'flex', flexDirection: 'column' }}>
									{
										entities.length > 0 &&
										<>
											<CheckBox
												isChecked={showBoundingBox}
												onChange={(value: boolean): void => {
													handleOptionBoundingBox(value);
												}}
												label={'Display Bounding Boxes'}
											/>
											<CheckBox
												isChecked={showBoundingBoxLabels && showBoundingBox}
												onChange={(value: boolean): void => {
													handleLabelOption(value);
												}}
												label={'Display labels'}
											/>
										</>
									}
									<ButtonComponent
										title={'Add New Bounding Box'}
										onClick={addBoundingBox}
										isSecondary={true}
									/>
									<div style={{ height: 10 }} />
									<Tooltip title={'No descriptors found associated to this image'} disableHoverListener={image.hasSimilarImages}>
										<span>
											<ButtonComponent
												isDisabled={!image.hasSimilarImages}
												title={'Search Similar Images'}
												onClick={() => { window.open(`similarity-search?_uniqueId=${image.uniqueId}`, '_blank', 'noreferrer'); }}
												isSecondary={true}
											/>
										</span>
									</Tooltip>
								</Box>
							</>
						}
					</div>
					<div className={style.contentRight}>
						<ImageBoundingBox
							image={image}
							showBoundingBox={showBoundingBox}
							showBoundingBoxLabels={showBoundingBoxLabels}
							setSelectedBoundingBox={setSelectedBoundingBox}
							imageNaturalSize={imageNaturalSize}
							setImageNaturalSize={setImageNaturalSize}
						/>
					</div>
				</div>
				<div className={style.bottomNavigation}>
					<div style={{ visibility: !isPreviousButtonDisabled ? 'visible' : 'hidden' }}>
						<Button color="primary" classes={{ label: style.btnText }} startIcon={<ArrowLeft />} onClick={onPreviousClick} disabled={isPreviousButtonDisabled}>
							Previous
						</Button>
					</div>
					<div style={{ visibility: !isNextButtonDisabled ? 'visible' : 'hidden' }}>
						<Button color="primary" classes={{ label: style.btnText }} endIcon={<ArrowRight />} onClick={onNextClick} disabled={isNextButtonDisabled}>
							Next
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ImageModal;

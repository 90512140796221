import { Box, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PropertyBoolean from './PropertyTypeBoxes/PropertyBooleanBox';
import PropertyDateBox from './PropertyTypeBoxes/PropertyDateBox';
import PropertyNumberBox from './PropertyTypeBoxes/PropertyNumberBox';
import PropertyNumberFloatBox from './PropertyTypeBoxes/PropertyNumberFloatBox';
import PropertyNumberIntegerBox from './PropertyTypeBoxes/PropertyNumberIntegerBox';
import PropertyTextBox from './PropertyTypeBoxes/PropertyTextBox';
import React from 'react';
import style from './PropertyBox.module.scss';

export type PropertyBoxType = 'BOOLEAN' | 'TEXT' | 'DATE' | 'DATETIME' | 'NUMBER-INTEGER' | 'NUMBER-FLOAT' | 'NUMBER';

interface PropertyBoxProps {
	title: string;
	type: PropertyBoxType;
	data: unknown;
	removePropertyFilter: () => void;
	updatePropertyFilter: (data: unknown) => void;
}

const getPropertyTypeBox = (type: PropertyBoxType, data: unknown, updatePropertyFilter: (data: unknown) => void): JSX.Element => {
	switch (type) {
		case 'BOOLEAN':
			return <PropertyBoolean data={data} updatePropertyFilter={updatePropertyFilter} />;
		case 'TEXT':
			return <PropertyTextBox data={data} updatePropertyFilter={updatePropertyFilter} />;
		case 'DATE':
			return <PropertyDateBox data={data} updatePropertyFilter={updatePropertyFilter} />;
		case 'DATETIME':
			return <PropertyDateBox data={data} updatePropertyFilter={updatePropertyFilter} />;
		case 'NUMBER-INTEGER':
			return <PropertyNumberIntegerBox data={data} updatePropertyFilter={updatePropertyFilter} />;
		case 'NUMBER-FLOAT':
			return <PropertyNumberFloatBox data={data} updatePropertyFilter={updatePropertyFilter} />;
		case 'NUMBER':
			return <PropertyNumberBox data={data} updatePropertyFilter={updatePropertyFilter} />;
		default:
			return <div>TYPE NOT SUPPORTED</div>;
	}
};

const getHelpText = (type: PropertyBoxType): JSX.Element | null => {
	switch (type) {
		case 'BOOLEAN':
			return null;
		case 'TEXT':
			return null;
		case 'DATE':
			return null;
		case 'DATETIME':
			return null;
		case 'NUMBER-INTEGER':
			return null;
		case 'NUMBER-FLOAT':
			return null;
		case 'NUMBER':
			return null;
		default:
			return null;
	}
};

const PropertyBox: React.FC<PropertyBoxProps> = ({ title, type, data, removePropertyFilter, updatePropertyFilter }) => {
	return (
		<Box className={style.propertyBox}>
			<IconButton aria-label="delete" className={style.delete} onClick={removePropertyFilter}>
				<DeleteIcon fontSize="small" />
			</IconButton>
			<Box className={style.top}>
				<Typography className={style.title}>
					{title}
				</Typography>
				{
					getHelpText(type)
				}
			</Box>
			{
				getPropertyTypeBox(type, data, updatePropertyFilter)
			}
		</Box>
	);
};

export default PropertyBox;

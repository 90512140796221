import { Box } from '@material-ui/core';
import ContentService from '../../../services/ContentService';
import React from 'react';
import { SelectBox } from '../../../components/SelectBox';
import style from './PropertyTextBox.module.scss';
import { TextField } from '../../../components/TextField';

interface PropertyTextBoxProps {
	data: unknown;
	updatePropertyFilter: (data: unknown) => void;
}

type TextOperations = 'GREATER_THAN' | 'GREATER_OR_EQUAL_THAN' | 'LESS_THAN' | 'LESS_OR_EQUAL_THAN' | 'EQUAL' | 'NOT_EQUAL';

export interface PropertyTextData {
	operation: TextOperations;
	value: string;
}

const selectBoxItems = ContentService.getPropertiesOperations();

const PropertyTextBox: React.FC<PropertyTextBoxProps> = ({ data, updatePropertyFilter }) => {
	const textData = data as PropertyTextData;

	const onChangeOperation = (value: unknown): void => {
		const operation = value as string;

		updatePropertyFilter({ ...textData, operation } as PropertyTextData);
	};

	const onChangeValue = (value: string): void => {
		updatePropertyFilter({ ...textData, value } as PropertyTextData);
	};

	return (
		<Box className={style.propertyTextBox}>
			<Box className={style.selected}>
				<SelectBox selectedValue={textData.operation} onChange={onChangeOperation} items={selectBoxItems} />
			</Box>
			<Box className={style.text}>
				<TextField placeholder="String value..." value={textData.value} onChange={onChangeValue} />
			</Box>
		</Box>
	);
};

export default PropertyTextBox;

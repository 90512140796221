import { Box } from '@material-ui/core';
import ContentService from '../../../services/ContentService';
import React from 'react';
import { SelectBox } from '../../../components/SelectBox';
import style from './PropertyNumberFloatBox.module.scss';
import { TextField } from '../../../components/TextField';

interface PropertyNumberFloatProps {
	data: unknown;
	updatePropertyFilter: (data: unknown) => void;
}

type NumberOperations = 'GREATER_THAN' | 'GREATER_OR_EQUAL_THAN' | 'LESS_THAN' | 'LESS_OR_EQUAL_THAN' | 'EQUAL' | 'NOT_EQUAL';

export interface PropertyNumberFloatData {
	operation: NumberOperations;
	value: string;
}

const selectBoxItems = ContentService.getPropertiesOperations();

const PropertyNumberFloatBox: React.FC<PropertyNumberFloatProps> = ({ data, updatePropertyFilter }) => {
	const textData = data as PropertyNumberFloatData;

	const onChangeOperation = (value: unknown): void => {
		const operation = value as string;

		updatePropertyFilter({ ...textData, operation } as PropertyNumberFloatData);
	};

	const onChangeValue = (value: string): void => {
		updatePropertyFilter({ ...textData, value } as PropertyNumberFloatData);
	};

	return (
		<Box className={style.propertyNumberFloatBox}>
			<Box className={style.selected}>
				<SelectBox selectedValue={textData.operation} onChange={onChangeOperation} items={selectBoxItems} />
			</Box>
			<Box className={style.text}>
				<TextField placeholder="Float value..." value={textData.value} onChange={onChangeValue} type={'number-float'} />
			</Box>
		</Box>
	);
};

export default PropertyNumberFloatBox;

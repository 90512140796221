import { Collapse, IconButton, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AuthenticationContext } from '../../contexts/AuthenticationContext';
import { Button } from '../../components/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import logo from '../../assets/logo-horizontal-color.svg';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { SectionTitle } from '../../components/SectionTitle';
import style from './Login.module.scss';
import { TextField } from '../../components/TextField';
import useLogin from './Hook/useLogin';
import visibility from '../../assets/visibility.png';

const Login: React.FC = () => {
	const { username, password, ip, port, isOpen, setUsername, setPassword, setIp, setPort, setIsOpen, login, error, setError, isLoading } = useLogin();
	const [showPassword, setShowPassword] = React.useState<boolean>(false);
	const { isAuthentication } = React.useContext(AuthenticationContext);

	const usernameHandler = (value: string): void => {
		setUsername(value);
	};

	const passwordHandler = (value: string): void => {
		setPassword(value);
	};

	const ipHandler = (value: string): void => {
		setIp(value);
	};

	const portHandler = (value: string): void => {
		setPort(value);
	};

	const togglePasswordHandler = (): void => {
		setShowPassword(!showPassword);
	};

	React.useEffect(() => {
		const listener = (event: KeyboardEvent): void => {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				event.preventDefault();

				if (!(!username || !password || (isOpen && (!ip || !port)))) {
					login();
				}
			}
		};

		document.addEventListener('keydown', listener);

		return (): void => {
			document.removeEventListener('keydown', listener);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [login]);

	const handleErrorClose = (event?: React.SyntheticEvent, reason?: string): void => {
		if (reason === 'clickaway') {
			return;
		}

		setError('');
	};

	if (isAuthentication) {
		return (
			<Redirect to={'/status'} />
		);
	}

	return (
		<div className={style.login}>
			<div className={style.container}>
				<div className={'app-bar__title-container'}>
					<img className={'app-bar__title'} src={logo} alt="ApertureDB" />
				</div>
				<div className={style.title}><SectionTitle title='Login' /></div>
				<div className={style.text}>
					<TextField value={username} onChange={usernameHandler} label={'Username'} required hideRequired />
				</div>
				<div className={style.text}>
					<TextField value={password} autocomplete="current-password" onChange={passwordHandler} label={'Password'} type={showPassword ? undefined : 'password'} iconInput={<img src={visibility} alt='Visibility' onClick={togglePasswordHandler} />} required hideRequired />
				</div>
				<div className={style.ipPort}>
					<div className={style.divider} />
					<Typography className={style.title} >{'Enable IP & Port'}</Typography>
					<IconButton className={style.button} aria-label="expand row" size="small" onClick={setIsOpen}>
						{isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
					<div className={style.divider}/>
				</div>
				<Collapse in={isOpen} timeout="auto" unmountOnExit>
					<div className={style.text}>
						<TextField value={ip} onChange={ipHandler} label={'IP'} />
					</div>
					<div className={style.text}>
						<TextField value={port} onChange={portHandler} label={'Port'} />
					</div>
				</Collapse>
				<div className={style.button}>
					<Button isDisabled={!username || !password || (isOpen && (!ip || !port))} title='LOGIN' onClick={(): void => login()} isLoading={isLoading} />
				</div>
			</div>
			<Snackbar open={!!error} autoHideDuration={6000} onClose={handleErrorClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<Alert onClose={handleErrorClose} severity="error">
					{error}
				</Alert>
			</Snackbar>
		</div>
	);
};

export default Login;

import { Box, Switch, Typography } from '@material-ui/core';
import React from 'react';
import style from './PropertyBooleanBox.module.scss';

interface PropertyBooleanProps {
	data: unknown;
	updatePropertyFilter: (data: unknown) => void;
}

export interface PropertyBooleanData {
	value: boolean;
}

const PropertyBooleanBox: React.FC<PropertyBooleanProps> = ({ data, updatePropertyFilter }) => {
	const booleanData = data as PropertyBooleanData;

	const onChangeValue = (value: boolean): void => {
		updatePropertyFilter({ ...booleanData, value } as PropertyBooleanData);
	};

	return (
		<Box className={style.propertyBoolean}>
			<Typography className={style.label}>False</Typography>
			<Switch
				checked={booleanData.value}
				onChange={(event: React.ChangeEvent<HTMLInputElement>): void => onChangeValue(event.target.checked)}
				color="primary"
			/>
			<Typography className={style.label}>True</Typography>
		</Box>
	);
};

export default PropertyBooleanBox;

interface RoutesStore {
	routes: { [KEY: string]: string };
}

const useRoutes: () => RoutesStore = () => {
	const routes = {
		accessControl: '/access-control',
		advancedSearch: '/advanced-search',
		customQuery: '/custom-query',
		imageSearch: '/image-search',
		login: '/login',
		root: '/',
		similaritySearch: '/similarity-search',
		status: '/status',
		videoSearch: '/video-search',
	};

	return { routes };

};

export default useRoutes;

import React, { ReactNode } from 'react';
import { VideoQuery, VideoResult } from '../interfaces/Video';
import VideoQueryService from '../services/VideoQueryService';
import { VideoResponse } from '../interfaces/Query';

export interface VideoQueryProviderStore {
	myVideoQueryResult: VideoResult[];
	myVideos: VideoResponse | undefined;
	isFetching: boolean;
	videosPerPage: number;
	pageOffset: number;
	userQuery: string;
}

export interface VideoQueryUpdateProviderStore {
	fetchVideoMetadata: (myVideoQuery: VideoQuery) => Promise<void>;
	setPageOffset: (offset: number) => void;
	fetchMoreVideos: (
		offset: number,
		queryProps?: VideoQuery | undefined,
		uniqueIdArray?: number[]
	) => void;
	setUserQuery: (query: string) => void;
	resetStates: () => void;
	setMyVideoQueryResult: (video: VideoResult[]) => void;
}

export const VideoQueryContext = React.createContext(
	{} as VideoQueryProviderStore
);
export const VideoQueryUpdateContext = React.createContext(
	{} as VideoQueryUpdateProviderStore
);

const VideoQueryProvider = ({
	children,
}: {
	children: ReactNode;
}): JSX.Element => {
	const [myVideoQueryResult, setMyVideoQueryResult] = React.useState<
		VideoResult[]
	>([]);
	const [myVideos, setMyVideos] = React.useState<VideoResponse | undefined>(
		undefined
	);
	const [isFetching, setIsFetching] = React.useState<boolean>(false);
	const [pageOffset, setPageOffset] = React.useState<number>(1);
	const [userQuery, setUserQuery] = React.useState<string>('[]');
	const [videoQuery, setVideoQuery] = React.useState<VideoQuery | undefined>(
		undefined
	);
	const [uniqueIds, setUniqueIds] = React.useState<number[]>([]);
	const videosPerPage = 10;

	const resetStates = (): void => {
		setMyVideoQueryResult([]);
		setMyVideos(undefined);
		setIsFetching(false);
		setPageOffset(1);
		setUserQuery('[]');
		setVideoQuery(undefined);
		setUniqueIds([]);
	};

	const fetchVideos = async (query: VideoQuery[]): Promise<void> => {
		setIsFetching(true);

		try {
			const videos = await VideoQueryService.getVideos(query);

			setMyVideos(videos);
		} catch (err) {
			Promise.reject((err as unknown as { name: string; message: string }).message);
		}

		setIsFetching(false);
		Promise.resolve();
	};

	const fetchMoreVideos = async (
		offset: number,
		queryProps: VideoQuery | undefined = videoQuery,
		uniqueIdArray: number[] = uniqueIds
	): Promise<void> => {
		if (queryProps && uniqueIdArray.length) {
			const query: VideoQuery[] =
				VideoQueryService.getIndividualVideoQueriesForBlob(
					queryProps,
					uniqueIdArray.slice(
						(offset - 1) * videosPerPage,
						(offset - 1) * videosPerPage + videosPerPage
					)
				);

			await fetchVideos(query);
		}
	};

	const fetchVideoMetadata = async (
		myVideoQuery: VideoQuery
	): Promise<void> => {
		setIsFetching(true);
		setVideoQuery(myVideoQuery);
		setMyVideos(undefined);
		setMyVideoQueryResult([]);
		setPageOffset(1);
		setUniqueIds([]);
		const query = myVideoQuery.FindBoundingBox ? [{ FindVideo: myVideoQuery.FindVideo }, { FindBoundingBox: myVideoQuery.FindBoundingBox }] : [myVideoQuery];

		try {
			const videoMetadata = await VideoQueryService.getVideoMetadata(
				JSON.parse(JSON.stringify(query))
			);

			setMyVideoQueryResult(videoMetadata);

			if (videoMetadata.length) {
				const uniqueIdArray: number[] =
					VideoQueryService.getUniqueIdArray(videoMetadata);

				setUniqueIds(uniqueIdArray);
				await fetchMoreVideos(1, myVideoQuery, uniqueIdArray);
			}
		} catch (err) {
			setIsFetching(false);

			throw err;
		}

		setIsFetching(false);
	};

	const store: VideoQueryProviderStore = {
		myVideoQueryResult,
		myVideos,
		isFetching,
		videosPerPage,
		pageOffset,
		userQuery,
	};

	const updateStore: VideoQueryUpdateProviderStore = {
		fetchVideoMetadata,
		setPageOffset,
		fetchMoreVideos,
		setUserQuery,
		resetStates,
		setMyVideoQueryResult,
	};

	return (
		<VideoQueryContext.Provider value={store}>
			<VideoQueryUpdateContext.Provider value={updateStore}>
				{children}
			</VideoQueryUpdateContext.Provider>
		</VideoQueryContext.Provider>
	);
};

export { VideoQueryProvider };
export const VideoQueryConsumer = VideoQueryContext.Consumer;

/* eslint-disable @typescript-eslint/no-explicit-any */
import Image, { ImageData } from '../ImageGallery/Image';
import { ImageResponse, VideoResponse } from '../../interfaces/Query';
import Video, { VideoData } from '../VideoGallery/Video';
import { CircularProgress } from '@material-ui/core';
import ImageModal from '../ImageGallery/ImageModal';
import { Pagination } from '../Pagination';
import React from 'react';
import style from './ContentGallery.module.scss';
import { useContentGallery } from './useContentGallery';
import VideoModal from '../VideoGallery/VideoModal';

interface GalleryResultsProps {
	rawVideos: VideoResponse | undefined;
	rawImages: ImageResponse | undefined;
	isFetching: boolean;
	pageOffset?: number;
	rawPerPage?: number;
	total?: number;
	onPageChange?: (offset: number) => void;
}

interface Content {
	type: 'image' | 'video';
	value: ImageData | VideoData;
}

const ContentGallery: React.FC<GalleryResultsProps> = ({
	rawVideos,
	rawImages,
	pageOffset,
	isFetching,
	rawPerPage,
	total,
	onPageChange,
}) => {
	const {
		validationStore: { isModalVideoOpen, isModalImageOpen, isFirstContent, isLastContent },
		paginationStore: { totalPages },
		handlersStore: {
			handlePageChange,
			handleContentImageClick,
			handleContentVideoClick,
			handleModalClose,
			handleNextContent,
			handlePreviousContent,
		},
		dataStore: { content, contentToDisplay },
	} = useContentGallery(
		rawVideos,
		rawImages,
		rawPerPage,
		total,
		onPageChange
	);

	return (
		<div
			className={`${style.contentGallery}  ${isFetching ? style.contentGalleryProgressLoading : ''}`}
		>
			{isFetching ? (
				<div style={{ display: 'flex' }}>
					<CircularProgress />
				</div>
			) : (
				<>
					<div className={style.headerGallery}>
						<h5
							className={style.titleGallery}
						>
							{`${(rawPerPage || 0) * ((pageOffset || 1) - 1)} - ${(rawPerPage || 0) * ((pageOffset || 1) - 1) + content.length} out of ${total}`}
						</h5>
						<Pagination
							totalPages={totalPages}
							currentPage={pageOffset || 1}
							onPageChange={handlePageChange}
						/>
					</div>
					<div className={style.thumbnailContainer}>
						{content.map((item: Content) => {
							if (item.type === 'image') {
								return <Image key={item.value.uniqueId} image={item.value as ImageData} onImageClick={handleContentImageClick} />;
							}

							return <Video key={item.value.uniqueId} video={item.value as VideoData} onVideoClick={handleContentVideoClick} />;
						})}
					</div>
					{content.filter(item => item.type === 'image').map((item: Content) => (<ImageModal
						key={item.value.uniqueId}
						isOpen={isModalImageOpen && (contentToDisplay as ImageData).uniqueId === item.value.uniqueId}
						onClose={handleModalClose}
						image={contentToDisplay as ImageData}
						onNextClick={handleNextContent}
						onPreviousClick={handlePreviousContent}
						isNextButtonDisabled={isLastContent}
						isPreviousButtonDisabled={isFirstContent}
					/>))}
					{content.filter(item => item.type === 'video').map((item: Content) => (<VideoModal
						key={item.value.uniqueId}
						isOpen={isModalVideoOpen && (contentToDisplay as VideoData).uniqueId === item.value.uniqueId}
						onClose={handleModalClose}
						video={contentToDisplay as VideoData}
						onNextClick={handleNextContent}
						onPreviousClick={handlePreviousContent}
						isNextButtonDisabled={isLastContent}
						isPreviousButtonDisabled={isFirstContent}
					/>))}
				</>
			)}
		</div>
	);
};

export default ContentGallery;

import { Box, IconButton, Modal } from '@material-ui/core';
import { Button } from '../Button';
import { Close } from '@material-ui/icons';
import { QuerySaved } from '../../interfaces/SaveQueries';
import React from 'react';
import SaveIcon from '@material-ui/icons/Save';
import styles from './ModalSaveQuery.module.scss';
import TextField from '../TextField/TextField';

interface ModalProps {
	isOpen: boolean;
	handleSave: (value: string, id: number) => void;
	handleClose: () => void;
	queryToSave: QuerySaved;
}

const ModalSaveQuery: React.FC<ModalProps> = ({
	isOpen,
	handleClose,
	handleSave,
	queryToSave,
}) => {
	const [name, setName] = React.useState<string>('');
	const [errorText, setErrorText] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (queryToSave.name !== '') {

			setName(queryToSave.name);
		}

	}, [queryToSave.name]);

	const handleFinishSave = () => {
		if (name !== '' && name.length >= 3) {
			setErrorText(false);
			handleSave(name, queryToSave.id);
			handleClose();
			setName('');
		} else {
			setErrorText(true);
		}
	};

	const handleCloseModal = () => {
		setErrorText(false);
		setName('');
		handleClose();
	};

	return (
		<Modal open={isOpen}>
			<div className={styles.modalBody}>
				<div className={styles.headModal}>
					<div className={styles.title}>
						<p>Choose Name</p>
					</div>
					<IconButton onClick={handleCloseModal}>
						<Close />
					</IconButton>
				</div>
				<div className={styles.inputContainer}>
					<Box className={styles.resultBoxLimit}>
						<div className={styles.resultBoxLimitText}>
							<TextField
								required
								placeholder="Insert name..."
								value={name}
								onChange={(value) => setName(value)}
								type={'text'}
							/>
							{errorText && (
								<div className={styles.errorTextModal}>
									Must have at least 3 characters
								</div>
							)}
						</div>
					</Box>
				</div>
				<div className={styles.btnContainer}>
					<Button
						title="Save"
						onClick={handleFinishSave}
						loadingTitle="Saving..."
						startIcon={<SaveIcon />}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default ModalSaveQuery;

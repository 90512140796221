import { Link } from 'react-router-dom';
import React from 'react';
import { useRoutes } from '../../helpers/hooks';

const NotFound: React.FC = () => {
	const { routes } = useRoutes();

	return (
		<div style={{ textAlign: 'center' }}>
			<h1>Ups! Not Found</h1>
			<Link to={routes.status}>Go back to start</Link>
		</div>
	);
};

export default NotFound;

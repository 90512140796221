import { ConnectionClassProperties, ConnectionClassViewModel, ConnectionObject, DataBaseStatus, EntityClassProperties, EntityClassViewModel, EntityObject } from '../interfaces/DataBaseStatus';
import { CustomResponse } from '../interfaces/Query';
import { getDataBaseStatus } from '../api/StatusRepository';
import { PropertyBoxType } from '../pages/ImageSearch/PropertyBox';

const getDataBaseStatusInfo = async (): Promise<DataBaseStatus[]> => {
	const response = await getDataBaseStatus();

	const responseData = response.data as CustomResponse;

	return JSON.parse(JSON.stringify(responseData.json)) as DataBaseStatus[];
};

const getPropertiesByClass = (entityObject: EntityObject | EntityObject[]): EntityClassProperties[] => {
	const objects = Array.isArray(entityObject) ? entityObject : [entityObject];
	const propertiesByClass: EntityClassProperties[] = [];

	objects.forEach(obj => {
		if (obj === null || obj.properties === null) {
			return [];
		}

		const keys = Object.keys(obj.properties);

		keys.forEach(key => {
			propertiesByClass.push({ name: key, type: obj.properties![key][2], isIndex: obj.properties![key][1] });
		});
	});

	return propertiesByClass;
};

const getPropertiesByClassConn = (connectionObject: ConnectionObject): ConnectionClassProperties[] => {
	if (connectionObject === null || connectionObject.properties === null) {
		return [];
	}

	const propertiesByClass: ConnectionClassProperties[] = [];
	const keys = Object.keys(connectionObject.properties);

	keys.forEach(key => {
		if (connectionObject.properties !== null) {
			propertiesByClass.push({ name: key, type: connectionObject.properties[key][2], isIndex: connectionObject.properties[key][1] });
		}
	});

	return propertiesByClass;
};

const getTotalNumberOfEntitiesByClass = (entityObject: EntityObject | EntityObject[]): number => {
	const objects = Array.isArray(entityObject) ? entityObject : [entityObject];
	let total = 0;

	objects.forEach(obj => {
		if (obj !== null && typeof obj === 'object' && typeof obj.matched === 'number') {
			total += obj.matched;
		}
	});

	return total;
};

const getTotalNumberOfConnectionByClass = (connectionObject: ConnectionObject): number => {
	return connectionObject.matched;
};

const getHighestAmount = (entityClassesViewModel: EntityClassViewModel[]): number => {
	let highestAmount = 0;

	entityClassesViewModel.forEach(entityClassViewModel => {
		if (entityClassViewModel.totalAmount > highestAmount) {
			highestAmount = entityClassViewModel.totalAmount;
		}
	});

	return highestAmount;
};

const getHighestAmountConn = (connectionClassesViewModel: ConnectionClassViewModel[]): number => {
	let highestAmount = 0;

	connectionClassesViewModel.forEach(connectionClassViewModel => {
		if (connectionClassViewModel.totalAmount > highestAmount) {
			highestAmount = connectionClassViewModel.totalAmount;
		}
	});

	return highestAmount;
};

const formatClassNames = (className: string): string => {
	if (className.charAt(0) === '_') {
		return className.slice(1);
	}

	return className;
};

const filterClassPropertiesByType = (property: EntityClassProperties): boolean => {
	if (property.type === 'MaybeMixed' || property.type === 'None') {
		return false;
	}

	return true;
};

const getPropertyBoxType = (propertyType: string): PropertyBoxType => {
	switch (propertyType) {
		case 'Integer':
			return 'NUMBER-INTEGER';
		case 'Float':
			return 'NUMBER-FLOAT';
		case 'Time':
			return 'DATE';
		case 'Datetime':
			return 'DATETIME';
		case 'Boolean':
			return 'BOOLEAN';
		case 'Number':
			return 'NUMBER';
		case 'String':
		default:
			return 'TEXT';
	}
};

export default {
	getDataBaseStatusInfo,
	getPropertiesByClass,
	getTotalNumberOfEntitiesByClass,
	formatClassNames,
	getHighestAmount,
	filterClassPropertiesByType,
	getPropertyBoxType,
	getPropertiesByClassConn,
	getTotalNumberOfConnectionByClass,
	getHighestAmountConn,
};

import { RoleModel, RolePermissionsModel } from '../../interfaces/AccessControl';
import AccessControlService from '../../services/AccessControlService';
import AddIcon from '@material-ui/icons/Add';
import { AuthenticationContext } from '../../contexts/AuthenticationContext';
import { Button } from '../../components/Button';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/EditOutlined';
import { IconButton } from '@material-ui/core';
import { ModalUpdateContext } from '../../contexts/ModalContext';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import { RefreshButton } from '../../components/RefreshButton';
import { SectionTitle } from '../../components/SectionTitle';
import style from './RolesTable.module.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { useAccessControl } from './Hooks/useAccessControl';

const Row = (props: { row: RolePermissionsModel; roleNameType: string; showColor: boolean }): JSX.Element => {
	const { row, roleNameType, showColor } = props;

	return (
		<TableRow className={showColor ? `${style.rowWithColor}` : `${style.rowWithOutColor}`}>
			<TableCell>
				<Typography component="span" className={style.entityName}>
					{AccessControlService.getRoleTypeName(roleNameType)}
				</Typography>
			</TableCell>
			<TableCell align="center" className={style.text}>
				{row.read && <CheckIcon fontSize={'small'} />}
			</TableCell>
			<TableCell align="center" className={style.text}>
				{row.create && <CheckIcon fontSize={'small'} />}
			</TableCell>
			<TableCell align="center" className={style.text}>
				{row.update && <CheckIcon fontSize={'small'} />}
			</TableCell>
			<TableCell align="center" className={style.text}>
				{row.delete && <CheckIcon fontSize={'small'} />}
			</TableCell>
		</TableRow>
	);
};

const RolesTable: React.FC = () => {
	const { roles, fetchValues, isFetching } = useAccessControl();
	const { setIsNewRoleModalOpen, setIsEditRoleModalOpen, setRoleToEdit } = React.useContext(ModalUpdateContext);
	const { myUser } = React.useContext(AuthenticationContext);

	return (
		<div className={style.rolesTable}>
			<div className={style.headerContainer}>
				<div className={style.title}>
					<SectionTitle title="Roles" />
					<RefreshButton
						isLoading={isFetching}
						onClick={fetchValues}
					/>
				</div>
				{
					myUser?.permissions.access_control.create && (
						<div className={style.actions}>
							<Button
								title="Add Role"
								onClick={(): void => setIsNewRoleModalOpen(true)}
								isSecondary={true}
								startIcon={<AddIcon />}
							/>
						</div>
					)
				}
			</div>
			<div className={style.tableContainer}>
				{
					roles ? (
						Object
							.entries(roles.GetRoles)
							.sort((a, b) => {
								if (a[0].toLocaleLowerCase() === b[0].toLocaleLowerCase()) {
									return 0;
								}

								if (a[0].toLocaleLowerCase() === 'admin') {
									return -1;
								}

								if (b[0].toLocaleLowerCase() === 'admin') {
									return 1;
								}

								return a[0].localeCompare(b[0]);
							})
							.map(([rolename, values]) => rolename !== 'status' && rolename !== 'info' && (
								<TableContainer component={Paper} className={style.table} elevation={0} key={rolename}>
									<Table size="small" aria-label="roles">
										<colgroup>
											<col style={{ width: '90%' }} />
											<col style={{ width: '10%' }} />
										</colgroup>
										<TableHead className={style.tableHeader}>
											<TableRow>
												<TableCell scope="row" className={style.text}> {rolename} </TableCell>
												<TableCell align={'right'} className={style.text} style={{ paddingTop: 0, paddingBottom: 0 }}>
													{
														rolename !== 'admin' && myUser?.permissions.access_control.update && (
															<IconButton className={style.arrowButton} aria-label="expand row" size="small" onClick={(): void => { setIsEditRoleModalOpen(true); setRoleToEdit({ name: rolename, ...(values as RoleModel) }); }}>
																<EditIcon style={{ fontSize: 18 }} />
															</IconButton>
														)
													}
												</TableCell>
											</TableRow>
										</TableHead>
									</Table>
									<Table aria-label="collapsible table">
										<colgroup>
											<col style={{ width: 10 }} />
											<col />
											<col style={{ width: 10 }} />
										</colgroup>
										<TableBody className={style.body}>
											<TableRow>
												<TableCell />
												<TableCell className={'withoutBorder'}>
													<Table size="small" aria-label="roles">
														<colgroup>
															<col style={{ width: '20%' }} />
															<col style={{ width: '20%' }} />
															<col style={{ width: '20%' }} />
															<col style={{ width: '20%' }} />
															<col style={{ width: '20%' }} />
														</colgroup>
														<TableHead>
															<TableRow>
																<TableCell colSpan={7} style={{ height: 10 }} />
															</TableRow>
														</TableHead>
														<TableBody className={style.header}>
															<TableRow>
																<TableCell scope="row" className={style.headerText}></TableCell>
																<TableCell scope="row" align="center" className={style.headerText}>View</TableCell>
																<TableCell scope="row" align="center" className={style.headerText}>Create</TableCell>
																<TableCell scope="row" align="center" className={style.headerText}>Update</TableCell>
																<TableCell scope="row" align="center" className={style.headerText}>Delete</TableCell>
															</TableRow>
														</TableBody>
													</Table>
													<Table size="small" aria-label="collapsible table">
														<colgroup>
															<col style={{ width: '20%' }} />
															<col style={{ width: '20%' }} />
															<col style={{ width: '20%' }} />
															<col style={{ width: '20%' }} />
															<col style={{ width: '20%' }} />
														</colgroup>
														<TableBody>
															{
																Object.entries(values as RoleModel).map(([roleNameType, values], index) => (<Row key={rolename + roleNameType} row={values} roleNameType={roleNameType} showColor={index % 2 === 0} />))
															}
															<TableRow>
																<TableCell colSpan={5} style={{ height: 10 }} />
															</TableRow>
														</TableBody>
													</Table>
												</TableCell>
												<TableCell />
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							))
					) : (
						<TableContainer component={Paper} className={style.table} elevation={0}>
							<Table aria-label="collapsible table">
								<colgroup>
									<col style={{ width: '100%' }} />
								</colgroup>
								<TableHead>
									<TableRow>
										<TableCell align="center" className={style.textEmptyDB} colSpan={1} style={{ backgroundColor: 'white' }}>Database is empty</TableCell>
									</TableRow>
								</TableHead>
							</Table>
						</TableContainer>
					)
				}
			</div>
		</div>
	);
};

export default RolesTable;

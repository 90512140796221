import { SwapHoriz, SwapVert } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { CheckBox } from '../../../components/CheckBox';
import React from 'react';
import style from './OperationFlipBox.module.scss';

interface OperationFlipBoxProps {
	data: unknown;
	updateOperation: (data: unknown) => void;
}

export type OperationFlipType = 'vertical' | 'horizontal' | 'both';

export interface OperationFlipData {
	value: OperationFlipType;
}

const OperationFlipBox: React.FC<OperationFlipBoxProps> = ({ data, updateOperation }) => {
	const flipData = data as OperationFlipData;

	const onSelectFlip = (value: OperationFlipType): void => {
		updateOperation({ ...flipData, value } as OperationFlipData);
	};

	return (
		<Box className={style.operationFlipBox}>
			<Box className={`${style.button} && ${flipData.value === 'both' || flipData.value === 'vertical' ? style.selected : ''}`} onClick={(): void => onSelectFlip('vertical')}>
				<SwapVert />
			</Box>
			<Box className={`${style.button} && ${flipData.value === 'both' || flipData.value === 'horizontal' ? style.selected : ''}`} onClick={(): void => onSelectFlip('horizontal')}>
				<SwapHoriz />
			</Box>
			<Box className="operationFlipBoxBoth">
				<CheckBox
					isChecked={flipData.value === 'both'}
					label={'Flip both'}
					onChange={(value: boolean): void => {
						if (value) {
							onSelectFlip('both');
						} else {
							onSelectFlip('vertical');
						}
					}}
				/>
			</Box>
		</Box>
	);
};

export default OperationFlipBox;

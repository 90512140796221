import { AccessControlContext, AccessControlUpdateContext } from '../../contexts/AccessControlContext';
import { FullRoleAccessLevelModel, RolePermissionsModel } from '../../interfaces/AccessControl';
import { ModalContext, ModalUpdateContext } from '../../contexts/ModalContext';
import AccessControlService from '../../services/AccessControlService';
import React from 'react';

interface UseAccessControlNewRoleStore {
	setIsNewRoleModalOpen: (open: boolean) => void;
	isNewRoleModalOpen: boolean;
	setRolename: (data: string) => void;
	rolename: string;
	saveNewRole: () => void;
	error: string;
	setError: (value: string) => void;
	options: FullRoleAccessLevelModel;
	updateOptions: (option: keyof FullRoleAccessLevelModel, permission: keyof RolePermissionsModel, value: boolean) => void;
}

const basePermissionsAccessControl: RolePermissionsModel = {
	create: false,
	read: false,
	update: false,
	delete: false,
};
const basePermissions: RolePermissionsModel = {
	create: false,
	read: true,
	update: false,
	delete: false,
};
const baseOptions: FullRoleAccessLevelModel = {
	'access_control': { ...basePermissionsAccessControl },
	indexes: { ...basePermissions },
	objects: { ...basePermissions },
};

const useAccessControlNewRole = (): UseAccessControlNewRoleStore => {
	const { isNewRoleModalOpen } = React.useContext(ModalContext);
	const { setIsNewRoleModalOpen } = React.useContext(ModalUpdateContext);
	const { myRoles } = React.useContext(AccessControlContext);
	const { fetchRoles } = React.useContext(AccessControlUpdateContext);
	const [ rolename, setRolename ] = React.useState<string>('');
	const [ options, setOptions ] = React.useState<FullRoleAccessLevelModel>(baseOptions);
	const [ error, setError ] = React.useState('');

	const cleanModalState = (): void => {
		setRolename('');
		setOptions({
			'access_control': { ...basePermissionsAccessControl },
			indexes: { ...basePermissions },
			objects: { ...basePermissions },
		});
		setError('');
	};

	const openModal = (open: boolean): void => {
		if (!open) {
			cleanModalState();
		}

		setIsNewRoleModalOpen(open);
	};

	const updateRolename = (data: string): void => {
		setRolename(data);
	};

	const updateOptions = (option: keyof FullRoleAccessLevelModel, permission: keyof RolePermissionsModel, value: boolean): void => {
		const newOptions = { ...options };

		newOptions[option][permission] = value;

		setOptions(newOptions);
	};

	const saveNewRole = async (): Promise<void> => {
		try {
			if (rolename.length < 1 || rolename.length > 30) {
				setError('Role name should have between 1 and 30 characters');

				return;
			}

			const roles = myRoles && myRoles.length ? Object.keys(myRoles[0].GetRoles).filter(role => ((role !== 'info') && (role !== 'status'))) : [];

			if (roles.includes(rolename)) {
				setError('Role name already in use. Please change the role name and try again');

				return;
			}

			const response = await AccessControlService.addRoleToDataBase({
				name: rolename,
				...options,
			});

			if (response.status === -1) {
				setError(response.info);
			} else {
				setError('');
				await fetchRoles();
				openModal(false);
			}
		} catch {
			setError('An error occurred while adding role');
		}
	};

	return {
		setIsNewRoleModalOpen: openModal,
		isNewRoleModalOpen,
		rolename,
		setRolename: updateRolename,
		saveNewRole,
		error,
		setError,
		options,
		updateOptions,
	};
};

export default useAccessControlNewRole;

import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Button } from '../../components/Button';
import ContentGallery from '../../components/ContentGallery/ContentGallery';
import { formatByteAmounts } from '../../helpers/numbers';
import { FullRoleAccessLevelModel } from '../../interfaces/AccessControl';
import { IconButton } from '@material-ui/core';
import ImageSearchFilter from './ImageSearchFilter';
import ImageSearchOperations from './ImageSearchOperations';
import { MessageBox } from '../../components/MessageBox';
import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import { SectionEditor } from '../../components/SectionEditor';
import { SectionTabs } from '../../components/SectionTabs';
import { SplitPanes } from '../../components';
import style from './ImageSearch.module.scss';
import useImageSearch from './Hooks/useImageSearch';
import useRoutes from '../../helpers/hooks/useRoutes';
import useVisibility from '../../helpers/hooks/useVisibility';
const PERMISSION_OPTION_REQUIRED = 'objects' as keyof FullRoleAccessLevelModel;

const ImageSearch: React.FC = () => {
	const { routes } = useRoutes();
	const { show } = useVisibility(
		routes.imageSearch,
		PERMISSION_OPTION_REQUIRED
	);

	const {
		isFetching,
		myImages,
		setFilters,
		setOperations,
		runQuery,
		userQuery,
		queryErr,
		myImageQueryResult,
		imagesPerPage,
		setPageOffset,
		pageOffset,
		handleArrowClick,
		isEditorShown,
		duration,
		blobsSize,
	} = useImageSearch();

	const tabLists = [
		{
			id: 0,
			title: 'Query',
			bodyTab: <div></div>,
			bodyPanel: (
				<div>
					<SectionEditor
						readonly={true}
						height={'30vh'}
						body={JSON.stringify(JSON.parse(userQuery), null, 2)}
					/>
				</div>
			),
		},
		{
			id: 1,
			title: 'Response',
			bodyTab: <div></div>,
			bodyPanel: (
				<div>
					<SectionEditor
						readonly={true}
						height={'30vh'}
						body={JSON.stringify(myImageQueryResult, null, 2)}
					/>
				</div>
			),
		},
	];

	return (
		<div style={{ flex: 1, visibility: show ? 'visible' : 'hidden' }}>
			<div className={style.imageSearch}>
				<SplitPanes primaryMaxSize={400}>
					<div className={style.leftContainer}>
						<div className={style.runButton}>
							<Button
								title="Run"
								onClick={runQuery}
								loadingTitle="Running..."
								isLoading={isFetching}
								startIcon={<RefreshIcon />}
							/>
						</div>
						<div className={style.errorMessage}>
							{queryErr ? (
								<MessageBox text={queryErr} />
							) : (
								myImageQueryResult &&
							myImageQueryResult.length > 0 &&
							!isFetching && (
									<MessageBox
										type={'success'}
										text={
											<>
												<strong>Time:</strong>
												{` ${duration}ms — `}
												<strong>Size:</strong>
												{` ${formatByteAmounts(blobsSize)}`}
											</>
										}
									/>
								)
							)}
						</div>
						<div className={style.filterContainer}>
							<ImageSearchFilter setFilters={setFilters} />
						</div>
						<div className={style.operationsContainer}>
							<ImageSearchOperations setOperations={setOperations} />
						</div>
					</div>
					<div className={style.rightContainer}>
						<div className={style.imagesContainer}>
							<ContentGallery
								rawImages={myImages}
								rawVideos={undefined}
								isFetching={isFetching}
								pageOffset={pageOffset}
								rawPerPage={imagesPerPage}
								total={
									(myImageQueryResult &&
									myImageQueryResult[0] &&
									myImageQueryResult[0].FindImage &&
									myImageQueryResult[0].FindImage.returned) ||
								0
								}
								onPageChange={setPageOffset}
							/>
						</div>
						<div className={style.queriesContainer}>
							<div className={style.button}>
								<IconButton
									onClick={handleArrowClick}
									style={{
										backgroundColor: 'white',
										boxShadow: '0 1px 6px 0 #e4e5ee',
									}}
								>
									{isEditorShown ? (
										<ExpandLess />
									) : (
										<ExpandMore />
									)}
								</IconButton>
							</div>
							<div className={style.queries}>
								<SectionTabs
									sectionTabs={tabLists}
									hideContent={isEditorShown}
								/>
							</div>
						</div>
					</div>
				</SplitPanes>
			</div>
		</div>
	);
};

export default ImageSearch;

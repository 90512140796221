/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomQueryContext, CustomQueryUpdateContext } from '../../../contexts/CustomQueryContext';
import { getUsernameKey, setSaveQueryCustoms } from '../../../services/LocalStorageService';
import { ImageResponse, VideoResponse } from '../../../interfaces/Query';
import ContentService from '../../../services/ContentService';
import CustomQueryService from '../../../services/CustomQueryService';
import GetSavedCustomQueryService from '../../../services/GetSavedCustomQueryService';
import { ImageResult } from '../../../interfaces/Image';
import { QuerySaved } from '../../../interfaces/SaveQueries';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
interface UseCustomSearchStore {
	isFetching: boolean;
	myImages: ImageResponse | undefined;
	myVideos: VideoResponse | undefined;
	saveQuery: (value: string, id: number) => void;
	runQuery: () => void;
	userQuery: string;
	queryErr: string;
	myCustomQueryResult: any[];
	rawPerPage: number;
	setPageOffset: (offset: number) => void;
	pageOffset: number;
	setUserQuery: (value: string) => void;
	tabSelected: number;
	setTabSelected: (tabIndex: number) => void;
	duration: number;
	blobsSize: number;
	handleSelectListedQuery: (id: number) => void;
	handleDeleteCard: (value: number) => void;
	handleResetAll: () => void;
	handleAddNewQuery: () => void;
	temporalSavedQueries: QuerySaved[];
	isEmptyList: boolean;
}

const jsonError = 'Invalid JSON format';

const useCustomSearch = (): UseCustomSearchStore => {
	const { isFetching, myImages, myVideos, rawPerPage, pageOffset, myCustomQueryResult, userQuery, tabSelected } = React.useContext(CustomQueryContext);
	const { fetchCustomData, setPageOffset, fetchMoreRaw, setUserQuery, setTabSelected, resetStates } = React.useContext(CustomQueryUpdateContext);
	const [queryErr, setQueryErr] = React.useState<string>('');
	const [queryResultErr, setResultQueryErr] = React.useState<string>('');
	const [myCustomQueryResultForUser, setMyCustomQueryResultForUser] = React.useState<any[]>([]);
	const [startTime, setStartTime] = React.useState<Date>(new Date());
	const [duration, setDuration] = React.useState<number>(0);
	const [blobsSize, setBlobsSize] = React.useState<number>(0);
	const [temporalSavedQueries, setTemporalSavedQueries] = React.useState<any[]>([]);
	const [isEmptyList, setIsEmptyList] = React.useState<boolean>(true);
	const firstUpdate = React.useRef(true);

	const getTimeDiff = (startQuery: Date, finishQuery: Date): void => {
		setDuration(finishQuery.getTime() - startQuery.getTime());
	};

	const getQueriesList = () => {
		const customQueriesSaved = GetSavedCustomQueryService.getSavedQueriesFiltered();

		const clearDuplicateQueries = [...temporalSavedQueries, ...customQueriesSaved].filter((value, index, self) =>
			index === self.findIndex((t) => (
				t.id === value.id
			))
		);
		const sortedByDate = clearDuplicateQueries.sort((a: any, b: any) => Date.parse(b.date) - Date.parse(a.date));

		if (sortedByDate.length) {
			const [ firstElement, ...restOfElements ] = sortedByDate;
			const modifiedFirstElement = { ...firstElement, isSelected: true };

			setUserQuery(firstElement.query);
			setTemporalSavedQueries([ modifiedFirstElement, ...restOfElements ]);
		} else {
			const newEmptyQuery = {
				id:  uuidv4(),
				userName: getUsernameKey(),
				name: '',
				date: new Date(),
				query: userQuery,
				isModified: false,
				isSelected: true,
			};

			setUserQuery(userQuery);
			setTemporalSavedQueries([ newEmptyQuery ]);
		}
	};

	React.useEffect(() => {
		getQueriesList();
	}, []);

	React.useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;

			return;
		}

		const handleSaveLocalQuery = () => {
			const newTemporalSavedQueries = temporalSavedQueries.map((item: QuerySaved) => {
				if (item.isSelected && item.query.localeCompare(userQuery) !== 0) {
					return { ...item, query: userQuery, isModified: true };
				}

				return item;
			});

			setTemporalSavedQueries(newTemporalSavedQueries);
		};

		handleSaveLocalQuery();
	}, [userQuery]);

	const handleResetAll = () => {
		resetStates();
	};

	React.useEffect(() => {
		setIsEmptyList(!temporalSavedQueries.length);
	}, [temporalSavedQueries]);

	const saveQuery = (name: string, id: number): void => {
		const queryToSave = temporalSavedQueries.find((item: QuerySaved) => item.id === id);
		const savedQueries = GetSavedCustomQueryService.getSavedQueries();
		const index = savedQueries.findIndex((e: QuerySaved) => e.id === queryToSave.id);

		if (index === -1) {
			savedQueries.push({ ...queryToSave, name, isModified: false, isSelected: false });
		} else {
			savedQueries[index] = { ...queryToSave, name, isModified: false, isSelected: false };
		}

		setSaveQueryCustoms(savedQueries);
		setTemporalSavedQueries(newTemporalSavedQueries => newTemporalSavedQueries.map((item: QuerySaved) => {
			if (item.id === id) {
				item.name = name;
				item.isModified = false;
			}

			return item;
		}));
	};

	const handleAddNewQuery = () => {
		setUserQuery('');

		const newEmptyQuery = {
			id:  uuidv4(),
			userName: getUsernameKey(),
			name: '',
			date: new Date(),
			query: userQuery,
			isModified: false,
			isSelected: true,
		};

		setTemporalSavedQueries(temporalQueries => [ newEmptyQuery, ...temporalQueries.map((item: QuerySaved) => ({ ...item, isSelected: false })) ]);
		setTabSelected(0);
		setQueryErr('');
	};

	const handleSelectListedQuery = (id: number): void => {
		const querySelected = temporalSavedQueries.map((item: QuerySaved)=> {
			if(item.id === id){
				return {...item, isSelected: true};
			}

			return {...item, isSelected: false};
		} );

		const queryFiltered = querySelected.filter((item: QuerySaved) => item.id === id);
		const sortByDate = querySelected.sort((a:any, b:any) => Date.parse(b.date) - Date.parse(a.date));

		setTemporalSavedQueries(sortByDate);
		setUserQuery(queryFiltered[0].query);
		setTabSelected(0);
		setQueryErr('');
		setMyCustomQueryResultForUser([]);
	};

	const handleDeleteCard = (id: number): void => {
		const selectedQuery = temporalSavedQueries.find((query: QuerySaved) => query.id === id);

		if (selectedQuery && selectedQuery.isSelected) {
			setUserQuery('');
		}

		const customQueriesSaved = GetSavedCustomQueryService.getSavedQueries();
		const deleteSaveQuery = customQueriesSaved.filter((query: QuerySaved) => query.id !== id);

		setSaveQueryCustoms(deleteSaveQuery);
		setTemporalSavedQueries(temporalQueries => temporalQueries.filter((query: QuerySaved) => query.id !== id));
	};

	const runQuery = (): void => {
		setStartTime(new Date());
		setBlobsSize(0);
		setQueryErr('');
		setResultQueryErr('');

		try {
			const processedBaseQuery = CustomQueryService.getProcessedCustomQuery(JSON.parse(userQuery));

			fetchCustomData(processedBaseQuery).catch(err => {
				if (err.name === 'InternalError') {
					setQueryErr('DB Error: Please check the Response for more information.');
					setResultQueryErr(err.message);
				} else {
					setQueryErr(err.message);
				}
			});
		} catch (err) {
			setQueryErr(jsonError);
		}
	};

	React.useEffect(() => {
		const myCustomQueryResultProcessedForUser: any[] = (queryResultErr && [{ info: queryResultErr }]) || JSON.parse(JSON.stringify(myCustomQueryResult));

		myCustomQueryResultProcessedForUser.forEach((item, index) => {
			if (typeof item === 'object' && item.FindImage && userQuery) {
				const query = JSON.parse(userQuery);

				if (!query[index]?.FindImage || query[index]?.FindImage.blobs === true) {
					(item as ImageResult).FindImage['blobs_start'] = 0;
				} else {
					const findImage = (item as ImageResult).FindImage;

					delete findImage['blobs_start'];
				}

				const {entities} = (item as ImageResult).FindImage;

				if (entities) {
					if (query[index].FindImage && !query[index].FindImage.uniqueids) {
						entities.forEach(entity => {
							delete entity['_blob_index'];
						});
						(item as ImageResult).FindImage.entities = entities;
					}
				}
			}
		});

		setMyCustomQueryResultForUser(myCustomQueryResultProcessedForUser);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [myCustomQueryResult, queryResultErr]);

	React.useEffect(() => {
		if (myCustomQueryResult.length || queryErr || queryResultErr) {
			if (queryErr !== jsonError) {
				setTabSelected(1);
			}
		}
	}, [myCustomQueryResult.length, queryErr, queryResultErr, setTabSelected]);

	React.useEffect(() => {
		if (myImages) {
			const { json: queryResult } = myImages;

			if (queryResult.some(item => item?.FindImage?.status < 0)) {
				setQueryErr('DB Error: Some of the images are not displayed due to errors on bad operations.');
			}
		} else {
			if (myVideos) {
				const { json: queryResult } = myVideos;

				if (queryResult.some(item => item?.FindVideo?.status < 0)) {
					setQueryErr('DB Error: Some of the images are not displayed due to errors on bad operations.');
				}
			}
		}
	}, [myImages, myVideos]);

	React.useEffect(() => {
		const now = new Date();
		let totalBytes = 0;

		getTimeDiff(startTime ? startTime : now, now);

		if (myImages) {
			totalBytes += ContentService.getBlobsTotalSize(myImages.blobs) + myImages.json.toString().length;
		}

		if (myVideos){
			totalBytes += ContentService.getBlobsTotalSize(myVideos.blobs) + myVideos.json.toString().length;
		}

		totalBytes += JSON.stringify(myCustomQueryResult).length;

		setBlobsSize(totalBytes);
	}, [myImages, myVideos, myCustomQueryResult, startTime]);

	const changeRawGalleryPage = (offset: number): void => {
		setPageOffset(offset);
		fetchMoreRaw(offset);
	};

	const changeTab = (tabIndex: number): void => {
		setTabSelected(tabIndex);
	};

	return {
		isFetching,
		myImages,
		myVideos,
		runQuery,
		saveQuery,
		userQuery,
		queryErr,
		rawPerPage,
		setPageOffset: changeRawGalleryPage,
		pageOffset,
		setUserQuery,
		myCustomQueryResult: myCustomQueryResultForUser,
		setTabSelected: changeTab,
		tabSelected,
		duration,
		blobsSize,
		handleSelectListedQuery,
		handleDeleteCard,
		handleResetAll,
		handleAddNewQuery,
		temporalSavedQueries,
		isEmptyList,
	};
};

export default useCustomSearch;

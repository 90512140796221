import { Button as MaterialButton } from '@material-ui/core';
import React from 'react';
import style from './Button.module.scss';

interface ButtonProps {
	endIcon?: JSX.Element;
	isControlAccess?: boolean;
	isDisabled?: boolean;
	isLoading?: boolean;
	isMaxContentWith?: boolean;
	isPrimary?: boolean;
	isTertiary?: boolean;
	isSecondary?: boolean;
	loadingTitle?: string;
	onClick: () => void;
	startIcon?: JSX.Element;
	title: string;
	variant?: 'contained' | 'outlined' | 'text';
}

const Button: React.FC<ButtonProps> = ({ endIcon, isControlAccess, isDisabled, isLoading, isMaxContentWith, isPrimary, isSecondary, loadingTitle, onClick, startIcon, title, variant, isTertiary }) => {

	return (
		<MaterialButton
			className={`${style.platformButton} ${isLoading && 'rotate-button'} ${variant === 'outlined' ? style.outlinedButton : variant === 'text' ? style.textButton : null}  ${isSecondary ? style.secondaryButton : isControlAccess ? style.controlAccessButton : null} ${isMaxContentWith && style.maxContentWith} ${isDisabled && style.disabledButton} ${isTertiary && style.tertiaryButton}`}
			color={isPrimary ? 'primary' : 'default'}
			disabled={isDisabled}
			endIcon={endIcon}
			onClick={onClick}
			size="small"
			startIcon={startIcon}
		>
			{isLoading ? loadingTitle || title : title}
		</MaterialButton>
	);
};

export default Button;

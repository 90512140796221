import { addDataBaseRole, addDataBaseUser, generateToken, getDataBaseRoles, getDataBaseUsers, getMyInfo, removeDataBaseRole, removeDataBaseUser, updateDataBaseRole, updateDataBaseUser } from '../api/AccessControlRepository';
import { CreateRoleModel, CreateUserModel, GenerateTokenResponse, GetRolesResponse, GetUserDetailsResponse, GetUsersResponse, MyUserInfo, MyUserModel, RemoveRoleModel, RemoveUserModel, UpdateRoleModel, UpdateUserModel, UpdateUserResponse } from '../interfaces/AccessControl';
import { CustomResponse, JsonResponse } from '../interfaces/Query';
import { getUsernameKey } from './LocalStorageService';
import { passwordGenerator } from '../helpers/password';

const getMyDataBaseUsers = async (): Promise<GetUsersResponse[]> => {
	const response = await getDataBaseUsers();
	const responseData = response.data as CustomResponse;

	return JSON.parse(JSON.stringify(responseData.json)) as GetUsersResponse[];
};

const getMyUserInfo = async (): Promise<GetUserDetailsResponse[]> => {
	const response = await getMyInfo();
	const responseData = response.data as CustomResponse;

	return JSON.parse(JSON.stringify(responseData.json)) as GetUserDetailsResponse[];
};

const userResponseToUser = (userResponse: GetUserDetailsResponse): MyUserModel => {
	const userDetails: MyUserInfo = userResponse.GetUserDetails;

	return {
		username: getUsernameKey() || 'unknown',
		permissions: userDetails.permissions,
		tokens: userDetails.tokens,
		email: userDetails.email,
	};
};

const addUserToDataBase = async (userData: CreateUserModel): Promise<JsonResponse> => {
	const response = await addDataBaseUser(userData);
	const responseData = response.data as CustomResponse;

	return JSON.parse(JSON.stringify(responseData.json)) as JsonResponse;
};

const updateUserInDataBase = async (userData: UpdateUserModel): Promise<JsonResponse> => {
	const response = await updateDataBaseUser(userData);
	const responseData = response.data as CustomResponse;
	const updateUserResponse = JSON.parse(JSON.stringify(responseData.json)) as UpdateUserResponse[];

	return updateUserResponse[0].UpdateUser;
};

const removeUserInDataBase = async (userData: RemoveUserModel): Promise<JsonResponse> => {
	const response = await removeDataBaseUser(userData);
	const responseData = response.data as CustomResponse;

	return JSON.parse(JSON.stringify(responseData.json)) as JsonResponse;
};

const generateUserPassword = (): string => {
	return passwordGenerator();
};

const getMyDataBaseRoles = async (): Promise<GetRolesResponse[]> => {
	const response = await getDataBaseRoles();
	const responseData = response.data as CustomResponse;

	return JSON.parse(JSON.stringify(responseData.json)) as GetRolesResponse[];
};

const addRoleToDataBase = async (roleData: CreateRoleModel): Promise<JsonResponse> => {
	const response = await addDataBaseRole(roleData);
	const responseData = response.data as CustomResponse;

	return JSON.parse(JSON.stringify(responseData.json)) as JsonResponse;
};

const updateRoleInDataBase = async (roleData: UpdateRoleModel): Promise<JsonResponse> => {
	const response = await updateDataBaseRole(roleData);
	const responseData = response.data as CustomResponse;

	return JSON.parse(JSON.stringify(responseData.json)) as JsonResponse;
};

const removeRoleInDataBase = async (roleData: RemoveRoleModel): Promise<JsonResponse> => {
	const response = await removeDataBaseRole(roleData);
	const responseData = response.data as CustomResponse;

	return JSON.parse(JSON.stringify(responseData.json)) as JsonResponse;
};

const getRoleTypeName = (roleTypeName: string): string => {
	switch (roleTypeName) {
		case 'access_control':
			return 'Users/Roles';
		case 'indexes':
			return 'Indexes';
		case 'objects':
			return 'Objects';
		default:
			return 'Unknown';
	}
};

const generateTempToken = async (): Promise<GenerateTokenResponse[]> => {
	const response = await generateToken();
	const responseData = response.data as CustomResponse;

	return JSON.parse(JSON.stringify(responseData.json)) as GenerateTokenResponse[];
};

export default {
	getMyDataBaseUsers,
	getMyDataBaseRoles,
	getRoleTypeName,
	getMyUserInfo,
	userResponseToUser,
	addUserToDataBase,
	addRoleToDataBase,
	generateUserPassword,
	updateUserInDataBase,
	generateTempToken,
	updateRoleInDataBase,
	removeUserInDataBase,
	removeRoleInDataBase,
};

import React from 'react';
import style from './LabelStatus.module.scss';
import { Tooltip } from '@material-ui/core';

interface LabelStatusProps {
	text: string;
	type: 'SUCCESS' | 'WARNING' | 'ERROR' | 'STOPPED' | 'SUCCESS-ROW' | 'STOPPED-ROW';
}

const LabelStatus: React.FC<LabelStatusProps> = ({ text, type }) => {
	return (
		<Tooltip title={text}>
			<div
				className={`${style.platformLabel} ${
					style[type.toLocaleLowerCase()]
				}`}
			>
				<div
					className={`${style.dot} ${style[type.toLowerCase()]}`}
				></div>
				<span>
					{text}
				</span>
			</div>
		</Tooltip>
	);
};

export default LabelStatus;

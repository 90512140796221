import { getApiKey } from './LocalStorageService';

const getApiUrl = (): string | undefined => {
	let apiUrl: string | undefined | null = getApiKey();

	if (!apiUrl) {
		apiUrl = process.env.REACT_APP_API_URL;
	}

	return apiUrl;
};

export default {
	getApiUrl,
};

import { ImageResponse, VideoResponse } from '../../interfaces/Query';
import { CustomQueryContext } from '../../contexts/CustomQueryContext';
import { ImageData } from '../ImageGallery/Image';
import { ImageQueryContext } from '../../contexts/ImageQueryContext';
import React from 'react';
import SimilaritySearchService from '../../services/SimilaritySearchService';
import { VideoData } from '../VideoGallery/Video';

interface UseContentGalleryStore {
	validationStore: {
		isModalImageOpen: boolean;
		isModalVideoOpen: boolean;
		isFirstContent: boolean;
		isLastContent: boolean;
	};
	paginationStore: {
		totalPages: number;
	};
	handlersStore: {
		handlePageChange: (value: number) => void;
		handleContentImageClick: (ContentToDisplay: VideoData | ImageData) => void;
		handleContentVideoClick: (ContentToDisplay: VideoData | ImageData) => void;
		handleModalClose: () => void;
		handleNextContent: () => void;
		handlePreviousContent: () => void;
	};
	dataStore: {
		content: Content[];
		contentToDisplay: VideoData | ImageData;
	};
}

interface Content {
	type: 'image' | 'video';
	value: VideoData | ImageData;
}

const initialContentToDisplayData = {
	title: '',
	src: '',
	uniqueId: 0,
	properties: {},
	alt: '',
};

export const useContentGallery: (
	rawVideos: VideoResponse | undefined,
	rawImages: ImageResponse | undefined,
	contentPerPage?: number,
	totalContent?: number,
	onPageChange?: (offset: number) => void
) => UseContentGalleryStore = (
	rawVideos,
	rawImages,
	contentPerPage,
	totalContent,
	onPageChange
) => {
	const [content, setContent] = React.useState<Content[]>([]);
	const [contentToDisplay, setContentToDisplay] = React.useState<VideoData | ImageData>(
		initialContentToDisplayData
	);
	const [isModalImageOpen, setIsModalImageOpen] = React.useState<boolean>(false);
	const [isModalVideoOpen, setIsModalVideoOpen] = React.useState<boolean>(false);
	const [isFirstContent, setIsFirstContent] = React.useState<boolean>(false);
	const [isLastContent, setIsLastContent] = React.useState<boolean>(false);
	const { myCustomQueryResult } = React.useContext(CustomQueryContext);
	const { myImageQueryResult } = React.useContext(ImageQueryContext);
	const TOTAL_CONTENT = totalContent || 1;
	const CONTENT_PER_PAGE = contentPerPage || 30;
	const TOTAL_PAGES = Math.ceil(TOTAL_CONTENT / CONTENT_PER_PAGE);

	React.useEffect(() => {
		setContent([]);

		const fetchValues = async () => {
			if (rawVideos) {
				const { blobs: blobList, json: queryResult } = rawVideos;

				const videosFromDb: VideoData[] = [];

				for (let index = 0; index < blobList.length; index++) {
					const blob = blobList[index];
					const videoDetails = queryResult[index];

					if (videoDetails?.FindVideo && videoDetails?.FindVideo?.entities) {
						/* eslint-disable-next-line no-unsafe-optional-chaining */
						const { _uniqueid: uniqueId, ...rest } = videoDetails?.FindVideo?.entities[0];

						videosFromDb.push({
							title: `${rest.title || rest.name || ''}`,
							src: blob,
							uniqueId: uniqueId as number,
							properties: rest,
							alt: `${rest.title || 'video'}`,
						});
					}
				}

				newContent = [...newContent, ...videosFromDb.map((item)=> ({type: 'video', value: item} as Content))];

			}

			if (rawImages) {
				const { blobs: blobList, json: queryResult } = rawImages;

				const imagesFromDb: ImageData[] = [];

				for (let index = 0; index < blobList.length; index++) {
					const blob = blobList[index];
					const imageDetails = queryResult[index];

					if (imageDetails?.FindImage && imageDetails?.FindImage?.entities) {
						/* eslint-disable-next-line no-unsafe-optional-chaining */
						const { _uniqueid: uniqueId, ...rest } = imageDetails?.FindImage?.entities[0];

						const mySimilaritySearchResponseQuery = await SimilaritySearchService.getSimilaritySearchQuery(uniqueId.toString());
						const hasSimilarImages = !!(mySimilaritySearchResponseQuery &&
							mySimilaritySearchResponseQuery.json &&
							mySimilaritySearchResponseQuery.json[2] &&
							mySimilaritySearchResponseQuery.json[2].FindDescriptorSet &&
							mySimilaritySearchResponseQuery.json[2].FindDescriptorSet.returned &&
							mySimilaritySearchResponseQuery.json[2].FindDescriptorSet.returned > 0);

						imagesFromDb.push({
							title: `${rest.title || rest.name || ''}`,
							src: blob,
							uniqueId: uniqueId as number,
							properties: rest,
							alt: `${rest.title || 'image'}`,
							hasSimilarImages: hasSimilarImages,
						});
					}
				}

				newContent = [...newContent, ...imagesFromDb.map((item)=> ({type: 'image', value: item} as Content))];
			}

			if (newContent.length) {
				setContent(newContent.slice(0, CONTENT_PER_PAGE));
			} else {
				setContent([]);
			}
		};

		let newContent: Content[] = [];

		fetchValues();

		return () => {
			newContent = [];
		};
	}, [CONTENT_PER_PAGE, rawVideos, rawImages]);

	const getContentIndex = (value: VideoData | ImageData): number => {
		return content.findIndex((i) => i.value.uniqueId === value.uniqueId);
	};

	const checkIsFirst = (index: number): void => {
		if (index === 0) {
			setIsFirstContent(true);
		} else {
			setIsFirstContent(false);
		}
	};

	const checkIsLast = (index: number): void => {
		if (index === content.length - 1) {
			setIsLastContent(true);
		} else {
			setIsLastContent(false);
		}
	};

	const getContentBoundingBox = (currentImageIndex: number): ImageData | VideoData => {
		let entities = [];

		const image = content[currentImageIndex];
		const imageUniqueId = image?.value?.uniqueId;

		const myCustomQueryResultCopy = myCustomQueryResult.map((query) => ({...query}));
		const myCustomQueryResultCopyFilterImage = myCustomQueryResultCopy.filter((property)=> {
			if(property.FindImage || property.FindBoundingBox){
				return property;
			}
		});

		if ((myImageQueryResult && myImageQueryResult[1] && myImageQueryResult[1]?.FindBoundingBox && myImageQueryResult[1]?.FindBoundingBox.entities)) {

			entities = myImageQueryResult[1].FindBoundingBox.entities[imageUniqueId];

		} else if (myCustomQueryResultCopyFilterImage && myCustomQueryResultCopyFilterImage[1] && myCustomQueryResultCopyFilterImage[1]?.FindBoundingBox && myCustomQueryResultCopyFilterImage[1]?.FindBoundingBox.entities) {

			entities = myCustomQueryResultCopyFilterImage[1].FindBoundingBox.entities[imageUniqueId];
		}

		image.value.boundingBox = {
			entities: [entities],
		};

		return image.value;
	};

	const handleContentImageClick = (contentToDisplay: ImageData | VideoData): void => {
		const currentContentIndex: number = getContentIndex(contentToDisplay);
		const content = getContentBoundingBox(currentContentIndex);

		checkIsFirst(currentContentIndex);
		checkIsLast(currentContentIndex);
		setContentToDisplay(content);
		setIsModalImageOpen(true);
	};

	const handleContentVideoClick = (contentToDisplay: ImageData | VideoData): void => {
		const currentContentIndex: number = getContentIndex(contentToDisplay);
		const content = getContentBoundingBox(currentContentIndex);

		checkIsFirst(currentContentIndex);
		checkIsLast(currentContentIndex);
		setContentToDisplay(content);
		setIsModalVideoOpen(true);
	};

	const handleModalClose = (): void => {
		setContentToDisplay(initialContentToDisplayData);
		setIsModalVideoOpen(false);
		setIsModalImageOpen(false);
	};

	const handlePreviousContent = (): void => {
		const currentContentIndex: number = getContentIndex(contentToDisplay);
		const content = getContentBoundingBox(currentContentIndex - 1);

		checkIsFirst(currentContentIndex - 1);
		checkIsLast(currentContentIndex - 1);
		setContentToDisplay(content);
	};

	const handleNextContent = (): void => {
		const currentContentIndex: number = getContentIndex(contentToDisplay);
		const content = getContentBoundingBox(currentContentIndex + 1);

		checkIsLast(currentContentIndex + 1);
		checkIsFirst(currentContentIndex + 1);
		setContentToDisplay(content);
	};

	const handlePageChange = (value: number): void => {
		onPageChange && onPageChange(value);
	};

	React.useEffect(() => {
		if (contentToDisplay.uniqueId) {
			const currentContentIndex: number = getContentIndex(contentToDisplay);
			const content = getContentBoundingBox(currentContentIndex);

			setContentToDisplay(content);
		}
		//eslint-disable-next-line
	}, [myImageQueryResult, myCustomQueryResult]);

	return {
		validationStore: {
			isModalImageOpen,
			isModalVideoOpen,
			isFirstContent,
			isLastContent,
		},
		paginationStore: {
			totalPages: TOTAL_PAGES,
		},
		handlersStore: {
			handlePageChange,
			handleContentImageClick,
			handleContentVideoClick,
			handleModalClose,
			handleNextContent,
			handlePreviousContent,
		},
		dataStore: {
			content,
			contentToDisplay,
		},
	};
};

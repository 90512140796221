import { AccessControlContext, AccessControlUpdateContext } from '../../contexts/AccessControlContext';
import { ModalContext, ModalUpdateContext } from '../../contexts/ModalContext';
import AccessControlService from '../../services/AccessControlService';
import React from 'react';
import { UpdateUserModel } from '../../interfaces/AccessControl';

interface UseAccessControlEditUserStore {
	setIsEditUserModalOpen: (open: boolean) => void;
	isEditUserModalOpen: boolean;
	username: string;
	setEmail: (data: string) => void;
	email: string;
	setPassword: (data: string) => void;
	password: string;
	roles: string[];
	selectedRoles: string[];
	setSelectedRoles: (value: string[]) => void;
	error: string;
	setError: (value: string) => void;
	editUser: () => void;
	removeUser: () => void;
}

const useAccessControlEditUser = (): UseAccessControlEditUserStore => {
	const { isEditUserModalOpen, userToEdit } = React.useContext(ModalContext);
	const { setIsEditUserModalOpen, setUserToEdit } = React.useContext(ModalUpdateContext);
	const { fetchUsers } = React.useContext(AccessControlUpdateContext);
	const { myRoles } = React.useContext(AccessControlContext);
	const [password, setPassword] = React.useState<string>('');
	const [email, setEmail] = React.useState<string>('');
	const [error, setError] = React.useState('');

	const roles = myRoles && myRoles.length ? Object.keys(myRoles[0].GetRoles).filter(role => ((role !== 'info') && (role !== 'status'))) : [];

	const [selectedRoles, setSelectedRoles] = React.useState<string[]>([]);

	React.useEffect(() => {
		setEmail(userToEdit?.email || '');
		setSelectedRoles((userToEdit && userToEdit.roles && userToEdit.roles.length && userToEdit.roles) || []);
	}, [userToEdit]);

	const cleanModalState = (): void => {
		setPassword('');
		setEmail('');
		setSelectedRoles([]);
		setError('');
	};

	const openModal = (open: boolean): void => {
		if (!open) {
			setUserToEdit(undefined);
			cleanModalState();
		}

		setIsEditUserModalOpen(open);
	};

	const updateEmail = (data: string): void => {
		setEmail(data);
	};

	const updatePassword = (data: string): void => {
		setPassword(data);
	};

	const editUser = async (): Promise<void> => {
		try {
			const user: UpdateUserModel = {
				username: userToEdit?.username || '',
			};

			if (password) {
				if (password.length < 10 || password.length > 20) {
					setError('Password should have between 10 and 20 characters');

					return;
				}

				user.password = password;
			}

			if (email) {
				user.email = email;
			}

			if (selectedRoles.length) {
				user['add_roles'] = selectedRoles.filter(selectedRole => !userToEdit?.roles?.includes(selectedRole));
				user['remove_roles'] = userToEdit?.roles?.filter(role => !selectedRoles.includes(role));
			}

			const response = await AccessControlService.updateUserInDataBase(user);

			if (response.status === -1) {
				setError(response.info);
			} else {
				setError('');
				await fetchUsers();
				openModal(false);
			}
		} catch {
			setError('An error occurred while adding user');
		}
	};

	const removeUser = async (): Promise<void> => {
		try {
			const response = await AccessControlService.removeUserInDataBase({
				username: userToEdit?.username || '',
			});

			if (response.status === -1) {
				setError(response.info);
			} else {
				setError('');
				await fetchUsers();
				openModal(false);
			}
		} catch {
			setError('An error occurred while removing user');
		}
	};

	return {
		setIsEditUserModalOpen: openModal,
		isEditUserModalOpen,
		username: userToEdit?.username || '',
		email,
		setEmail: updateEmail,
		password,
		setPassword: updatePassword,
		roles,
		selectedRoles,
		setSelectedRoles,
		error,
		setError,
		editUser,
		removeUser,
	};
};

export default useAccessControlEditUser;

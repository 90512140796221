/* eslint-disable @typescript-eslint/no-explicit-any */
import { Constraints, CropOperation, FlipOperation, ImageQuery, ImageResult, ResizeOperation, RotateOperation, ThresholdOperation } from '../interfaces/Image';
import { CustomResponse, ImageResponse } from '../interfaces/Query';
import { fetchImage, fetchImageMetadata, fetchImages } from '../api/ImageQueryRepository';
import { FilterProperty, FilterResults } from '../pages/ImageSearch/Hooks/useImageSearchFilter';
import ContentService from './ContentService';
import { OperationCropData } from '../pages/ImageSearch/OperationTypeBoxes/OperationCropBox';
import { OperationFlipData } from '../pages/ImageSearch/OperationTypeBoxes/OperationFlipBox';
import { OperationProperty } from '../pages/ImageSearch/Hooks/useImageSearchOperations';
import { OperationResizeData } from '../pages/ImageSearch/OperationTypeBoxes/OperationResizeBox';
import { OperationRotateData } from '../pages/ImageSearch/OperationTypeBoxes/OperationRotateBox';
import { OperationThresholdData } from '../pages/ImageSearch/OperationTypeBoxes/OperationThresholdBox';
import { PropertyBooleanData } from '../pages/ImageSearch/PropertyTypeBoxes/PropertyBooleanBox';
import { PropertyDateData } from '../pages/ImageSearch/PropertyTypeBoxes/PropertyDateBox';
import { PropertyNumberData } from '../pages/ImageSearch/PropertyTypeBoxes/PropertyNumberBox';
import { PropertyNumberFloatData } from '../pages/ImageSearch/PropertyTypeBoxes/PropertyNumberFloatBox';
import { PropertyNumberIntegerData } from '../pages/ImageSearch/PropertyTypeBoxes/PropertyNumberIntegerBox';
import { PropertyTextData } from '../pages/ImageSearch/ResultsBoxes/ResultsBoxes';

const getImageMetadata = async (query: ImageQuery[]): Promise<ImageResult[]> => {
	const response = await fetchImageMetadata(query);
	const responseData = response.data as CustomResponse;

	if (JSON.parse(JSON.stringify(responseData.json)).info) {
		throw ({
			name: 'InternalError',
			message: JSON.parse(JSON.stringify(responseData.json)).info,
		});
	}

	return JSON.parse(JSON.stringify(responseData.json)) as ImageResult[];
};

const getImages = async (query: ImageQuery[]): Promise<ImageResponse> => {
	const blobsData: any = [];
	const response = await fetchImages(query);
	const queryResponse = response.data as ImageResponse;
	const findImagesFilter = queryResponse.json.filter((item)=> {
		if (item.FindImage) {
			queryResponse.blobs.forEach((blob, key) => {
				if(key === item.FindImage.blobs_start ){
					blobsData.push(blob);
				}
			});

			return item.FindImage;
		}});

	return {blobs: blobsData, json: findImagesFilter};

};

const getImage = async (uniqueId: string): Promise<ImageResponse> => {
	const response = await fetchImage(uniqueId);
	const queryResponse = response.data as ImageResponse;

	return {blobs: queryResponse.blobs, json: queryResponse.json};
};

const getUniqueIdArray = (response: any[]): number[] => {
	return response[0] && response[0].FindImage && response[0].FindImage.entities ? response[0].FindImage.entities.map((entity: any): number => entity._uniqueid) : [];
};

const buildConstraints = (properties: FilterProperty[]): Constraints => {
	const constraints: Constraints = {};
	const operations = ContentService.getPropertiesOperations();

	if (properties.length) {
		properties.forEach(property => {
			if (property.type !== 'BOOLEAN') {
				const data = property.data as (PropertyDateData | PropertyTextData | PropertyNumberFloatData | PropertyNumberIntegerData | PropertyNumberData);

				if (constraints[property.title] && typeof constraints[property.title] === 'object') {
					(constraints[property.title] as (string | number | { _date: string })[]).concat([
						operations.find(operation => operation.value === data.operation)?.label || '==',
						property.type ==='NUMBER'? parseFloat(data.value) || 0 : property.type === 'NUMBER-INTEGER' ? parseInt(data.value) || 0 : (property.type === 'NUMBER-FLOAT' ? parseFloat(data.value) || 0 : (property.type === 'DATE' ? { _date: data.value } : (property.type === 'DATETIME' ? { _date: data.value } : data.value))),
					]);
				} else {
					constraints[property.title] = [
						operations.find(operation => operation.value === data.operation)?.label || '==',
						property.type ==='NUMBER'? parseFloat(data.value) || 0 : property.type === 'NUMBER-INTEGER' ? parseInt(data.value) || 0 : (property.type === 'NUMBER-FLOAT' ? parseFloat(data.value) || 0 : (property.type === 'DATE' ? { _date: data.value } : (property.type === 'DATETIME' ? { _date: data.value } : data.value))),
					];
				}
			} else {
				const data = property.data as PropertyBooleanData;

				constraints[property.title] = ['==', data.value];
			}
		});
	}

	return constraints;
};

const buildOperations = (operationProperties: OperationProperty[]): (RotateOperation | FlipOperation | ResizeOperation | ThresholdOperation | CropOperation)[] => {
	const operations: (RotateOperation | FlipOperation | ResizeOperation | ThresholdOperation | CropOperation | undefined)[] = operationProperties.map(operationProperty => {
		let data;

		switch (operationProperty.type) {
			case 'Resize':
				data = operationProperty.data as OperationResizeData;

				if (!data.width) {
					data.width = '1';
				}

				if (!data.height) {
					data.height = '1';
				}

				return ({ type: 'resize', width: parseInt(data.width), height: parseInt(data.height) } as ResizeOperation);
			case 'Threshold':
				data = operationProperty.data as OperationThresholdData;

				return ({ type: 'threshold', value: data.value } as ThresholdOperation);
			case 'Rotate':
				data = operationProperty.data as OperationRotateData;

				if (!data.degree) {
					data.degree = '0.0';
				}

				return ({ type: 'rotate', angle: parseFloat(data.degree), resize: data.resize } as RotateOperation);
			case 'Crop':
				data = operationProperty.data as OperationCropData;

				if (!data.width) {
					data.width = '1';
				}

				if (!data.height) {
					data.height = '1';
				}

				if (!data.x) {
					data.x = '0';
				}

				if (!data.y) {
					data.y = '0';
				}

				return ({ type: 'crop', width: parseInt(data.width), height: parseInt(data.height), x: parseInt(data.x), y: parseInt(data.y) } as CropOperation);
			case 'Flip':
				data = operationProperty.data as OperationFlipData;

				return ({ type: 'flip', code: data.value === 'vertical' ? 0 : (data.value === 'horizontal' ? 1 : -1) } as FlipOperation);
		}
	});

	const operationsWithoutUndefined = operations.filter(operation => operation !== undefined) as (RotateOperation | FlipOperation | ResizeOperation | ThresholdOperation | CropOperation)[];

	return operationsWithoutUndefined;
};

const buildImageQuery = (properties: FilterProperty[], results: FilterResults | undefined, operationProperties: OperationProperty[]): ImageQuery => {
	const constraints: Constraints = buildConstraints(properties);
	const operations: (RotateOperation | FlipOperation | ResizeOperation | ThresholdOperation | CropOperation)[] = buildOperations(operationProperties);

	const imageQuery: ImageQuery = {
		FindImage: {
			uniqueids: true,
		},
	};

	if (Object.keys(constraints).length) {
		imageQuery.FindImage.constraints = constraints;
	}

	if (operations.length) {
		imageQuery.FindImage.operations = operations;
	}

	if (results) {
		imageQuery.FindImage.unique = results.unique;

		if (results.list.length) {
			imageQuery.FindImage.results = {
				...imageQuery.FindImage.results,
				list: results.list,
			};
		}

		if (results.limit) {
			imageQuery.FindImage.results = {
				...imageQuery.FindImage.results,
				limit: parseInt(results.limit),
			};
		}

		if (results.sortBy) {
			imageQuery.FindImage.results = {
				...imageQuery.FindImage.results,
				sort: results.sortBy,
			};
		}

		if (results.as_format && results.as_format !== 'DEFAULT') {
			imageQuery.FindImage['as_format'] = results.as_format === 'JPG' ? 'jpg' : 'png';
		}

		if (results.boundingBox) {
			imageQuery.FindImage._ref = 1;
			imageQuery.FindBoundingBox = {
				'is_connected_to': {
					ref: 1,
				},
				uniqueids: true,
				coordinates: true,
				labels: true,
				'group_by_source': true,
				results: {
					'all_properties': true,
				},
			};
		}
	}

	return imageQuery;
};

const getProcessedImageQuery = (imageQuery: ImageQuery): ImageQuery => {
	const imageQueryCopy = JSON.parse(JSON.stringify(imageQuery)) as ImageQuery;

	if (!imageQueryCopy.FindImage.results) {
		imageQueryCopy.FindImage.results = {};
	}

	imageQueryCopy.FindImage.blobs = imageQuery.FindImage.blobs ?? false;

	if (imageQuery.FindImage.blobs) {
		imageQueryCopy.FindImage.uniqueids = true;
	}

	return imageQueryCopy;
};

const getIndividualImageQueriesForBlob = (imageQuery: ImageQuery, uniqueIds: number[]): ImageQuery[] => {
	const imageQueryCopy = JSON.parse(JSON.stringify(imageQuery)) as ImageQuery;
	const imageQueriesForBlob: ImageQuery[] = uniqueIds.map(uniqueId => {
		const imageQueryForBlob: ImageQuery = {
			FindImage: {
				blobs: true,
				uniqueids: true,
				constraints: {
					_uniqueid: ['==', uniqueId],
				},
			},
		};

		if (imageQueryCopy.FindImage.operations) {
			imageQueryForBlob.FindImage['operations'] = imageQueryCopy.FindImage.operations;
		}

		if (imageQueryCopy.FindImage.as_format) {
			imageQueryForBlob.FindImage['as_format'] = imageQueryCopy.FindImage.as_format;
		}

		if (imageQueryCopy.FindImage.results) {
			imageQueryForBlob.FindImage['results'] = {
				list: imageQueryCopy.FindImage.results.list,
			};

			if (imageQueryCopy.FindImage.results.all_properties) {
				imageQueryForBlob.FindImage['results'] = {
					...imageQueryForBlob.FindImage['results'],
					'all_properties': imageQueryCopy.FindImage.results.all_properties,
				};
			}
		}

		return imageQueryForBlob;
	});

	return imageQueriesForBlob;
};

export default {
	getImageMetadata,
	getImage,
	getImages,
	getUniqueIdArray,
	buildImageQuery,
	getProcessedImageQuery,
	getIndividualImageQueriesForBlob,
};

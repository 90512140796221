import React from 'react';
import { ZoomableSunburst } from '../ZoomableSunburst';

export interface SunburstData {
	name: string;
	amount?: number;
	children?: SunburstData[];
}

interface PieChartProps {
	width: number;
	height: number;
	data: SunburstData;
	legendPosition?: 'CENTER' | 'BOTTOM' | 'TOP';
}

const PieChart: React.FC<PieChartProps> = ({ width, height, data, legendPosition }) => {
	return (
		<ZoomableSunburst
			data={data}
			scale="exponential"
			tooltip
			keyId="Sunburst"
			legendPosition={legendPosition}
			width={width}
			value={'amount'}
			height={height}
		/>
	);
};

export default PieChart;

import axios, { AxiosResponse } from 'axios';
import { DeleteBoundingBoxRequest, UpdateBoundingBoxRequest } from '../services/BoundingBoxService';
import { AddBoundingBoxRequest } from './../services/BoundingBoxService';
import ApiAddressService from '../services/ApiAddressService';
import { convertToFormData } from '../helpers/request';

export const addNewBoundingBox = async (query: AddBoundingBoxRequest[]): Promise<AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const updateBoundingBox = async (query: UpdateBoundingBoxRequest[]): Promise<void> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const deleteBoundingBox = async (query: DeleteBoundingBoxRequest[]): Promise<void> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

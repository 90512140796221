import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { AuthenticationContext } from '../../contexts/AuthenticationContext';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { PieChart } from '../../components/PieChart';
import React from 'react';
import { RefreshButton } from '../../components/RefreshButton';
import { SectionTitle } from '../../components/SectionTitle';
import style from './StatusChart.module.scss';
import TableContainer from '@material-ui/core/TableContainer';
import { useStatus } from './Hooks/useStatus';

const StatusChart: React.FC = () => {
	const { chartData, entityClassesViewModel, isFetching, fetchStatus } = useStatus();
	const { myUser } = React.useContext(AuthenticationContext);

	return (
		<TableContainer component={Paper} className={style.statusChart} elevation={0}>
			<Box className={style.top}>
				<SectionTitle title="Data Distribution" />
				<RefreshButton
					isLoading={isFetching}
					onClick={fetchStatus}
				/>
			</Box>
			<div className={style.pie}>
				{
					entityClassesViewModel.length && chartData ? (
						<PieChart width={500} height={500} data={chartData} legendPosition={'CENTER'} />
					) : (
						<Table aria-label="data-distribution">
							<TableBody>
								<TableRow>
									<TableCell align="center" className={style.textHeader}>
										{myUser?.permissions.objects.read ? 'Database is empty' : 'You don\'t have permissions to read data'}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					)
				}
			</div>
		</TableContainer>
	);
};

export default StatusChart;

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	FilterProperty,
	FilterResults
} from '../../ImageSearch/Hooks/useImageSearchFilter';
import {
	VideoQueryContext,
	VideoQueryUpdateContext
} from '../../../contexts/VideoQueryContext';
import ContentService from '../../../services/ContentService';
import { OperationProperty } from '../../ImageSearch/Hooks/useImageSearchOperations';
import React from 'react';
import VideoQueryService from '../../../services/VideoQueryService';
import { VideoResponse } from '../../../interfaces/Query';
import { VideoResult } from '../../../interfaces/Video';

interface UseVideoSearchStore {
	isFetching: boolean;
	myVideos: VideoResponse | undefined;
	setFilters: (
		resultFilters: FilterResults | undefined,
		properties: FilterProperty[]
	) => void;
	setOperations: (operations: OperationProperty[]) => void;
	runQuery: () => void;
	userQuery: string;
	queryErr: string;
	myVideoQueryResult: any[];
	videosPerPage: number;
	setPageOffset: (offset: number) => void;
	pageOffset: number;
	handleArrowClick: () => void;
	isEditorShown: boolean;
	duration: number;
	blobsSize: number;
	setIncludeBoundingBox: (value: boolean) => void;
}

const useVideoSearch = (): UseVideoSearchStore => {
	const {
		isFetching,
		myVideos,
		myVideoQueryResult,
		videosPerPage,
		pageOffset,
		userQuery,
	} = React.useContext(VideoQueryContext);
	const { fetchVideoMetadata, setPageOffset, fetchMoreVideos, setUserQuery } =
		React.useContext(VideoQueryUpdateContext);
	const [properties, setProperties] = React.useState<FilterProperty[]>([]);
	const [results, setResults] = React.useState<FilterResults | undefined>(
		undefined
	);
	const [operations, setOperations] = React.useState<OperationProperty[]>([]);
	const [queryErr, setQueryErr] = React.useState<string>('');
	const [queryResultErr, setResultQueryErr] = React.useState<string>('');
	const [isEditorShown, setIsEditorShown] = React.useState<boolean>(false);
	const [startTime, setStartTime] = React.useState<Date>(new Date());
	const [duration, setDuration] = React.useState<number>(0);
	const [blobsSize, setBlobsSize] = React.useState<number>(0);
	const [includeBoundingBox, setIncludeBoundingBox] =
		React.useState<boolean>(true);

	const getTimeDiff = (startQuery: Date, finishQuery: Date): void => {
		setDuration(finishQuery.getTime() - startQuery.getTime());
	};

	const setFilters = (
		filterResults: FilterResults | undefined,
		filterProperties: FilterProperty[]
	): void => {
		setProperties(filterProperties);
		setResults(filterResults);
	};

	const runQuery = (): void => {
		const filterResults = results
			? { ...results, boundingBox: includeBoundingBox }
			: results;

		const baseQuery = VideoQueryService.buildVideoQuery(
			properties,
			filterResults,
			operations
		);
		const processedBaseQuery =
			VideoQueryService.getProcessedVideoQuery(baseQuery);

		setStartTime(new Date());
		setBlobsSize(0);
		setQueryErr('');
		setResultQueryErr('');
		setUserQuery(JSON.stringify([baseQuery]));

		fetchVideoMetadata(processedBaseQuery).catch((err) => {
			if (err.name === 'InternalError') {
				setQueryErr(
					'DB Error: Please check the Response for more information.'
				);
				setResultQueryErr(err.message);
			} else {
				setQueryErr(err.message);
			}
		});
	};

	React.useEffect(() => {
		if (myVideos) {
			const { json: queryResult } = myVideos;

			if (queryResult.some((item) => item.FindVideo.status < 0)) {
				setQueryErr(
					'DB Error: Some of the images are not displayed due to errors on bad operations.'
				);
			} else {
				const now = new Date();

				getTimeDiff(startTime ? startTime : now, now);
				setBlobsSize(ContentService.getBlobsTotalSize(myVideos.blobs));
			}
		}
	}, [myVideos, startTime]);

	React.useEffect(() => {
		const now = new Date();
		let totalBytes = 0;

		getTimeDiff(startTime ? startTime : now, now);

		if (myVideos) {
			totalBytes +=
				ContentService.getBlobsTotalSize(myVideos.blobs) +
				myVideos.json.toString().length;
		}

		totalBytes += JSON.stringify(myVideoQueryResult).length;

		setBlobsSize(totalBytes);
	}, [myVideoQueryResult, myVideos, startTime]);

	const changeVideoGalleryPage = (offset: number): void => {
		setStartTime(new Date());
		setPageOffset(offset);
		fetchMoreVideos(offset);
	};

	const myVideoQueryResultProcessedForUser: any[] =
		(queryResultErr && [{ info: queryResultErr }]) ||
		(JSON.parse(JSON.stringify(myVideoQueryResult)) as VideoResult[]);

	myVideoQueryResultProcessedForUser.forEach((item, index) => {
		if (typeof item === 'object' && item.FindVideo) {
			const query = JSON.parse(userQuery);
			const entities = (item as VideoResult).FindVideo.entities ?? [];

			if (query[index].FindImage && !query[index].FindVideo.uniqueids) {
				if (entities.length && Object.keys(entities[0]).length === 1) {
					delete (item as VideoResult).FindVideo['entities'];
				} else {
					entities.forEach((entity) => {
						delete entity['_blob_index'];
					});
					(item as VideoResult).FindVideo.entities = entities;
				}
			}
		}
	});

	const handleArrowClick = (): void => {
		setIsEditorShown((prevState) => !prevState);
	};

	const parsedUserQuery = JSON.parse(userQuery);
	const newUserQuery = [];

	parsedUserQuery &&
		parsedUserQuery[0] &&
		parsedUserQuery[0].FindVideo &&
		newUserQuery.push({ FindVideo: parsedUserQuery[0].FindVideo });
	parsedUserQuery &&
		parsedUserQuery[0] &&
		parsedUserQuery[0].FindBoundingBox &&
		newUserQuery.push({
			FindBoundingBox: parsedUserQuery[0].FindBoundingBox,
		});

	return {
		isFetching,
		myVideos,
		setFilters,
		setOperations,
		runQuery,
		userQuery: JSON.stringify(newUserQuery),
		queryErr,
		myVideoQueryResult: myVideoQueryResultProcessedForUser,
		videosPerPage,
		setPageOffset: changeVideoGalleryPage,
		pageOffset,
		handleArrowClick,
		isEditorShown,
		duration,
		blobsSize,
		setIncludeBoundingBox,
	};
};

export default useVideoSearch;

import { b64toBlob } from '../helpers/blob';
import { SelectBoxItem } from '../components/SelectBox/SelectBox';

const getPropertiesOperations = (): SelectBoxItem[] => {
	return [
		{
			value: 'EQUAL',
			label: '==',
		},
		{
			value: 'NOT_EQUAL',
			label: '!=',
		},
		{
			value: 'GREATER_THAN',
			label: '>',
		},
		{
			value: 'GREATER_OR_EQUAL_THAN',
			label: '>=',
		},
		{
			value: 'LESS_THAN',
			label: '<',
		},
		{
			value: 'LESS_OR_EQUAL_THAN',
			label: '<=',
		},
	];
};

const getBlobSize = (blob: Blob): number => {
	return blob.size;
};

const getBlobsTotalSize = (blobs: string[]): number => {
	return blobs.reduce((accumulator, blob) => accumulator + getBlobSize(b64toBlob(blob)), 0);
};

export default {
	getPropertiesOperations,
	getBlobsTotalSize,
};

import { getSavedCustomQueries, getUsernameKey } from './LocalStorageService';
import { QuerySaved } from '../interfaces/SaveQueries';

const getSavedQueries = () => {
	const result = getSavedCustomQueries();

	if(result) {
		return JSON.parse(result);
	}

	return [];
};

const getSavedQueriesFiltered = () => {
	const result = getSavedCustomQueries();

	if(result) {
		return JSON.parse(result).filter((item: QuerySaved) => item.userName === getUsernameKey());
	}

	return [];
};

export default {
	getSavedQueries,
	getSavedQueriesFiltered,
};

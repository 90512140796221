import axios, { AxiosResponse } from 'axios';
import ApiAddressService from '../services/ApiAddressService';
import { convertToFormData } from '../helpers/request';

export const fetchSimilaritySearch = async (uniqueId: string, descriptor: string, kNeighbors: number): Promise<AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		const params = descriptor.length > 0 ? 	{
			_uniqueid: uniqueId,
			descriptor,
			k_neighbors: kNeighbors,
		} : {
			_uniqueid: uniqueId,
			k_neighbors: kNeighbors,
		};

		return axios.get(`${apiUrl}/similarity-search/`, {
			params,
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const fetchSimilaritySearchQuery = async (uniqueId: string): Promise<AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();
	const query = [{
		FindImage: {
			_ref: 1,
			blobs: false,
			constraints: {
				_uniqueid: [
					'==',
					uniqueId,
				],
			},
		},
	},
	{
		FindDescriptor: {
			is_connected_to: {
				ref: 1,
			},
			_ref: 2,
			blobs: false,
		},
	},
	{
		FindDescriptorSet: {
			is_connected_to: {
				ref: 2,
			},
			results: {
				list: [
					'_name',
				],
			},
		},
	}];

	if (apiUrl) {
		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};


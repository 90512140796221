import axios, { AxiosResponse } from 'axios';
import ApiAddressService from '../services/ApiAddressService';
import { convertToFormData } from '../helpers/request';
import { ImageQuery } from '../interfaces/Image';
import { VideoQuery } from '../interfaces/Video';

export const fetchData = (query: ImageQuery[] | VideoQuery[]): Promise<AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

import { Box, Link, Paper, TableContainer, Typography } from '@material-ui/core';
import useImageSearchFilter, { FilterProperty, FilterResults } from './Hooks/useImageSearchFilter';
import { Button } from '../../components/Button';
import PropertyBox from './PropertyBox';
import React from 'react';
import ResultsBoxes from './ResultsBoxes/ResultsBoxes';
import { SectionTitle } from '../../components/SectionTitle';
import { SimpleDialog } from '../../components/SimpleDialog';
import StatusService from '../../services/StatusService';
import style from './ImageSearchFilter.module.scss';

interface ImageSearchFilterProps {
	setFilters: (resultFilters: FilterResults | undefined, properties: FilterProperty[]) => void;
}

const ImageSearchFilter: React.FC<ImageSearchFilterProps> = ({ setFilters }) => {
	const {
		propertyFilters,
		resultFilters,
		imgEntityClassViewModel,
		openSelectPropertyFilterDialog,
		onCloseSelectPropertyFilterDialog,
		onOpenSelectPropertyFilterDialog,
		onSelectPropertyFilterDialog,
		updatePropertyFilter,
		setResultFilters,
		removePropertyFilter,
		resetFilters,
	} = useImageSearchFilter(setFilters);

	const propertyNames = imgEntityClassViewModel.filter(StatusService.filterClassPropertiesByType).map(property => property.name);

	return (
		<div className={style.imageSearchFilter}>
			<TableContainer component={Paper} className={style.container} elevation={0}>
				<Box className={style.top} >
					<SectionTitle title="Filter" />
					<div className={style.button} >
						<Button
							title="Reset"
							onClick={resetFilters}
							isSecondary={true}
						/>
					</div>
				</Box>
				<Box className={style.properties}>
					<Typography className={style.title}>
						Constraints
					</Typography>
					<Box className={style.propertyBoxes}>
						{
							propertyFilters.map((propertyFilter, index) => (
								<PropertyBox
									key={propertyFilter.title + index}
									title={propertyFilter.title}
									type={propertyFilter.type}
									data={propertyFilter.data}
									updatePropertyFilter={(data: unknown): void => updatePropertyFilter(index, { ...propertyFilter, data })}
									removePropertyFilter={(): void => removePropertyFilter(index)}
								/>
							))
						}
					</Box>
				</Box>
				<Link className={style.addFilter} onClick={onOpenSelectPropertyFilterDialog}>+ Add new constraint</Link>
				<div style={{ flex: 1, height: 3, backgroundColor: '#f8f8fc', margin: 20 }}></div>
				<Box className={style.properties}>
					<Typography className={style.title}>
						Results
					</Typography>
					<Box className={style.propertyBoxes}>
						<ResultsBoxes resultFilters={resultFilters} setResultFilters={setResultFilters} propertyNames={propertyNames} />
					</Box>
				</Box>
			</TableContainer>
			<SimpleDialog open={openSelectPropertyFilterDialog} onClose={onCloseSelectPropertyFilterDialog} onSelect={onSelectPropertyFilterDialog} labels={propertyNames} />
		</div>
	);
};

export default ImageSearchFilter;

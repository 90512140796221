import { removeApiKey, setApiKey, setAuthToken, setRefreshToken, setUsernameKey } from '../../../services/LocalStorageService';
import { AuthenticationUpdateContext } from '../../../contexts/AuthenticationContext';
import AuthService from '../../../services/AuthService';
import React from 'react';

interface UseLoginStore {
	setUsername: (data: string) => void;
	username: string;
	setPassword: (data: string) => void;
	password: string;
	setIp: (data: string) => void;
	ip: string;
	setPort: (data: string) => void;
	port: string;
	setIsOpen: () => void;
	isOpen: boolean;
	login: () => void;
	error: string;
	setError: (value: string) => void;
	isLoading: boolean;
}

const useLogin = (): UseLoginStore => {
	const [username, setUsername] = React.useState<string>('');
	const [password, setPassword] = React.useState<string>('');
	const [ip, setIp] = React.useState<string>('127.0.0.1');
	const [port, setPort] = React.useState<string>('9001');
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const [error, setError] = React.useState('');
	const { setIsAuthentication } = React.useContext(AuthenticationUpdateContext);
	const [ isLoading, setIsLoading ] = React.useState(false);

	const updateUsername = (username: string): void => {
		setUsername(username);
	};

	const updatePassword = (pass: string): void => {
		setPassword(pass);
	};

	const updateIp = (ip: string): void => {
		setIp(ip);
	};

	const updatePort = (port: string): void => {
		setPort(port);
	};

	const updateOpen = (): void => {
		setIsOpen(!isOpen);
	};

	const login = async (): Promise<void> => {
		setIsLoading (true);

		try {
			if (username.length <= 0) {
				setError('Username is required');

				return;
			}

			if (isOpen) {
				if (ip.length <= 0) {
					setError('Ip is required');

					return;
				}

				if (port.length <= 0) {
					setError('Port is required');

					return;
				}

				setApiKey(`${ip}:${port}/api`);
			} else {
				removeApiKey();
			}

			const response = await AuthService.loginToDatabase({
				username,
				password,
			});

			if (response[0].Authenticate.status === -1) {
				setError(response[0].Authenticate.info);
			} else {
				const authenticate = response[0].Authenticate;

				setError('');
				setAuthToken(authenticate.session_token);
				setRefreshToken(authenticate.refresh_token);
				setUsernameKey(username);
				setIsAuthentication(true);
			}
		} catch {
			setError('An error occurred while trying to login');
		} finally {
			setIsLoading (false);
		}
	};

	return {
		setUsername: updateUsername,
		username,
		setPassword: updatePassword,
		password,
		setIp: updateIp,
		ip,
		setPort: updatePort,
		port,
		setIsOpen: updateOpen,
		isOpen,
		login,
		error,
		setError,
		isLoading,
	};
};

export default useLogin;

import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import style from './RefreshButton.module.scss';
import { Tooltip } from '@material-ui/core';

interface ButtonProps {
	isLoading?: boolean;
	onClick: () => void;
}

const RefreshButton: React.FC<ButtonProps> = ({ onClick, isLoading }) => {
	return (
		<Tooltip title={isLoading ? 'Refreshing...' : 'Refresh'} placement="right" arrow={true}>
			<div
				className={`${style.iconContainer} ${isLoading? style.rotateRefreshButton : style.platformRefreshButton}`}
				onClick={onClick}
			>
				<RefreshIcon fontSize={'small'} />
			</div>
		</Tooltip>
	);
};

export default RefreshButton;

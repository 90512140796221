/* eslint-disable @typescript-eslint/no-explicit-any */
import './Pagination.scss';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import { IconButton, OutlinedInput } from '@material-ui/core';
import React from 'react';
import style from './Pagination.module.scss';

interface PaginationProps {
	totalPages: number;
	currentPage: number;
	onPageChange: (value: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ totalPages, currentPage, onPageChange }) => {
	const [pageOffset, setPageOffset] = React.useState<number>(currentPage);
	const minPage = 1;
	const maxPage = totalPages;

	const isInputModified = (): boolean => {
		return currentPage !== pageOffset;
	};

	const isInputValid = (input: number): boolean => {
		return input >= minPage && input <= maxPage && typeof input === 'number' && !isNaN(input);
	};

	const handleChange = (e: any): void => {
		const value: number = parseInt(e.target.value);

		setPageOffset(!isNaN(value) ? value : 0);
	};

	const handleKeyPress = (e: any): void => {
		if (e.key.toLowerCase() === 'enter' && isInputModified()) {
			if (isInputValid(pageOffset)) {
				onPageChange(pageOffset);
			} else {
				setPageOffset(currentPage);
			}
		}
	};

	const handleBlur = (): void => {
		if (isInputModified() && isInputValid(pageOffset)) {
			onPageChange(pageOffset);
		}
		else if (!isInputValid(pageOffset)) {
			setPageOffset(currentPage);
		}
	};

	const handlePageDown = (): void => {
		if (isInputValid(pageOffset - 1)) {
			setPageOffset((prevPageOffset) => prevPageOffset - 1);
			onPageChange(pageOffset - 1);
		}
	};

	const handlePageUp = (): void => {
		if (isInputValid(pageOffset + 1)) {
			setPageOffset((prevPageOffset) => prevPageOffset + 1);
			onPageChange(pageOffset + 1);
		}
	};

	return (
		<div className={style.asPagination}>
			<p className={`${style.asPagination} ${style.textPaginationDisplaying}`}>Displaying page</p>
			<IconButton
				className={`${style.asPagination} icons-pagination`}
				color={'primary'}
				onClick={handlePageDown}
				disabled={!isInputValid(pageOffset - 1)}
			>
				<ArrowLeft />
			</IconButton>
			<OutlinedInput
				className={`${style.asPagination} offset-pagination-input`}
				value={pageOffset}
				margin={'dense'}
				onKeyPress={handleKeyPress}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
			<p className={`${style.asPagination} ${style.textPaginationOfTotal}`}>of {totalPages}</p>
			<IconButton
				className={`${style.asPagination} icons-pagination`}
				color={'primary'}
				onClick={handlePageUp}
				disabled={!isInputValid(pageOffset + 1)}
			>
				<ArrowRight />
			</IconButton>
		</div>
	);
};

export default Pagination;


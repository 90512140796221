/* eslint-disable @typescript-eslint/no-explicit-any */
import './BarChart.scss';
import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';

export interface BarChartData {
	name: string;
	amount?: number;
}

interface BarChartProps {
	width: number;
	height: number;
	data: BarChartData[];
}

const margin = {top: 50, right:20, bottom: 30, left:60};

const BarChart: React.FC<BarChartProps> = ({ width, height, data }): any => {

	const barChart = useRef(null!);

	useEffect(() => {
		const drawBarChart = (data: any, width: number, height: number): any => {
			const chartWidth = width - margin.left - margin.right;
			const chartHeight = height - margin.top - margin.bottom;

			const tooltip = d3.select('#barChart')
				.append('div')
				.attr('class', 'tooltip');

			const svg = d3.select(barChart.current)
				.attr('width', chartWidth + margin.left + margin.right)
				.attr('height', chartHeight + margin.top + margin.bottom);

			const x = d3.scaleBand()
				.domain(data.map((d: any, i: number) => i))
				.range([margin.left, chartWidth - margin.right])
				.padding(0.3);

			svg.append('g')
				.attr('transform', 'translate(0,'+ chartHeight + ')')
				.call(d3.axisBottom(x).tickFormat((i: string) => i).tickSizeOuter(0));

			const max = d3.max(data, (d: any): number => { return d.amount; })!;
			const y_ = d3.scaleLinear()
				.domain([0, max])
				.range([chartHeight, margin.top]);
			const y = (value: d3.NumberValue) => y_(value) || 0;

			svg.append('g')
				.attr('transform', 'translate(' + margin.left + ',0)')
				.call(d3.axisLeft(y_).tickFormat((d) => {return d + ' k';}).ticks(10));

			svg.selectAll('.bar')
				.data(data)
				.join('rect')
				.attr('class', 'bar')
				.attr('x', (d: any, i: any): any => x(i))
				.attr('y', (d: any): any => y(d.amount))
				.attr('height', (d: any): any => y(0) - y(d.amount))
				.attr('width', x.bandwidth())
				.on('mouseover', (data: any) => {
					tooltip.style('visibility', 'visible')
						.html(`${data.name}<br><br>${data.amount}`);
				})
				.on('mousemove', () => {
					tooltip.style('top', ` ${(d3.event.layerY + 20)}px`)
						.style('left', `${(d3.event.layerX)}px`);
				})
				.on('mouseout', () => {
					tooltip.html('')
						.style('visibility', 'hidden');
				});
		};

		drawBarChart(data, width, height);

	}, [data, width, height]);

	return (
		<div id='barChart'>
			<svg
				ref={barChart}
				style={{ width: width || 580, height: height || 400 }}
			/>
		</div>
	);
};

export default BarChart;

import { FormControlLabel, Radio as MaterialRadio } from '@material-ui/core';
import React from 'react';
import style from './Radio.module.scss';

interface RadioProps {
	isChecked: boolean;
	label: string;
	name: string;
	value: string;
	onChange: (value: string) => void;
}

const Radio: React.FC<RadioProps> = ({ isChecked, onChange, label, name, value }) => {
	return (
		<FormControlLabel
			control={
				<MaterialRadio
					checked={isChecked}
					value={value}
					onChange={(event: React.ChangeEvent<HTMLInputElement>): void => onChange(event.target.value)}
					color="primary"
				/>
			}
			name={name}
			label={label}
			className={style.platformRadioControlLabel}
		/>
	);
};

export default Radio;

import './AccessControlNewUser.scss';
import { Snackbar, Typography } from '@material-ui/core';
import AccessControlService from '../services/AccessControlService';
import { Alert } from '@material-ui/lab';
import { Button } from '../components/Button';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Modal } from '../components/Modal';
import { MultipleSelectBox } from '../components/SelectBox';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import React from 'react';
import { TextField } from '../components/TextField';
import tokenIcon from '../assets/token-icon.png';
import useAccessControlNewUser from './Hooks/useAccessControlNewUser';
import visibility from '../assets/visibility.png';

const AccessControlNewUser: React.FC = () => {
	const { isNewUserModalOpen, setIsNewUserModalOpen, username, setUsername, password, setPassword, email, setEmail, roles, selectedRoles, setSelectedRoles, saveNewUser, error, setError } = useAccessControlNewUser();
	const [showPassword, setShowPassword] = React.useState<boolean>(false);

	const usernameHandler = (value: string): void => {
		setUsername(value);
	};

	const passwordHandler = (value: string): void => {
		setPassword(value);
	};

	const togglePasswordHandler = (): void => {
		setShowPassword(!showPassword);
	};

	const emailHandler = (value: string): void => {
		setEmail(value);
	};

	const handleErrorClose = (event?: React.SyntheticEvent, reason?: string): void => {
		if (reason === 'clickaway') {
			return;
		}

		setError('');
	};

	return (
		<Modal open={isNewUserModalOpen} setOpen={setIsNewUserModalOpen} title={'Add a new user'} successButton={'add a new user'} successButtonOnClick={(): void => saveNewUser()} successButtonDisabled={!username || !password || !selectedRoles.length}>
			<div className='new-user-content'>
				<div className='new-user-input'>
					<TextField value={username} onChange={usernameHandler} label={'Username'} iconInput={<PersonOutlineIcon />} required />
				</div>
				<div className='edit-email-input'>
					<TextField value={email} onChange={emailHandler} label={'Email'} iconInput={<MailOutlineIcon />} />
				</div>
				<div className='new-user-input'>
					<TextField value={password} autocomplete="current-password" onChange={passwordHandler} label={'Password'} type={showPassword ? undefined : 'password'} iconInput={<img src={visibility} alt='Visibility' onClick={togglePasswordHandler} />} required />
				</div>
				<Button title="Generate password" onClick={(): void => passwordHandler(AccessControlService.generateUserPassword())} startIcon={<img src={tokenIcon} alt='password' />} isControlAccess={true} />
				<div className='new-user-input'>
					<Typography className="new-user_select-title">Role (*)</Typography>
					<MultipleSelectBox selectedValues={selectedRoles} onChange={(value: unknown): void => setSelectedRoles(value as string[])} items={roles.map(role => ({ value: role, label: role }))} />
				</div>
			</div>
			<Snackbar open={!!error} autoHideDuration={6000} onClose={handleErrorClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<Alert onClose={handleErrorClose} severity="error">
					{error}
				</Alert>
			</Snackbar>
		</Modal>
	);
};

export default AccessControlNewUser;

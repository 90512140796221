import { Divider, IconButton, Link, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import style from './DropdownMenu.module.scss';
import topBarMenu from '../../assets/topbar-menu.png';

interface MenuOptionProps {
	option: string;
	link: string;
	onClick?: () => void;
	target?: string;
	isDisabled?: boolean;
	isDivider? : boolean;
}

interface DropdownMenuProps {
	options: MenuOptionProps[];
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ options }) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (): void => {
		setAnchorEl(null);
	};

	return (
		<div>
			<IconButton onClick={handleClick}>
				<img src={topBarMenu} alt='Menu' />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				elevation={0}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				classes={{
					list: style.list,
				}}
			>
				{
					options.map((item, index) => {
						if (item.onClick) {
							return (
								<MenuItem key={index} disabled={item.isDisabled}>
									<Link className={style.menuOptions} onClick={(): void => { item.onClick && item.onClick(); handleClose(); }} color="inherit" target={item.target ? '_blank' : '_self'}>
										{item.option}
									</Link>
								</MenuItem>
							);
						}

						return (
							<>
								{item.isDivider &&
								<><Divider orientation={'horizontal'} variant={'middle'} className={style.menuDivider} key={index} /></>}
								<MenuItem key={index} disabled={item.isDisabled}>
									<Link className={style.menuOptions} href={item.link} onClick={(): void => { handleClose(); }} color="inherit" target={item.target ? '_blank' : '_self'}>
										{item.option}
									</Link>
								</MenuItem>
							</>
						);
					})
				}
			</Menu>
		</div>
	);
};

export default DropdownMenu;

import axios, { AxiosResponse } from 'axios';
import ApiAddressService from '../services/ApiAddressService';
import { convertToFormData } from '../helpers/request';
import { DataBaseStatus } from '../interfaces/DataBaseStatus';
import { SystemStatus } from '../interfaces/SystemStatus';

export const getDataBaseStatus = (): Promise<AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		const query: DataBaseStatus[] = [
			{
				GetSchema: {},
			},
		];

		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const getSystemStatus = (): Promise<AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		const query: SystemStatus[] = [
			{
				GetStatus: { all: true },
			},
		];

		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

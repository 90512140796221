import { AccessControlContext, AccessControlUpdateContext } from '../../../contexts/AccessControlContext';
import { GetRolesResponse, GetUsersResponse } from '../../../interfaces/AccessControl';
import React from 'react';

export interface AccessControlStore {
	fetchUsers: () => void;
	fetchRoles: () => void;
	fetchValues: () => void;
	isFetching: boolean;
	users: GetUsersResponse | undefined;
	roles: GetRolesResponse | undefined;
	userSearch: string;
	setUserSearch: (text: string) => void;
}

export const useAccessControl = (): AccessControlStore => {
	const { myUsers, myRoles, isFetching } = React.useContext(AccessControlContext);
	const { fetchUsers, fetchRoles } = React.useContext(AccessControlUpdateContext);
	const [ users, setUsers ] = React.useState<GetUsersResponse | undefined>();
	const [ roles, setRoles ] = React.useState<GetRolesResponse | undefined>();
	const [ userSearch, setUserSearch ] = React.useState('');

	const fetchValues = (): void => {
		fetchUsers();
		fetchRoles();
	};

	React.useEffect((): void => {
		if (myUsers && myUsers.length) {
			setUsers(myUsers[0]);
		}

		if (myRoles && myRoles.length) {
			setRoles(myRoles[0]);
		}
	}, [myUsers, myRoles]);

	return {
		isFetching,
		users,
		roles,
		fetchRoles,
		fetchUsers,
		fetchValues,
		userSearch,
		setUserSearch,
	};
};

import React, { ReactNode } from 'react';
import { AuthenticationContext } from './AuthenticationContext';
import { SimilaritySearch } from '../interfaces/SimilaritySearch';
import { SimilaritySearchResponse } from '../interfaces/Query';
import SimilaritySearchService from '../services/SimilaritySearchService';

export interface SimilaritySearchQueryProviderStore {
	isFetching: boolean;
	itemsPerPage: number;
	mySimilaritySearchResponse: SimilaritySearchResponse | undefined;
	pageOffset: number;
	fetchSimilaritySearch: (search: SimilaritySearch) => Promise<void>;
}

export interface SimilaritySearchUpdateProviderStore {
	resetStates: () => void;
	setPageOffset: (offset: number) => void;
}

export const SimilaritySearchContext = React.createContext({} as SimilaritySearchQueryProviderStore);
export const SimilaritySearchUpdateContext = React.createContext({} as SimilaritySearchUpdateProviderStore);

const SimilaritySearchProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const [isFetching, setIsFetching] = React.useState(false);
	const [mySimilaritySearchResponse, setMySimilaritySearch] = React.useState<SimilaritySearchResponse | undefined>(undefined);
	const [pageOffset, setPageOffset] = React.useState<number>(1);
	const { isAuthentication } = React.useContext(AuthenticationContext);

	const itemsPerPage = 50;

	const resetStates = (): void => {
		setMySimilaritySearch(undefined);
		setIsFetching(false);
		setPageOffset(1);
	};

	const fetchSimilaritySearch = async (search: SimilaritySearch): Promise<void> => {
		if (isAuthentication) {
			setIsFetching(true);

			try {
				const similaritySearch = await SimilaritySearchService.getSimilaritySearch(search.uniqueId, search.descriptor, search.kNeighbors = 50) as SimilaritySearchResponse;

				setMySimilaritySearch(similaritySearch);
			} catch (err) {
				Promise.reject((err as unknown as { name: string; message: string }).message);
			}

			finally {
				setIsFetching(false);
			}

		} else {
			setMySimilaritySearch(undefined);
		}

		Promise.resolve();
	};

	const store: SimilaritySearchQueryProviderStore = {
		isFetching,
		itemsPerPage,
		mySimilaritySearchResponse,
		pageOffset,
		fetchSimilaritySearch,
	};

	const updateStore: SimilaritySearchUpdateProviderStore = {
		resetStates,
		setPageOffset,
	};

	return (
		<SimilaritySearchContext.Provider value={store}>
			<SimilaritySearchUpdateContext.Provider value={updateStore}>
				{children}
			</SimilaritySearchUpdateContext.Provider>
		</SimilaritySearchContext.Provider>
	);
};

export { SimilaritySearchProvider };
export const SimilaritySearchConsumer = SimilaritySearchContext.Consumer;

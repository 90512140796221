import { CustomQuery, ImageSearch, Status } from '..';
import AccessControl from '../AccessControl/AccessControl';
import { AdvancedSearch } from '../AdvancedSearch';
import { NotFound } from '../../pages';
import React from 'react';
import { SimilaritySearch } from '../SimilaritySearch';
import { useParams } from 'react-router';
import useRoutes from '../../helpers/hooks/useRoutes';
import { VideoSearch } from '../VideoSearch';

type Params = {
	path: string;
};

const ApertureDB: React.FC = () => {
	const { path } = useParams<Params>();
	const { routes } = useRoutes();

	const isValidRoute = (path: string):boolean => {
		const pathValues = path.split('/');

		if (
			pathValues[0] === 'advanced-search' ||
			routes.advancedSearch.startsWith(`/${pathValues[0]}`)
		) {

			return true;
		}

		return Object.values(routes).includes(`/${pathValues[0]}`);
	};

	if (!isValidRoute(path)) {
		return (
			<NotFound />
		);
	}

	const renderComponents = (): any => {
		return (
			<>
				<AccessControl />
				<CustomQuery />
				<Status />
				<ImageSearch />
				<VideoSearch />
				<AdvancedSearch/>
				<SimilaritySearch/>
			</>
		);
	};

	return renderComponents();
};

export default ApertureDB;

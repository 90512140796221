import AccessControlEditRole from '../../modals/AccessControlEditRole';
import AccessControlEditUser from '../../modals/AccessControlEditUser';
import AccessControlNewRole from '../../modals/AccessControlNewRole';
import AccessControlNewUser from '../../modals/AccessControlNewUser';
import { Appbar } from '../../components';
import { AuthenticationContext } from '../../contexts/AuthenticationContext';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ManageToken from '../../modals/ManageToken';
import Navigation from '../../Navigation';
import { OverlayContext } from '../../contexts/OverlayContext';
import React from 'react';
import style from './Home.module.scss';
import { Theme } from '@material-ui/core/styles';
import UpdatePassword from '../../modals/UpdatePassword';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff',
		},
	})
);

const Home: React.FC = () => {
	const { isAuthentication } = React.useContext(AuthenticationContext);
	const { isOpen } = React.useContext(OverlayContext);
	const classes = useStyles();

	return (
		<div className={style.home}>
			{isAuthentication ?
				<Appbar />
				: (
					null
				)
			}
			<div className={style.container}>
				<Navigation />
			</div>
			<ManageToken />
			<UpdatePassword />
			<AccessControlNewUser />
			<AccessControlNewRole />
			<AccessControlEditUser />
			<AccessControlEditRole />
			<Backdrop
				className={classes.backdrop}
				open={isOpen}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
};

export default Home;

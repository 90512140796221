import { cleanUserLoginData, getAuthToken } from '../services/LocalStorageService';
import React, { ReactNode } from 'react';
import AccessControlService from '../services/AccessControlService';
import { JsonResponse } from '../interfaces/Query';
import { MyUserModel } from '../interfaces/AccessControl';
import { OverlayUpdateContext } from './OverlayContext';

export interface AuthenticationProviderStore {
	myUser: MyUserModel | null;
	isAuthentication: boolean;
}

export interface AuthenticationUpdateProviderStore {
	fetchMyUserInfo: () => void;
	setIsAuthentication: (isAuthentication: boolean) => void;
}

export const AuthenticationContext = React.createContext({} as AuthenticationProviderStore);
export const AuthenticationUpdateContext = React.createContext({} as AuthenticationUpdateProviderStore);

const AuthenticationProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const [myUser, setMyUser] = React.useState<MyUserModel | null>(null);
	const [isAuthentication, setIsAuthentication] = React.useState<boolean>(getAuthToken() !== null);
	const { setIsOpen } = React.useContext(OverlayUpdateContext);

	const fetchMyUserInfo = React.useCallback(async (): Promise<void> => {
		const userInfo = await AccessControlService.getMyUserInfo();

		if (Array.isArray(userInfo)) {
			setMyUser(AccessControlService.userResponseToUser(userInfo[0]));
		} else {
			throw new Error((userInfo as JsonResponse).info);
		}
	}, []);

	React.useEffect(() => {
		if (isAuthentication) {
			(async (): Promise<void> => {
				try {
					setIsOpen(true);
					await fetchMyUserInfo();
				} catch (err) {
					cleanUserLoginData();
					setIsAuthentication(false);
				} finally {
					setIsOpen(false);
				}
			})();
		} else {
			setMyUser(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthentication, fetchMyUserInfo]);

	const store: AuthenticationProviderStore = {
		myUser,
		isAuthentication,
	};

	const updateStore: AuthenticationUpdateProviderStore = {
		fetchMyUserInfo,
		setIsAuthentication,
	};

	return (
		<AuthenticationContext.Provider value={store}>
			<AuthenticationUpdateContext.Provider value={updateStore}>
				{children}
			</AuthenticationUpdateContext.Provider>
		</AuthenticationContext.Provider>
	);
};

export { AuthenticationProvider };
export const AuthenticationConsumer = AuthenticationContext.Consumer;

import { GetRolesResponse, GetUsersResponse } from '../interfaces/AccessControl';
import React, { ReactNode } from 'react';
import AccessControlService from '../services/AccessControlService';
import { AuthenticationContext } from './AuthenticationContext';

export interface AccessControlProviderStore {
	myUsers: GetUsersResponse[];
	myRoles: GetRolesResponse[];
	isFetching: boolean;
}

export interface AccessControlUpdateProviderStore {
	setMyUsers: (myUsers: GetUsersResponse[]) => void;
	setMyRoles: (myRoles: GetRolesResponse[]) => void;
	fetchUsers: () => void;
	fetchRoles: () => void;
}

export const AccessControlContext = React.createContext({} as AccessControlProviderStore);
export const AccessControlUpdateContext = React.createContext({} as AccessControlUpdateProviderStore);

const AccessControlProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const { isAuthentication, myUser } = React.useContext(AuthenticationContext);
	const [myUsers, setMyUsers] = React.useState<GetUsersResponse[]>([]);
	const [myRoles, setMyRoles] = React.useState<GetRolesResponse[]>([]);
	const [isFetching, setIsFetching] = React.useState(false);

	const fetchUsers = async (): Promise<void> => {
		if (isAuthentication) {
			setIsFetching(true);

			try {
				const users = await AccessControlService.getMyDataBaseUsers();

				setMyUsers(users);
			} catch (err) {
				Promise.reject((err as unknown as { name: string; message: string }).message);
			}

			setIsFetching(false);
		}

		Promise.resolve();
	};

	const fetchRoles = async (): Promise<void> => {
		if (isAuthentication) {
			setIsFetching(true);

			try {
				const roles = await AccessControlService.getMyDataBaseRoles();

				setMyRoles(roles);
			} catch (err) {
				Promise.reject((err as unknown as { name: string; message: string }).message);
			}

			setIsFetching(false);
		}

		Promise.resolve();
	};

	React.useEffect(() => {
		fetchUsers();
		fetchRoles();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthentication, myUser]);

	const store: AccessControlProviderStore = {
		myUsers,
		myRoles,
		isFetching,
	};

	const updateStore: AccessControlUpdateProviderStore = {
		setMyUsers,
		setMyRoles,
		fetchUsers,
		fetchRoles,
	};

	return (
		<AccessControlContext.Provider value={store}>
			<AccessControlUpdateContext.Provider value={updateStore}>
				{children}
			</AccessControlUpdateContext.Provider>
		</AccessControlContext.Provider>
	);
};

export { AccessControlProvider };
export const AccessControlConsumer = AccessControlContext.Consumer;

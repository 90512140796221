export const formatAmounts = (value: number): string => {
	if (typeof value !== 'number' || isNaN(value)) {
		return value.toString();
	}

	const isNegative = value < 0;
	let countDividers = 0;

	value = Math.abs(value);

	while (value > 999 && countDividers < 3) {
		value /= 1000;
		countDividers++;
	}

	let formattedAmounts = value.toFixed(1);

	switch (countDividers) {
		case 1:
			formattedAmounts += 'K';
			break;
		case 2:
			formattedAmounts += 'M';
			break;
		case 3:
			formattedAmounts += 'B';
			break;
		default:
			formattedAmounts = value.toString();
			break;
	}

	const sign = isNegative ? '-' : '';

	return countDividers > 0 ? `~${sign}${formattedAmounts}` : `${sign}${formattedAmounts}`;
};

export const formatByteAmounts = (value: number): string => {
	if (typeof value !== 'number' || isNaN(value)) {
		return value.toString();
	}

	const isNegative = value < 0;
	let countDividers = 0;

	value = Math.abs(value);

	while (value > 1023 && countDividers < 3) {
		value /= 1024;
		countDividers++;
	}

	switch (countDividers) {
		case 1:
			return (isNegative ? '-' : '') + value.toFixed(2) + ' KB';
		case 2:
			return (isNegative ? '-' : '') + value.toFixed(2) + ' MB';
		case 3:
			return (isNegative ? '-' : '') + value.toFixed(2) + ' GB';
		default:
			return (isNegative ? '-' : '') + value.toString() + ' Bytes';
	}
};

export const randomBoundingBoxId = (): string => {
	const result = Math.random();

	return `${result}`;
};

import { IconButton, InputAdornment, InputLabel, TextField as MaterialTextField } from '@material-ui/core';
import React from 'react';
import style from './TextField.module.scss';

interface TextFieldProps {
	onChange: (value: string) => void;
	placeholder?: string;
	value: string;
	type?: 'number-integer' | 'number-float' | 'date' | 'password' | 'text' | 'number';
	label?: string;
	iconInput?: JSX.Element;
	autocomplete?: string;
	disabled?: boolean;
	required?: boolean;
	hideRequired?: boolean;
}

const TextField: React.FC<TextFieldProps> = ({ placeholder, onChange, value, type, label, iconInput, autocomplete, disabled, required, hideRequired }) => {
	const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (type && type !== 'text' && type !== 'password') {
			if (type === 'number-integer' && (/^-?(0|[1-9]\d*)$/.test(event.target.value) || event.target.value === '')) {
				onChange(event.target.value);
			} else if (type === 'number-float' && (/^-?[0-9]+(e[0-9]+)?.?([0-9]+)?$/.test(event.target.value) || event.target.value === '')) {
				onChange(event.target.value);
			} else if (type === 'date') {
				onChange(event.target.value);
			} else if (type === 'number' && (/^([0-9]*[.])?[0-9]+$/.test(event.target.value) || event.target.value === '')) {
				onChange(event.target.value);
			}
		} else {
			onChange(event.target.value);
		}
	};

	return (
		<>
			{label ? <InputLabel className={style.platformTextFieldLabel}>{`${label} ${required && !hideRequired ? '(*)' : ''}`}</InputLabel> : null}

			<MaterialTextField
				disabled={disabled}
				placeholder={placeholder}
				variant="outlined"
				value={value}
				className={label ? style.platformTextFieldControl : style.platformTextField}
				onChange={onChangeValue}
				type={type === 'number'? 'number' : type === 'date' ? 'date' : type === 'password' ? 'password' : undefined}
				autoComplete={autocomplete ? autocomplete : 'off'}
				InputProps={iconInput && {
					endAdornment:
						<InputAdornment position="end">
							<IconButton className={style.platformTextFieldButton}>
								{iconInput}
							</IconButton>
						</InputAdornment>,
				}}
			/>
		</>
	);
};

export default TextField;

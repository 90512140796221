import { Box, Link } from '@material-ui/core';
import ContentService from '../../../services/ContentService';
import React from 'react';
import { SelectBox } from '../../../components/SelectBox';
import style from './PropertyDateBox.module.scss';
import { TextField } from '../../../components/TextField';

interface PropertyDateBoxProps {
	data: unknown;
	updatePropertyFilter: (data: unknown) => void;
}

type DateOperations = 'GREATER_THAN' | 'GREATER_OR_EQUAL_THAN' | 'LESS_THAN' | 'LESS_OR_EQUAL_THAN' | 'EQUAL' | 'NOT_EQUAL';

export interface PropertyDateData {
	operation: DateOperations;
	value: string;
	isAdvanced: boolean;
}

const selectBoxItems = ContentService.getPropertiesOperations();

const PropertyDateBox: React.FC<PropertyDateBoxProps> = ({ data, updatePropertyFilter }) => {
	const dateData = data as PropertyDateData;
	const [isAdvanced, setIsAdvanced] = React.useState(dateData.isAdvanced);

	const onChangeOperation = (value: unknown): void => {
		const operation = value as string;

		updatePropertyFilter({ ...dateData, operation } as PropertyDateData);
	};

	const onChangeValue = (value: string): void => {
		updatePropertyFilter({ ...dateData, value } as PropertyDateData);
	};

	return (
		<Box className={style.propertyDateBox}>
			<Box className={style.inputs}>
				<Box className={style.selected}>
					<SelectBox selectedValue={dateData.operation} onChange={onChangeOperation} items={selectBoxItems} />
				</Box>
				<Box className={style.text}>
					{
						isAdvanced
							? <TextField placeholder="2018-11-07T00:25:00.073876Z" value={dateData.value} onChange={onChangeValue} />
							: <TextField placeholder={''} value={Number.isNaN(Date.parse(dateData.value)) ? new Date().toISOString() : `${new Date(dateData.value).getFullYear()}-${('0' + (new Date(dateData.value).getMonth() + 1)).slice(-2)}-${('0' + (new Date(dateData.value).getDate())).slice(-2)}`} onChange={onChangeValue} type="date" />
					}
				</Box>
			</Box>
			<Box className={style.advanced}>
				<Link className={style.link} onClick={(): void => setIsAdvanced(!isAdvanced)}>{isAdvanced ? 'Standard' : 'Advanced'}</Link>
			</Box>
		</Box>
	);
};

export default PropertyDateBox;

import React, { EventHandler, MouseEvent, SyntheticEvent } from 'react';
import { FindBoundingBoxResponse } from '../../interfaces/Video';
import style from './Video.module.scss';

export interface VideoProperties {
	[key: string]: string | number | boolean;
}

export interface VideoData {
	boundingBox?: FindBoundingBoxResponse;
	src: string;
	title?: string;
	uniqueId: number;
	properties: VideoProperties;
	alt?: string;
	hasSimilarImages?: boolean;
}

interface VideoProps {
	video: VideoData;
	onVideoClick: (video: VideoData) => void;
}

const Video: React.FC<VideoProps> = ({ video, onVideoClick }) => {
	const handleClick: EventHandler<SyntheticEvent> = (e) => {
		e.preventDefault();
		onVideoClick(video);
	};

	const _formatProperties: (properties: VideoProperties) => string = ({
		width,
		height,
	}) => `${width}x${height} px`;

	const handleMouseEnter = (event: MouseEvent<HTMLMediaElement>): void => {
		event.currentTarget.play();
	};

	const handleMouseLeave = (event: MouseEvent<HTMLMediaElement>): void => {
		event.currentTarget.pause();
	};

	return (
		<>
			<div className={style.video}>
				<div className={style.content}>
					<video
						className={style.contentElement}
						key={video.alt || video.src}
						src={`data:video/mp4;base64, ${video.src}`}
						onClick={handleClick}
						onMouseEnter={(event): void => handleMouseEnter(event)}
						onMouseLeave={(event): void => handleMouseLeave(event)}
						muted
					/>
				</div>
				<div className={style.description}>
					{video.properties.height &&
						video.properties.height !== 'Missing property' &&
						video.properties.width &&
						video.properties.width !== 'Missing property' && (<p className={style.propertiesDescription}>{_formatProperties(video.properties)}</p>)}
				</div>
			</div>
		</>
	);
};

export default Video;

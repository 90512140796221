/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthenticationContext, AuthenticationUpdateContext } from '../../contexts/AuthenticationContext';
import { ModalContext, ModalUpdateContext } from '../../contexts/ModalContext';
import AccessControlService from '../../services/AccessControlService';
import React from 'react';

interface UseUpdatePasswordStore {
	setIsOpenUpdate: (open: boolean) => void;
	isOpenUpdate: boolean;
	newPassword: string;
	setNewPassword: (password: string) => void;
	oldPassword: string;
	setOldPassword: (password: string) => void;
	repeatPassword: string;
	setRepeatPassword: (password: string) => void;
	changePassword: () => void;
	error: string;
	setError: (value: string) => void;
}

const useUpdatePassword = (): UseUpdatePasswordStore => {
	const { isOpenUpdate } = React.useContext(ModalContext);
	const { setIsOpenUpdate } = React.useContext(ModalUpdateContext);
	const { myUser } = React.useContext(AuthenticationContext);
	const { fetchMyUserInfo } = React.useContext(AuthenticationUpdateContext);
	const [newPassword, setNewPassword] = React.useState<string>('');
	const [oldPassword, setOldPassword] = React.useState<string>('');
	const [repeatPassword, setRepeatPassword] = React.useState<string>('');
	const [ error, setError ] = React.useState('');

	const openModal = (open: boolean): void => {
		if (!open) {
			setNewPassword('');
			setOldPassword('');
			setRepeatPassword('');
		}

		setIsOpenUpdate(open);
	};

	const updateNewPassword = (password: string): void => {
		setNewPassword(password);
	};

	const updateOldPassword = (password: string): void => {
		setOldPassword(password);
	};

	const updateRepeatPassword = (password: string): void => {
		setRepeatPassword(password);
	};

	const changePassword = async (): Promise<void> => {
		try {
			if (myUser) {
				if (newPassword.length < 10 || newPassword.length > 20) {
					setError('Password should have between 10 and 20 characters');

					return;
				}

				const response = await AccessControlService.updateUserInDataBase({
					username: myUser.username,
					password: newPassword,
				});

				if (response.status === -1) {
					setError(response.info);
				} else {
					setError('');
					await fetchMyUserInfo();
					openModal(false);
				}
			} else {
				setError('An error occurred while changing your password');
			}
		} catch {
			setError('An error occurred while changing your password');
		}
	};

	return {
		setIsOpenUpdate: openModal,
		isOpenUpdate,
		setNewPassword: updateNewPassword,
		newPassword,
		setOldPassword: updateOldPassword,
		oldPassword,
		setRepeatPassword: updateRepeatPassword,
		repeatPassword,
		changePassword,
		error,
		setError,
	};
};

export default useUpdatePassword;

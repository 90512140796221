/* eslint-disable @typescript-eslint/no-explicit-any */
import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';
import { deepEqual } from '../../helpers/objects';
import style from './ZoomableSunburst.module.scss';

const formatTooltip: (name: string, amount: number, d3Node: any) => string = (name, amount, root) => {
	const total: number = amount / root.value;

	return `${name}: ${new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 1 }).format(total < 0.0001 ? 0.0001 : total)}`;
};

const usePrevious = (value: any): any => {
	const ref = useRef();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

const ZoomableSunburst = (props: any): any => {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const svgRef = useRef(null!);
	const prevProps = usePrevious(props);

	useEffect(() => {
		if (!deepEqual(prevProps, props)) {
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			renderSunburst();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	const arcTweenData = (a: any, i: any, node: any, x: any, arc: any): any => {
		const oi = d3.interpolate({ x0: (a.x0s ? a.x0s : 0), x1: (a.x1s ? a.x1s : 0) }, a);

		const tween = (t: any): any => {
			const b = oi(t);

			a.x0s = b.x0;
			a.x1s = b.x1;

			return arc(b);
		};

		if (i === 0) {
			const xd = d3.interpolate(x.domain(), [node.x0, node.x1]);

			return (t: any): any => {
				x.domain(xd(t));

				return tween(t);
			};
		}

		return tween;

	};

	const update = (root: any, firstBuild: any, svg: any, partition: any, hueDXScale: any, x: any, y: any, radius: any, arc: any, node: any): any => {
		if (firstBuild) {
			const arcTweenZoom = (d: any): any => {
				const xd = d3.interpolate(x.domain(), [d.x0, d.x1]);
				const yd = d3.interpolate(y.domain(), [d.y0, 1]);
				const yr = d3.interpolate(y.range(), [d.y0 ? 40 : 0, radius]);

				return (data: any, i: any): any => {
					return i
						? (): any => arc(data)
						: (t: any): any => {
							x.domain(xd(t));
							y.domain(yd(t)).range(yr(t));

							return arc(data);
						};
				};
			};

			const click = (d: any): any => {
				node = d;
				props.onSelect && props.onSelect(d);
				svg.selectAll('path').transition().duration(1000).attrTween('d', arcTweenZoom(d));
			};

			const centerInfo = d3.select(`#${props.keyId}-svg-center-info`).style('position', 'absolute')
				.style('z-index', '10')
				.style('opacity', '1');

			svg
				.selectAll('path')
				.data(partition(root).descendants())
				.enter()
				.append('path')
				.style('fill', (d: any): any => {
					let hue;
					const current = d;

					if (current.depth === 0) {
						return 'transparent';
					}

					if (current.depth <= 3) {
						hue = hueDXScale(d.x0);
						current.fill = d3.hsl(hue, 0.5, 0.6);

						return current.fill;
					}

					// current.fill = current.parent.fill.brighter(0.5);
					// const hsl = d3.hsl(current.fill);

					// hue = hueDXScale(current.x0);
					// const colorshift = hsl.h + (hue / 4);

					// return d3.hsl(colorshift, hsl.s, hsl.l);
				})
				.attr('stroke', '#fff')
				.attr('stroke-width', '0')
				.on('mouseover', (d: any): any => {
					if (props.tooltip) {
						centerInfo.html(() => formatTooltip(d.data.name, d.value, root));

						return centerInfo.transition().duration(50).style('opacity', 1);
					}

					return null;
				})
				.on('mouseout', () => {
					if (props.tooltip) {
						centerInfo.transition().duration(50).style('opacity', 0);
					}

					return null;
				})
				.filter((d: any): any => d.children)
				.on('click', (d: any): any => click(d))
				.on('mouseover', (d: any): any => {
					if (props.tooltip) {
						d3.select('g').style('cursor', 'pointer');
						centerInfo.html(() => formatTooltip(d.data.name, d.value, root));

						return centerInfo.transition().duration(50).style('opacity', 1);
					}

					return null;
				})
				.on('mouseout', () => {
					if (props.tooltip) {
						d3.select('g').style('cursor', 'default');
						centerInfo.transition().duration(50).style('opacity', 0);
					}

					return null;
				});
		} else {
			svg.selectAll('path').data(partition(root).descendants());
		}

		svg.selectAll('path').transition().duration(1000).attrTween('d', (d: any, i: any): any => arcTweenData(d, i, node, x, arc));
	};

	const renderSunburst = (): any => {
		if (props.data) {
			document.querySelectorAll('g').forEach((node) => {
				node.remove();
			});
			const gWidth = props.width;
			const gHeight = props.height;
			const radius = (Math.min(gWidth, gHeight) / 2) - 25;
			const svg = d3.select(svgRef.current).append('g').attr('transform', `translate(${gWidth / 2},${gHeight / 2})`);
			let transformValue: string = `translate(${gWidth / 2},${15})`;

			if (props.legendPosition === 'CENTER') {
				transformValue = `translate(${gWidth / 2},${gHeight / 2})`;
			}
			else if (props.legendPosition === 'BOTTOM') {
				transformValue = `translate(${gWidth / 2},${gHeight - 10})`;
			}

			d3.select(svgRef.current).append('g').attr('transform', transformValue)
				.append('text')
				.attr('text-anchor', 'middle')
				.attr('class', `${style.zoomableSunburstLabelText}`)
				.attr('id', `${props.keyId}-svg-center-info`);
			const x_ = d3.scaleLinear().range([0, 2 * Math.PI]);
			const y_ = props.scale === 'linear' ? d3.scaleLinear().range([0, radius]) : d3.scaleSqrt().range([0, radius]);
			const x = (value: d3.NumberValue) => x_(value) || 0;
			const y = (value: d3.NumberValue) => y_(value) || 0;
			const partition = d3.partition();
			const arc = d3.arc()
				.startAngle((d: any): any => Math.max(0, Math.min(2 * Math.PI, x(d.x0))))
				.endAngle((d: any): any => Math.max(0, Math.min(2 * Math.PI, x(d.x1))))
				.innerRadius((d: any): any => Math.max(0, y(d.y0)))
				.outerRadius((d: any): any => Math.max(0, y(d.y1)));
			const hueDXScale = d3.scaleLinear()
				.domain([0, 1])
				.range([0, 360]);
			const rootData = d3.hierarchy(props.data);
			const firstBuild = true;
			const node = rootData;

			rootData.sum(d => d[props.value]);
			update(rootData, firstBuild, svg, partition, hueDXScale, x, y, radius, arc, node); // GO!
		}
	};

	return (
		<div id={props.keyId} className="text-center">
			<svg
				ref={svgRef}
				style={{ width: parseInt(props.width, 10) || 480, height: parseInt(props.height, 10) || 400 }}
				id={`${props.keyId}-svg`}
			/>
		</div>
	);
};

export default ZoomableSunburst;

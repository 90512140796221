import { Box, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { HelpText } from '../../components/HelpText';
import OperationCropBox from './OperationTypeBoxes/OperationCropBox';
import OperationFlipBox from './OperationTypeBoxes/OperationFlipBox';
import OperationMaxSizeBox from '../VideoSearch/OperationTypeBoxes/OperationMaxSizeBox';
import OperationResizeBox from './OperationTypeBoxes/OperationResizeBox';
import OperationRotateBox from './OperationTypeBoxes/OperationRotateBox';
import OperationThresholdBox from './OperationTypeBoxes/OperationThresholdBox';
import React from 'react';
import style from './OperationBox.module.scss';

export type OperationBoxType = 'Resize' | 'Threshold' | 'Rotate' | 'Crop' | 'Flip' | 'Max-Size Limit';

interface OperationBoxProps {
	type: OperationBoxType;
	data: unknown;
	removeOperation: () => void;
	updateOperation: (data: unknown) => void;
}

const getOperationTypeBox = (type: OperationBoxType, data: unknown, updateOperation: (data: unknown) => void): JSX.Element => {
	switch (type) {
		case 'Resize':
			return <OperationResizeBox data={data} updateOperation={updateOperation} />;
		case 'Threshold':
			return <OperationThresholdBox data={data} updateOperation={updateOperation} />;
		case 'Max-Size Limit':
			return <OperationMaxSizeBox data={data} updateOperation={updateOperation} />;
		case 'Rotate':
			return <OperationRotateBox data={data} updateOperation={updateOperation} />;
		case 'Crop':
			return <OperationCropBox data={data} updateOperation={updateOperation} />;
		case 'Flip':
			return <OperationFlipBox data={data} updateOperation={updateOperation} />;
		default:
			return <div>TYPE NOT SUPPORTED</div>;
	}
};

const getHelpText = (type: OperationBoxType): JSX.Element | null => {
	switch (type) {
		case 'Resize':
			return null;
		case 'Threshold':
			return null;
		case 'Max-Size Limit':
			return <HelpText text={'For longer size limits use Python API'} />;
		case 'Rotate':
			return <HelpText text={'Resize option will resize image after rotation'} />;
		case 'Crop':
			return null;
		case 'Flip':
			return null;
		default:
			return null;
	}
};

const OperationBox: React.FC<OperationBoxProps> = ({ type, data, removeOperation, updateOperation }) => {
	return (
		<Box className={style.operationBox}>
			<IconButton aria-label="delete" className={style.delete} onClick={removeOperation}>
				<DeleteIcon fontSize="small" />
			</IconButton>
			<Box className={style.top}>
				<Typography className={style.title}>
					{type}
				</Typography>
				{
					getHelpText(type)
				}
			</Box>
			{
				getOperationTypeBox(type, data, updateOperation)
			}
		</Box>
	);
};

export default OperationBox;

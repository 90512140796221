import { AuthenticationContext } from '../../contexts/AuthenticationContext';
import { FullRoleAccessLevelModel } from '../../interfaces/AccessControl';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import useRoutes from '../../helpers/hooks/useRoutes';

type Params = {
	path: string;
};

export interface VisibilityStore {
	show: boolean;
}

export const useVisibility = (
	component: string,
	permissionOptionRequired?: keyof FullRoleAccessLevelModel
): VisibilityStore => {
	const { path } = useParams<Params>();
	const [show, setShow] = React.useState(false);
	const { isAuthentication, myUser } = React.useContext(
		AuthenticationContext
	);
	const { routes } = useRoutes();
	const history = useHistory();

	React.useEffect((): void => {
		if (component.includes(`/${path}`)) {
			if (
				(permissionOptionRequired === undefined ||
					myUser?.permissions[permissionOptionRequired].read) &&
				isAuthentication
			) {
				setShow(true);
			} else {
				history.push(routes.login);
			}
		} else {
			if (Object.values(routes).indexOf(`/${path}`) === -1) {
				history.push(routes.root);
			} else {
				setShow(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthentication, myUser, myUser?.permissions, path, permissionOptionRequired]);

	return {
		show,
	};
};

export default useVisibility;

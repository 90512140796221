import { ArrowLeft, ArrowRight, Close } from '@material-ui/icons';
import { Button, IconButton, Modal } from '@material-ui/core';
import React from 'react';
import style from './VideoModal.module.scss';
import { VideoData } from './Video';
import VideoProperties from './VideoProperties';

interface VideoModalProps {
	video: VideoData;
	isOpen: boolean;
	onClose: () => void;
	onPreviousClick: () => void;
	onNextClick: () => void;
	isNextButtonDisabled?: boolean;
	isPreviousButtonDisabled?: boolean;
}

const VideoModal: React.FC<VideoModalProps> = ({
	video,
	isOpen,
	onClose,
	onPreviousClick,
	onNextClick,
	isNextButtonDisabled,
	isPreviousButtonDisabled,
}) => {

	return (
		<Modal open={isOpen} onClose={onClose}>
			<div className={style.videoModal}>
				<IconButton onClick={onClose} className={style.closeIcon}>
					<Close className={style.svgIcon} />
				</IconButton>
				<div className={style.titleModal}>
					{video.title && <h1>{video.title}</h1>}
				</div>
				<div className={style.contentModal}>
					<div className={style.contentLeft}>
						<VideoProperties video={video} />
					</div>
					<div className={style.contentRight}>
						<video
							className={`${style.content}`}
							key={video.alt || video.src}
							src={`data:video/mp4;base64,${video.src}`}
							controls
						/>
					</div>
				</div>
				<div className={style.bottomNavigation}>
					<Button
						color="primary"
						classes={{ label: style.btnText }}
						startIcon={<ArrowLeft />}
						onClick={onPreviousClick}
						disabled={isPreviousButtonDisabled}
					>
						Previous
					</Button>
					<Button
						color="primary"
						classes={{ label: style.btnText }}
						endIcon={<ArrowRight />}
						onClick={onNextClick}
						disabled={isNextButtonDisabled}
					>
						Next
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default VideoModal;

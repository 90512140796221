import 'react-circular-progressbar/dist/styles.css';
import ApiAddressService from '../../services/ApiAddressService';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { LabelStatus } from '../../components/LabelStatus';
import Paper from '@material-ui/core/Paper';
import { ProgressChart } from '../../components/ProgressChart';
import { ProgressType } from '../../components/ProgressChart/ProgressChart';
import React from 'react';
import { SectionTitle } from '../../components/SectionTitle';
import style from './StatusSystem.module.scss';
import TableContainer from '@material-ui/core/TableContainer';
import { useSystemStatus } from './Hooks/useSystemStatus';

const StatusSystem: React.FC = () => {
	const { systemInfoResponse: systemData, open, toggleSystemDataPanel } = useSystemStatus();

	const type = systemData.status === 0 ? 'SUCCESS' : systemData.status === -1 ? 'ERROR' : 'WARNING';

	const charts = [
		{
			data: (systemData.cpu_utilization ?? 0) * 100,
			dataValue: (systemData.cpu_utilization ?? 0) * 100,
			dataType: 'used',
			type: 'PERCENT',
			dataTitle: 'CPU UTILIZATION',
			boxTitle: 'CONNECTION COUNT',
			boxData: systemData.connection_count ?? 0,
		},
		{
			data: (systemData.storage_gb?.total ?? 0),
			dataValue: 0,
			dataType: 'used',
			type: 'SIZE',
			dataTitle: 'STORAGE CONSUMPTION',
			boxTitle: 'QUERIES RUNNING',
			boxData: systemData.queries_running ?? 0,
		},
		{
			data: systemData.memory_utilization_gb ?? 0,
			dataValue: (systemData.memory_utilization ?? 0) * 100,
			dataType: 'used',
			type: 'SIZE',
			dataTitle: 'MEMORY CONSUMPTION',
			boxTitle: 'QUERIES QUEUED',
			boxData: systemData.queries_queued ?? 0,
		},
	];

	return (
		<TableContainer component={Paper} className={style.statusSystem} elevation={0}>
			<Box className={style.top}>
				<SectionTitle title="System Info" />
				<IconButton className={style.arrowButton} aria-label="expand row" size="small" onClick={toggleSystemDataPanel}>
					{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
				</IconButton>
			</Box>
			<div className={style.pieContainer}>
				<div> {`VERSION: ${systemData.version}`} </div>
				<div>HOST: {ApiAddressService.getApiUrl()?.slice(0, -4) || window.location.host}</div>
				<div>
					<div className={style.status}>
						<div>STATUS: </div>
						<LabelStatus text={systemData.info} type={type} />
					</div>
				</div>
			</div>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<div className={style.graphic}>
					{
						charts.map((chart) => (
							<ProgressChart
								key={chart.dataTitle}
								data={chart.data}
								dataValue={chart.dataValue}
								dataType={chart.dataType}
								type={chart.type as ProgressType}
								dataTitle={chart.dataTitle}
								boxTitle={chart.boxTitle}
								boxData={chart.boxData}
							/>
						))
					}
				</div>
			</Collapse>
		</TableContainer>
	);
};

export default StatusSystem;

import './SelectBox.scss';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import style from './SelectBox.module.scss';

export interface SelectBoxItem {
	value: string;
	label: string;
}

interface SelectBoxProps {
	selectedValue: string;
	onChange: (value: unknown) => void;
	items: SelectBoxItem[];
	isDisabled?: boolean;
}

const SelectBox: React.FC<SelectBoxProps> = ({ selectedValue, onChange, items, isDisabled = false }) => {
	return (
		<FormControl variant="outlined" className={style.platformSelectBoxControl}>
			<Select
				value={selectedValue}
				onChange={(event: React.ChangeEvent<{ value: unknown }>): void => onChange(event.target.value)}
				className={`mui-input ${style.platformSelectBox}`}
				disabled= {isDisabled}
			>
				{
					items.map(item => (
						<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
					))
				}
			</Select>
		</FormControl>
	);
};

export default SelectBox;

import {
	AdvancedSearchContext,
	AdvancedSearchUpdateContext
} from '../../contexts/AdvancedSearchContext';
import { AdvancedSearch as AdvancedSearchInt } from '../../interfaces/AdvancedSearch';
import { Button } from '../../components/Button'; import { MessageBox } from '../../components/MessageBox';
import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import { SelectBox } from '../../components/SelectBox';
import style from './AdvancedSearch.module.scss';
import { TextField } from '../../components/TextField';
import { useHistory } from 'react-router-dom';
import useRoutes from '../../helpers/hooks/useRoutes';
import useVisibility from '../../helpers/hooks/useVisibility';

const types = [
	{ label: 'image', value: 'image' },
	{ label: 'video', value: 'video' },
];

const AdvancedSearch: React.FC = () => {
	const url = new URL(window.location.href);
	const params = new URLSearchParams(url.search);
	const uniqueId = params.get('_uniqueId') ?? '';
	const type = params.get('type') ?? types[0].value;
	const [search, setSearch] = React.useState<AdvancedSearchInt>({
		uniqueId,
		type,
	});
	const [error, setError] = React.useState('');
	const { isFetching, myAdvancedSearchResult } = React.useContext(AdvancedSearchContext);
	const { fetchAdvancedSearch } = React.useContext(AdvancedSearchUpdateContext);
	const { routes } = useRoutes();
	const { show } = useVisibility(routes.advancedSearch);
	const history = useHistory();

	const fetch = (): void => {
		fetchAdvancedSearch(search).catch((err) => {
			setError(err.message);
		});
	};

	const changeURL = () => {
		history.push({
			search: `?type=${search.type === types[1].value ? types[1].label : types[0].label}&_uniqueId=${search.uniqueId}`,
		});
	};

	const handleSearchButton = () => {
		changeURL();
		fetch();
	};

	const isValid = (): boolean => {
		const regex = /^[^\s].*\S$/;

		return regex.test(search.uniqueId) && search.type !== '';
	};

	React.useEffect(() => {
		setSearch({ uniqueId, type: type === types[1].label ? types[1].value : types[0].value });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uniqueId, type]);

	React.useEffect(() => {
		if (search.uniqueId !== '' && search.type !== '') { fetch(); }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={show ? style.advancedSearch : style.advancedSearchHidden}>
			<div className={style.container}>
				<div className={style.top}>
					<div className={style.actions} >
						<div className={style.searchContainer}>
							<TextField
								value={search.uniqueId}
								onChange={(value: string): void => setSearch({ ...search, uniqueId: value })}
								placeholder="Search by _uniqueId"
							/>
						</div>
						<div className={style.typeSelectContainer}>
							<SelectBox
								selectedValue={search.type}
								onChange={(value: any): void => setSearch({ ...search, type: value })
								}
								items={types}
							/>
						</div>
						<div className={style.searchButtonContainer}>
							<Button
								title="Search"
								onClick={handleSearchButton}
								loadingTitle="Searching..."
								isLoading={isFetching}
								startIcon={isFetching ? <RefreshIcon /> : <SearchIcon />}
								isDisabled={!isValid() || isFetching}
							/>
						</div>
					</div>
				</div>
				<div className={style.contentView} >
					{
						type === types[0].label && search.uniqueId === uniqueId && uniqueId !== '' && !isFetching && myAdvancedSearchResult?.blobs[0] && (
							<div className={style.image} >
								<img
									id={'image'}
									className={style.image}
									key={myAdvancedSearchResult?.blobs[0]}
									src={`data:image/png;base64, ${myAdvancedSearchResult?.blobs[0]}`}
									alt={'image'}
								/>
							</div>
						)
					}
					{
						type === types[1].label && search.type === types[1].value && search.uniqueId === uniqueId && !isFetching && myAdvancedSearchResult?.blobs[0] && (
							<video
								className="content-view-video"
								key={myAdvancedSearchResult?.blobs[0]}
								src={`data:video/mp4;base64,${myAdvancedSearchResult?.blobs[0]}`}
								controls
							/>
						)
					}
					{
						!myAdvancedSearchResult?.blobs[0] && search.uniqueId === uniqueId && uniqueId !== '' && !isFetching && (
							<div>
								<h1 style={{ color: '#aaa' }}>Not found</h1>
							</div>
						)
					}
				</div>
				{
					error && (
						<MessageBox text={error} />
					)
				}
			</div>
		</div>
	);
};

export default AdvancedSearch;

import { Box, Tooltip } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import React from 'react';
import style from './HelpText.module.scss';

interface HelpTextProps {
	text: string;
}

const HelpText: React.FC<HelpTextProps> = ({ text }) => {
	return (
		<Tooltip title={text} className={style.helpTextMessage} placement="right" arrow={true}>
			<Box className={style.helpTextIcon}>
				<HelpOutline fontSize={'small'} />
			</Box>
		</Tooltip>
	);
};

export default HelpText;

import {
	SimilaritySearchContext,
	SimilaritySearchUpdateContext
} from '../../contexts/SimilaritySearchContext';
import { Button } from '../../components/Button';
import ContentGallery from '../../components/ContentGallery/ContentGallery';
import { ImageResult } from '../../interfaces/Image';
import { MessageBox } from '../../components/MessageBox';
import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import { SelectBox } from '../../components/SelectBox';
import { SimilaritySearch as SimilaritySearchInt } from '../../interfaces/SimilaritySearch';
import style from './SimilaritySearch.module.scss';
import { TextField } from '../../components/TextField';
import { useHistory } from 'react-router-dom';
import useRoutes from '../../helpers/hooks/useRoutes';
import useVisibility from '../../helpers/hooks/useVisibility';

interface DescriptorInt {
	label: string,
	value: string,
}

const SimilaritySearch: React.FC = () => {
	const [descriptors, setDescriptors] = React.useState<DescriptorInt[]>([]);
	const [error, setError] = React.useState('');
	const url = new URL(window.location.href);
	const params = new URLSearchParams(url.search);
	const uniqueId = params.get('_uniqueId') ?? '';
	const descriptor = params.get('descriptor') ?? '';
	const [search, setSearch] = React.useState<SimilaritySearchInt>({
		uniqueId,
		descriptor,
	});
	const { isFetching, itemsPerPage, pageOffset, mySimilaritySearchResponse, fetchSimilaritySearch } = React.useContext(SimilaritySearchContext);
	const { setPageOffset } = React.useContext(SimilaritySearchUpdateContext);
	const { routes } = useRoutes();
	const { show } = useVisibility(routes.similaritySearch);
	const history = useHistory();

	const fetch = (): void => {
		fetchSimilaritySearch(search).catch((err) => {
			setError(err.message);
		});
	};

	const changeURL = () => {
		history.push({
			search: search.descriptor ?
				`_uniqueId=${search.uniqueId}&descriptor=${search.descriptor}` :
				`_uniqueId=${search.uniqueId}`,
		});
	};

	const handleSearchButton = () => {
		changeURL();
		fetch();
	};

	const isValid = (): boolean => {
		const regex = /^[^\s].*\S$/;

		return regex.test(search.uniqueId);
	};

	const parseStringArrayToDescriptorInt = (arr: string[]): DescriptorInt[] => {
		return arr.map(item => {
			return { label: item, value: item };
		});
	};

	const parseMySimilaritySearchQueryResultToUseImage = (): ImageResult[] => {
		if (mySimilaritySearchResponse?.json) {
			// eslint-disable-next-line prefer-destructuring
			const { FindImage } = mySimilaritySearchResponse.json[1];

			if (FindImage.entities) {
				const currentEntities = FindImage.entities.map((entity, index) => ({
					FindImage: {
						blobs_start: index,
						entities: [
							{
								...entity,
							},
						],
						returned: 1,
						status: 0,
					},
				}));

				return currentEntities;
			}
		}

		return [];
	};

	React.useEffect(() => {
		if (search.uniqueId !== '') {
			fetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (mySimilaritySearchResponse?.descriptors && search.uniqueId !== '') {
			setDescriptors(parseStringArrayToDescriptorInt(mySimilaritySearchResponse?.descriptors));
			setSearch({ ...search, descriptor: mySimilaritySearchResponse?.descriptors[0] });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mySimilaritySearchResponse]);

	React.useEffect(() => {
		setSearch({ ...search, uniqueId });

		if (mySimilaritySearchResponse?.descriptors) {
			setDescriptors(parseStringArrayToDescriptorInt(mySimilaritySearchResponse?.descriptors));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uniqueId]);

	return (
		<div className={show ? style.similaritySearch : style.similaritySearchHidden}>
			<div className={style.container}>
				<div className={style.top} >
					<div className={style.actions} >
						<div className={style.searchContainer}>
							<TextField
								value={search.uniqueId}
								onChange={(value: string): void => {
									setSearch({ descriptor: '', uniqueId: value });
									setDescriptors([]);
								}}
								placeholder="Search by _uniqueId"
							/>
						</div>
						<div className={style.descriptorSelectContainer}>
							<SelectBox
								selectedValue={search.descriptor || ''}
								onChange={
									(value: any): void => { setSearch({ ...search, descriptor: value }); }
								}
								items={search.uniqueId ? descriptors : []}
								isDisabled={descriptors.length === 0}
							/>
						</div>
						<div className={style.searchButtonContainer}>
							<Button
								title="Search"
								onClick={handleSearchButton}
								loadingTitle="Searching..."
								isLoading={isFetching}
								startIcon={isFetching ? <RefreshIcon /> : <SearchIcon />}
								isDisabled={!isValid() || isFetching}
							/>
						</div>
					</div>
				</div>
				<div className={style.contentView} >
					{
						search.uniqueId === uniqueId && uniqueId !== '' && !isFetching && mySimilaritySearchResponse?.blobs[0] && (
							<div className={style.imagesContainer}>
								<ContentGallery
									rawImages={
										{
											blobs: mySimilaritySearchResponse.blobs,
											json: parseMySimilaritySearchQueryResultToUseImage(),
										}
									}
									rawVideos={undefined}
									isFetching={isFetching}
									pageOffset={pageOffset}
									rawPerPage={itemsPerPage}
									total={
										(mySimilaritySearchResponse &&
											mySimilaritySearchResponse.blobs &&
											mySimilaritySearchResponse.blobs.length) || 0
									}
									onPageChange={setPageOffset}
								/>
							</div>
						)
					}
					{
						!mySimilaritySearchResponse?.blobs[0] && search.uniqueId === uniqueId && uniqueId !== '' && !isFetching && (
							<div className={style.notFound}>
								<h1 style={{ color: '#aaa' }}>Not found</h1>
							</div>
						)
					}
				</div>
				{
					error && (
						<MessageBox text={error} />
					)
				}

			</div>
		</div>
	);
};

export default SimilaritySearch;

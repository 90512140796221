import React, { useRef } from 'react';
import Add from '@material-ui/icons/Add';
import { Button } from '../../components/Button';
import ContentGallery from '../../components/ContentGallery/ContentGallery';
import { formatByteAmounts } from '../../helpers/numbers';
import { MessageBox } from '../../components/MessageBox';
import { MessageBoxType } from '../../components/MessageBox/MessageBox';
import { ModalConfirm } from '../../components/ModalConfirm';
import ModalDeleteSaveQuery from '../../components/ModalDeleteSaveQuery/ModalDeleteSaveQuery';
import { ModalSaveQuery } from '../../components/ModalSaveQuery';
import { QuerySaved } from '../../interfaces/SaveQueries';
import QuerySaveList from './QuerySaveList/QuerySaveList';
import RefreshIcon from '@material-ui/icons/Refresh';
import { SectionEditor } from '../../components/SectionEditor';
import { SectionTabs } from '../../components/SectionTabs';
import { SplitPanes } from '../../components';
import style from './CustomQuery.module.scss';
import { Tooltip } from '@material-ui/core';
import useCustomSearch from './Hooks/useCustomSearch';
import useRoutes from '../../helpers/hooks/useRoutes';
import useVisibility from '../../helpers/hooks/useVisibility';

const CustomQuery: React.FC = () => {
	const [formattedQuery, setFormattedQuery] = React.useState<string>('');
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const [isOpenConfirm, setIsOpenConfirm] = React.useState<boolean>(false);
	const [isOpenDelete, setIsOpenDelete] = React.useState<boolean>(false);
	const [queryIdToDelete, setQueryIdToDelete] = React.useState<number>(0);
	const [queryToSave, setQueryToSave] = React.useState<any>({});
	const [useFormattedQuery, setUseFormattedQuery] = React.useState<boolean>(false);

	const { routes } = useRoutes();
	const { show } = useVisibility(routes.customQuery);

	const {
		isFetching,
		myImages,
		myVideos,
		runQuery,
		saveQuery,
		userQuery,
		queryErr,
		rawPerPage,
		setPageOffset,
		pageOffset,
		setUserQuery,
		myCustomQueryResult,
		tabSelected,
		setTabSelected,
		duration,
		blobsSize,
		handleSelectListedQuery,
		handleDeleteCard,
		handleResetAll,
		handleAddNewQuery,
		isEmptyList,
		temporalSavedQueries,
	} = useCustomSearch();

	const previousUserQuery = useRef(userQuery);

	const handleModalSave = (query: QuerySaved): void => {
		if (query.name) {
			saveQuery(query.name, query.id);
		} else {
			setIsOpen(state => !state);
			setQueryToSave(query);
		}
	};

	const handleModalSaveClose = (): void => {
		setIsOpen(state => !state);
	};

	const handleSelected = (id: number): void => {
		handleSelectListedQuery(id);
	};

	const handleConfirm = (): void => {
		handleResetAll();
		setIsOpenConfirm(state => !state);
	};

	const handleCloseModal = (): void => {
		setIsOpenConfirm(state => !state);
	};

	const handleDeleteModal = (id: number): void => {
		setIsOpenDelete(state => !state);
		setQueryIdToDelete(id);
	};

	const handleConfirmDelete = (): void => {
		handleDeleteCard(queryIdToDelete);
		setIsOpenDelete(state => !state);
	};

	const handleModalDelete = (): void => {
		setIsOpenDelete(state => !state);
	};

	const handleRunQuery = () => {
		try {
			const queryFormatted = JSON.parse(userQuery);

			setFormattedQuery(JSON.stringify(queryFormatted, null, 2));
			setUseFormattedQuery(true);
		} catch (error) {
			setUseFormattedQuery(false);
		} finally {
			runQuery();
		}
	};

	React.useEffect(() => {
		if (previousUserQuery.current !== userQuery) {
			previousUserQuery.current = userQuery;
			setUseFormattedQuery(false);
		} else {
			setUseFormattedQuery(true);
		}
	}, [userQuery]);

	const tabLists = [
		{
			id: 0,
			title: 'Query',
			bodyTab: (
				<div className={style.queryButton}>
					<Button
						title="Run"
						onClick={handleRunQuery}
						loadingTitle="Running..."
						isLoading={isFetching}
						startIcon={<RefreshIcon />}
						isDisabled={isFetching || isEmptyList}
					/>
				</div>
			),
			bodyPanel: (
				<div style={{ height: '100%' }}>
					<SectionEditor
						readonly={isEmptyList}
						height={'100%'}
						body={formattedQuery.length > 0 && useFormattedQuery ? formattedQuery : userQuery}
						onChange={setUserQuery}
					/>
				</div>
			),
		},
		{
			id: 1,
			title: 'Response',
			bodyTab: <div></div>,
			bodyPanel: (
				<div style={{ height: '100%' }}>
					<SectionEditor
						readonly={true}
						height={'100%'}
						body={JSON.stringify(myCustomQueryResult, null, 2)}
					/>
				</div>
			),
		},
	];
	let messageText: string | JSX.Element = '';
	let messageType: MessageBoxType | undefined = undefined;

	if (queryErr) {
		messageText = queryErr;
		messageType = 'error';
	} else {
		if (
			myCustomQueryResult &&
			myCustomQueryResult.length > 0 &&
			!isFetching
		) {
			messageText = (
				<>
					<strong>Time:</strong>
					{` ${duration}ms — `}
					<strong>Size:</strong>
					{` ${formatByteAmounts(blobsSize)}`}
				</>
			);
			messageType = 'success';
		} else {
			messageText = 'Ready for query';
			messageType = 'info';
		}
	}

	return (
		<div className={style.customQuery}>
			<ModalSaveQuery isOpen={isOpen} handleSave={saveQuery} handleClose={handleModalSaveClose} queryToSave={queryToSave} />
			<ModalConfirm isOpenConfirm={isOpenConfirm} handleConfirm={handleConfirm} handleClose={handleCloseModal} />
			<ModalDeleteSaveQuery isOpenDelete={isOpenDelete} handleCloseModalDelete={handleModalDelete} handleConfirmDelete={handleConfirmDelete} />
			<div style={{ flex: 1, visibility: show ? 'visible' : 'hidden' }}>
				<SplitPanes>
					<div className={style.leftContainer}>
						<div className={style.querySave}>
							<div className={style.saveHead}>Queries <Tooltip title={'Add new Query'} placement="right" arrow={true}><Add className={style.addButton} onClick={handleAddNewQuery} /></Tooltip></div>
							<QuerySaveList data={temporalSavedQueries} handleSelected={handleSelected} handleDeleteModal={handleDeleteModal} handleModalSave={handleModalSave} />
						</div>
						<div className={style.query}>
							<div className={style.tabs}>
								<SectionTabs
									sectionTabs={tabLists}
									tabIndex={tabSelected}
									onSelect={(index): void => setTabSelected(index)}
								/>
							</div>
							<div className={style.errorMessage}>
								<MessageBox type={messageType} text={messageText} />
							</div>
						</div>
					</div>
					<div className={style.rightContainer}>
						<ContentGallery
							rawVideos={myVideos}
							rawImages={myImages}
							isFetching={isFetching}
							pageOffset={pageOffset}
							rawPerPage={rawPerPage}
							total={myCustomQueryResult.reduce((total, item) => {
								if (typeof item === 'object' && item.FindImage) {
									return total + item.FindImage.returned;
								}

								if (typeof item === 'object' && item.FindVideo) {
									return total + item.FindVideo.returned;
								}

								return total;
							}, 0)}
							onPageChange={setPageOffset}
						/>
					</div>
				</SplitPanes>
			</div>
		</div>
	);
};

export default CustomQuery;

import ConnectionsTable from './ConnectionsTable';
import React from 'react';
import { SplitPanes } from '../../components';
import StatusChart from './StatusChart';
import StatusList from './StatusList';
import StatusSystem from './StatusSystem';
import StatusTable from './StatusTable';
import style from './Status.module.scss';
import useRoutes from '../../helpers/hooks/useRoutes';
import useVisibility from '../../helpers/hooks/useVisibility';

const Status: React.FC = () => {
	const { routes } = useRoutes();
	const { show } = useVisibility(routes.status);

	return (
		<div style={{ flex: 1, visibility: show ? 'visible' : 'hidden' }}>
			<div className={style.status}>
				<SplitPanes>
					<div className={style.leftContainer}>
						<div className={style.system}>
							<StatusSystem />
						</div>
						<div className={style.table}>
							<StatusTable />
						</div>
						<div className={style.table}>
							<ConnectionsTable />
						</div>
					</div>
					<div className={style.rightContainer}>
						<div className={style.chart}>
							<StatusChart />
						</div>
						<div className={style.list}>
							<StatusList />
						</div>
					</div>
				</SplitPanes>
			</div>
		</div>
	);
};

export default Status;

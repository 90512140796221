import Alert from '@material-ui/lab/Alert';
import React from 'react';
import style from './MessageBox.module.scss';

export type MessageBoxType = 'error' | 'success' | 'info';

interface MessageBoxProps {
	text: string | JSX.Element;
	type?: MessageBoxType;
}

const MessageBox: React.FC<MessageBoxProps> = ({ type, text }) => {
	return (
		<Alert severity={type ? type : 'info'} className={style.errorMessage}>
			{text}
		</Alert>
	);
};

export default MessageBox;
